import React, { ChangeEvent, FC, useState } from 'react'

import { useAppDispatch, useAppSelector } from '@app/model/store'

import l10n from '@widgets/Restaurant/Booking/lib/booking-localization'
import { BookingInput } from '@widgets/Restaurant/Booking/ui/components/BookingInput'
import { setNameFieldValue } from '@widgets/Restaurant/Booking/model/slice'

import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { EGG } from '@shared/api/analytics'

const BookingFullName: FC = () => {
  const dispatch = useAppDispatch()
  const { restaurantSource, form } = useAppSelector((store) => store.booking)
  const { nameField, isErrorsVisible } = form

  const handleNameFocus = () => {
    EGG.entity.booking.click_reserve_field_name()
  }

  const handleNameTyping = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value
    dispatch(setNameFieldValue(newValue))

    if (newValue.length === 1) {
      EGG.entity.booking.input_reserve_field_name()
    }
  }

  const handleNameBlur = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value
    dispatch(setNameFieldValue(value.trim()))
  }

  return (
    <ErrorBoundary>
      <BookingInput
        field={nameField}
        isErrorsVisible={isErrorsVisible}
        placeholder={l10n('guest_full_name_placeholder')}
        onChange={handleNameTyping}
        onFocus={handleNameFocus}
        onBlur={handleNameBlur}
      />
    </ErrorBoundary>
  )
}

export default BookingFullName
