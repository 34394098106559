import s from './Booking.module.sass'

import React, { useCallback, useEffect, useRef } from 'react'
import { closeBooking } from '@widgets/Restaurant/Booking/model/slice'
import { BookingScreenForm } from '@widgets/Restaurant/Booking/ui/screens/BookingScreenForm'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { BookingScreenConfirm } from '@widgets/Restaurant/Booking/ui/screens/BookingScreenConfirm'
import { BookingScreenSuccess } from '@widgets/Restaurant/Booking/ui/screens/BookingScreenSuccess'
import { useBlockScrollOverlay } from '@shared/lib/hooks/useBlockScrollOverlay'
import { useOnClickOutside, useOnScreen } from '@widgets/Restaurant/Booking/lib/helper'
import { submitBooking } from '@widgets/Restaurant/Booking/model/actions'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { EGG } from '@shared/api/analytics'
import { getIsActiveQA, TARGET_QA_KEY_URL } from '@shared/lib/helpers/autoTests/helpers'

const Modal = ({ children }) => {
  useBlockScrollOverlay()

  const updateURL = (type: 'add' | 'remove') => {
    if (history.pushState) {
      const isQA = getIsActiveQA()
      const baseUrl = window.location.origin + window.location.pathname

      let newUrl
      if (!isQA) {
        newUrl = baseUrl + (type === 'add' ? `?reserve` : ``)
      } else {
        newUrl = baseUrl + (type === 'add' ? `?reserve&${TARGET_QA_KEY_URL}=true` : `${TARGET_QA_KEY_URL}=true`)
      }

      history.pushState(null, null, newUrl)
    } else {
      console.warn('History API не поддерживается')
    }
  }

  useEffect(() => {
    const hasPreopenedUrlPath = window.location.search.includes('?reserve')
    if (!hasPreopenedUrlPath) updateURL('add')
    return () => updateURL('remove')
  }, [])

  return (
    <>
      <div>{children}</div>
      <div className={s['booking-overlay']}></div>
    </>
  )
}

const Booking = () => {
  const dispatch = useAppDispatch()
  const storeBooking = useAppSelector((state) => state.booking)
  const { isOpenModal, isOpenScreenForm, isOpenScreenConfirm, isOpenScreenSuccess, restaurantSource } = storeBooking
  const { isMobile } = useClientResponsive()

  const contentRef = useRef()
  const isContentVisible = useOnScreen(contentRef)
  const contentClassName = `
  ${s['booking-content']}
  ${isOpenScreenConfirm ? s['booking-content--confirm'] : ''}
  ${isOpenScreenSuccess ? s['booking-content--success'] : ''}
  `
  useOnClickOutside(contentRef, () => (isContentVisible ? handleAbortBookingCallback() : false))

  const handleClose = useCallback(() => {
    dispatch(closeBooking(null))
  }, [dispatch])

  const handleAbortBookingCallback = useCallback(() => {
    if (!isOpenScreenConfirm) handleClose()

    isOpenScreenForm && EGG.entity.booking.click_reserve_close()
    isOpenScreenSuccess && EGG.entity.booking.click_success_close()
  }, [
    handleClose,
    isOpenScreenConfirm,
    isOpenScreenForm,
    isOpenScreenSuccess,
    restaurantSource.id,
    restaurantSource.name,
  ])

  const keydownCallback = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case 'Enter':
          event.preventDefault()
          dispatch(submitBooking())
          break

        case 'Escape':
          event.preventDefault()
          handleAbortBookingCallback()
          break
      }
    },
    [dispatch, handleAbortBookingCallback],
  )

  useEffect(() => {
    window.addEventListener('keyup', keydownCallback, false)
    return () => {
      window.removeEventListener('keyup', keydownCallback, false)
    }
  }, [keydownCallback])

  return isOpenModal ? (
    <ErrorBoundary>
      <Modal>
        <div className={`${s.booking} ${s[isMobile ? 'booking--mobile' : 'booking--desktop']}`}>
          <div className={s['booking-scroll-wrap']}>
            <div ref={contentRef} className={contentClassName}>
              {isOpenScreenForm && <BookingScreenForm />}
              {isOpenScreenConfirm && <BookingScreenConfirm />}
              {isOpenScreenSuccess && <BookingScreenSuccess />}
            </div>
          </div>
        </div>
      </Modal>
    </ErrorBoundary>
  ) : null
}

export default Booking
