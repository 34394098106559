import s from './OwnerRestCardPage.module.sass'
import copyTextToClipboard from './copyTextToClipboard'

import React, { useState, useEffect, useCallback } from 'react'

import { useAppDispatch, useAppSelector } from '@app/model/store'

import { initPostsPage } from '@pages/OwnerPersonalPage/model/OwnerPostsModel/actions'
import { dataRestAdvertMock, dataRestMock } from '@pages/OwnerPersonalPage/ui/pages/OwnerRestCardPage/consts'
import { IconRestCardSkeleton } from '@pages/OwnerPersonalPage/ui/components/IconRestCardSkeleton'
import { OwnerRestInfoTextarea } from '@pages/OwnerPersonalPage/ui/components/OwnerRestInfoTextarea'

import { addTemporaryNotice } from '@widgets/Common/Notices'
import { RestCard } from '@widgets/Restaurant/ResturantCards/RestCard'

import { API } from '@shared/api'
import { Text } from '@shared/ui/Typography/Text'
import { Title } from '@shared/ui/Typography/Title'
import { Button } from '@shared/ui/Actions/Button'

import IconCopy from '@icons/icon-copy.svg'
import merge from 'lodash/merge'
import Link from 'next/link'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

/**
 * @function openInNewTab
 */
const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

/**
 * @function OwnerTabRestaurant - Карточка ресторана
 */

const OwnerRestCardPage = (props) => {
  const thisState = useAppSelector((store) => store.pages.owner_personal)
  const dispatch = useAppDispatch()
  const [activeCard, setActiveCard] = useState('promo')
  const handleSwitchRestPreview = (restState: 'promo' | 'common', ev: React.SyntheticEvent) => {
    setActiveCard(restState)
  }

  const previewDataRestAdvert = merge({}, props.data, { categories: { advert: true } })
  const previewDataRest = merge({}, props.data, { categories: { advert: false } })
  const restLink = props?.data?.url?.path ? `https://www.afisha.ru${props.data.url.path}` : null
  const handleOpenRestItemPage = (ev: React.SyntheticEvent, restLink: string) => {
    restLink && openInNewTab(restLink)
  }

  const [copiedState, setCopiedState] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      if (copiedState === true) {
        setCopiedState(false)
      }
    }, 150)
  }, [copiedState])

  const handleCopyRestLink = (ev: React.SyntheticEvent) => {
    console.info('handleCopyRestLink restLink', restLink)
    if (restLink) {
      copyTextToClipboard(restLink, () => {
        setCopiedState(true)
      })
    }
  }

  useEffect(() => {
    dispatch(initPostsPage({ id: props.data.id, name: props.data.name }))
  }, [])
  // @ts-ignore
  const thisDescription = thisState.posts.description

  const propsOwnerDescr = thisDescription || props?.data?.description?.description?.owner || ''
  const [ownerDescrTextarea, setOwnerDescrTextarea] = useState(propsOwnerDescr)
  const [isOwnerDescrValid, setIsOwnerDescrValid] = useState(true)
  const aboutMaxLength = 4000
  const ownerAboutPlaceholder = 'Расскажите о главных особенностях вашего ресторана, команде, расположении'
  const removeTripleLineBreaks = (value: any): string => {
    let ouput = value || ''
    const hasTripleLineBreaks = ouput.indexOf('\n\n\n') >= 0
    ouput = hasTripleLineBreaks ? ouput.replace('\n\n\n', '\n\n') : ouput
    return ouput
  }
  const trimVal = (thisVal: string): string => thisVal?.trim() || ''

  const handleOwnerDescr = {
    onChange: (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
      let newValue = ev?.target?.value ? ev.target.value : ''
      newValue = removeTripleLineBreaks(newValue)

      const isMaxChars = newValue.length > aboutMaxLength
      const isEmptyData = newValue === ''
      setIsOwnerDescrValid(isEmptyData || !isMaxChars ? true : false)
      setOwnerDescrTextarea(newValue)
    },
    onBlur: (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
      let newValue = ev?.target?.value ? ev.target.value : ''
      newValue = trimVal(newValue)

      setIsOwnerDescrValid(newValue.length > aboutMaxLength ? false : true)
      setOwnerDescrTextarea(newValue)
    },
    onFocus: (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
      // empty
    },
  }

  const [isDescrLoad, setIsDescrLoad] = useState(false)
  const [isDescrDone, setIsDescrDone] = useState(false)

  useEffect(() => {
    if (isDescrDone === true) {
      setTimeout(() => {
        setIsDescrDone(false)
      }, 1500)
    }
  }, [isDescrDone])

  const handleSubmitAboutText = async (ev: React.SyntheticEvent) => {
    setIsDescrLoad(true)

    const params = {
      rid: props.data.id,
      restaurant: props.data.id,
      body: { description: ownerDescrTextarea },
    }

    // запрос на изменение текста о ресторане
    API.r_info(params)
      .then((response) => {
        if (response?.data?.description) {
          setOwnerDescrTextarea(response.data.description)
        }
        setIsDescrDone(true)
      })
      .catch((error) => {
        dispatch(addTemporaryNotice({ markup: 'Ошибка обновления описания' }))
      })
      .finally(() => {
        setIsDescrLoad(false)
      })
  }

  const isSubmitInfoDisabled = !isOwnerDescrValid

  const isHttps = window.location.protocol.indexOf('https') >= 0

  return (
    <ErrorBoundary>
      <div className={s['page-content-wrap']}>
        <section className={s['page-content-section']}>
          <Title sizes={'h5'} tag={'h3'}>
            Внешний вид карточки
          </Title>

          <div className={s['restaurant-info-content-margin']}>
            <div className={s['page-content-white-block']}>
              <div className={s['tabs-preview']}>
                <div className={s['tabs-preview-list']}>
                  <Button
                    className={s['tabs-preview-item-button']}
                    data-selected={activeCard === 'promo'}
                    sizes={'L'}
                    mode={'white'}
                    onClick={(ev) => handleSwitchRestPreview('promo', ev)}>
                    <div>
                      <span className={s['tabs-preview-item-button-text']}>Промо карточка</span>
                      <span className={s['tabs-preview-item-button-descr']}>Показы в динамике</span>
                    </div>
                  </Button>

                  <Button
                    className={s['tabs-preview-item-button']}
                    data-selected={activeCard === 'common'}
                    sizes={'L'}
                    mode={'white'}
                    onClick={(ev) => handleSwitchRestPreview('common', ev)}>
                    <div>
                      <span className={s['tabs-preview-item-button-text']}>Обычная карточка</span>
                      <span className={s['tabs-preview-item-button-descr']}>Показы по сайту</span>
                    </div>
                  </Button>
                </div>

                <div className={s['tabs-preview-rest-cards']} data-test='tabs-preview-rest-cards'>
                  {activeCard === 'promo' && (
                    <div className={s['tabs-preview-rest-card']}>
                      <RestCard
                        type={'list'}
                        data={previewDataRestAdvert}
                        sizes={'L ML'}
                        analytic={null}
                        send_analytic={false}
                      />
                    </div>
                  )}
                  {activeCard === 'common' && (
                    <div className={s['tabs-preview-rest-card']}>
                      <RestCard
                        type={'list'}
                        data={previewDataRest}
                        sizes={'L ML'}
                        analytic={null}
                        send_analytic={false}
                      />
                    </div>
                  )}

                  <IconRestCardSkeleton />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={s['page-content-section']}>
          <div className={s['restaurant-info-page-header-wrap']}>
            <Title sizes={'h5'} tag={'h3'}>
              <label htmlFor='rest-text-about'>Ресторан о себе</label>
            </Title>
          </div>

          <div className={s['restaurant-info-content-margin']}>
            <div className={`${s['page-content-white-block']} ${s['page-content-white-block--no-padding']}`}>
              <OwnerRestInfoTextarea
                name={'rest-text-about'}
                resize={true}
                onChange={handleOwnerDescr.onChange}
                onBlur={handleOwnerDescr.onBlur}
                onFocus={handleOwnerDescr.onFocus}
                value={ownerDescrTextarea}
                isValid={isOwnerDescrValid}
                isEmpty={ownerDescrTextarea?.length <= 0}
                isDescrLoad={isDescrLoad}
                isDescrDone={isDescrDone}
                placeholder={ownerAboutPlaceholder}
                isSubmitInfoDisabled={isSubmitInfoDisabled}
                handleSubmitAboutText={handleSubmitAboutText}
                ownerDescrTextarea={ownerDescrTextarea}
              />
            </div>
          </div>
        </section>

        <section className={s['page-content-section']}>
          <div className={s['page-content-white-block']}>
            <div className={s['restaurant-info-link-wrap']}>
              <Text className={s['restaurant-info-link-title']} sizes={'ML'}>
                Страница ресторана на сайте
              </Text>

              {restLink && (
                <div className={s['restaurant-info-link-text-row']}>
                  <Link href={restLink}>
                    <Text className={s['restaurant-info-link-text']} sizes={'M'}>
                      {restLink}
                    </Text>
                  </Link>

                  {isHttps && (
                    <span className={s['restaurant-info-link-copy-wrap']}>
                      <Button
                        className={s['restaurant-info-link-button']}
                        sizes={'S'}
                        mode='white'
                        onClick={(ev) => handleCopyRestLink(ev)}>
                        <span className={s['restaurant-info-link-icon']}>
                          <IconCopy />
                        </span>
                      </Button>

                      <span
                        className={s['restaurant-info-link-copy-state']}
                        data-is-visible={copiedState ? true : false}>
                        <Text sizes={'S'}>Скопировано</Text>
                      </span>
                    </span>
                  )}
                </div>
              )}

              {restLink && (
                <Link href={restLink} className={s['restaurant-info-link-aciton-link']} target={'_blank'}>
                  Перейти
                </Link>
              )}
            </div>
          </div>
        </section>
      </div>
    </ErrorBoundary>
  )
}

export default OwnerRestCardPage
