import { createSlice } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import { TypeState } from '@widgets/Common/Notices/model/types'

const initialState: TypeState = {
  notices: [],
  maxNotices: 10,
}

const noticesSlice = createSlice({
  name: 'notices',
  initialState,

  reducers: {
    addNotice(state, action) {
      if (state.notices.length >= state.maxNotices) state.notices.splice(0, 1)

      state.notices.push(action.payload)
      state.notices.sort((a, b) => a.priority - b.priority)
    },

    removeNotice(state, action) {
      const noticeToDelete = state.notices.find((item) => action.payload === item.id)
      if (!noticeToDelete) return

      switch (noticeToDelete.type) {
        case 'cookies':
          localStorage.setItem('cookie-apply', 'true')
          break
        case 'default':
          break
      }

      state.notices = state.notices.filter((item) => action.payload !== item.id)
    },

    // Special reducer for hydrating the state
    extraReducers: {
      // @ts-ignore
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.notices,
        }
      },
    },
  },
})

export const { addNotice, removeNotice } = noticesSlice.actions

export default noticesSlice.reducer
