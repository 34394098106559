/**
 * @fsd_page NewsGallery - список новостей
 */

import React, { FC, useEffect, useMemo, useState } from 'react'

import { ContainerTwoColumns } from '@shared/ui/Layout/Containers/ContainerTwoColumns'
import { Title } from '@shared/ui/Typography/Title'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import DefaultTag from '@shared/ui/Form/Single/Filters/DefaultTag/DefaultTag'

import s from './NewsGallery.module.sass'
import { NewsCard, NewsCardSkeleton } from '@widgets/News/NewsCard'
import { CompilationGrid, CompilationGridSkeleton } from '@widgets/News/CompilationGrid'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { changeSelectSortButton, resetSort } from '@pages/NewsGallery/model/actions'
import { useRouter } from 'next/router'
import { loadMorePage } from '@pages/NewsGallery/model/thunks'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { SectionMainCarousel } from '@shared/ui/Layout/SectionMainCarousel'
import { NewsCardMain } from '@widgets/News/NewsCardMain'
import { getListSection } from '@shared/lib/helpers/getListSection'
import { useEffectOnce } from '@shared/lib/hooks/useEffectOnce'
import { EGG } from '@shared/api/analytics'
import { Pagination } from '@features/Common/Pagination'
import { AdvertTemplate } from '@shared/ui/Layout/AdvertTemplate'
import { TypeMappingNewsOutput } from '@shared/api/middleware/mappingAPI/news/type'
import { AdvertSidebarSticky } from '@shared/ui/Layout/AdvertTemplate/ui/AdvertSidebarSticky'
import { MainCardSkeleton } from '@shared/ui/Layout/Cards/MainCard'
import { SelectionCardSkeleton } from '@widgets/Selection/SelectionCard'
import { ContainerLongLeft } from '@shared/ui/Layout/Containers/ContainerLongLeft'
import { SelectionCardCover } from '@widgets/Selection/SelectionCardCover'
import { NewsCardCover } from '@widgets/News/NewsCardCover'
import { BtnFilterTag, BtnFilterTagSkeleton } from '@shared/ui/Form/Single/Filters/DefaultTag'

const findListIndex = (thisList, thisItem) => {
  // ! TODO: необходимо исследовать (и возможно упростить логику)
  //    почему пришлось добавить дополнительную проверку
  //    на наличие id в объектах thisList & thisItem

  return thisList.findIndex((findItem) => {
    return findItem?.id && thisItem?.id ? findItem.id === thisItem.id : false
  })
}

const NewsGallery: FC = () => {
  const router = useRouter()
  const dispatch = useAppDispatch()
  const stateGlobal = useAppSelector((state) => state.global)
  const { loading, pagination, sort, list, special } = useAppSelector((state) => state.pages.news_gallery)
  const responsive = useClientResponsive()
  const { isMobile, isTablet } = responsive
  const [listSection, setListSection] = useState([])

  useEffect(() => {
    const isLastPage = !pagination?.page.next.number
    setListSection(getListSection(list, responsive, isLastPage))
  }, [list, responsive])

  const updateQuery = () => {
    const { variety, ...queryWithoutVariety } = router.query
    const newParams = { ...queryWithoutVariety }

    const selectedVariety = sort.selected.map((sortItem) => sortItem.slug).join(',')
    if (selectedVariety) newParams.variety = selectedVariety
    sort.list.length && router.push({ query: newParams }, undefined, { shallow: true })
  }

  // после какого кол-ва секций показывать специальный блок
  const specSectionPosition = useMemo(() => {
    const result = {
      desktop: 4,
      mobile: 12,
    }
    // на этом разрешении карточки выстраиваются в две колонки
    if (window.innerWidth <= 500) {
      result.mobile = 11
      // в три колонки
    } else if (window.innerWidth <= 650) {
      result.mobile = 12
      // в четыре колонки
    } else if (window.innerWidth <= 757) {
      result.mobile = 13
    }

    const hasSlider = listSection.some((section) => section === 'slider')
    const hasAdvert = listSection.some((section) => section === 'advert')

    if (hasSlider) result.mobile++
    if (hasAdvert) result.mobile++

    return result
  }, [listSection.length])

  useEffect(() => {
    sort.selected.length && updateQuery()
  }, [sort.selected.length])

  const handleResetSort = () => {
    dispatch(resetSort())
    const { variety, ...queryWithoutVariety } = router.query
    const newParams = { ...queryWithoutVariety }
    router.push({ query: newParams }, undefined, { shallow: true })
  }

  const isShowTwoPartListMobile = isMobile && listSection.length - 1 > specSectionPosition.mobile
  const isShowTwoPartListDesktop = !isMobile && listSection.length - 1 > specSectionPosition.desktop
  const isShowTwoPart = isShowTwoPartListMobile || isShowTwoPartListDesktop
  const isShowMainSlider = listSection[0]?.type === 'slider'

  useEffectOnce(() => {
    EGG.pages.NewsGallery.appear_screen_page()
  })

  const sectionNameForProps = `Галерея новостей`
  const getAnalyticIndexes = (section) => {
    return section.list.map((el) =>
      list.findIndex((findItem) => {
        // @ts-ignore
        return findItem?.id && el?.id ? findItem.id === el.id : false
      }),
    )
  }

  const isSkeletonTitle = loading.page
  const isSkeletonSortButtons = loading.page

  return (
    <ErrorBoundary>
      <div className={s.body}>
        {isShowMainSlider && (
          <SectionMainCarousel data-loading={loading.items_full}>
            {listSection[0].list.map((data, index) =>
              listSection[0].skeleton ? (
                <MainCardSkeleton />
              ) : (
                <NewsCardMain
                  data={data}
                  key={index}
                  analytic={{
                    section_name: sectionNameForProps,
                    section_index: findListIndex(list, data),
                  }}
                />
              ),
            )}
          </SectionMainCarousel>
        )}

        <ContainerTwoColumns>
          <ContainerTwoColumns.Main>
            <div className={`${s.header} ${!isShowMainSlider ? s['header--margin-top'] : ''}`}>
              <Title sizes={'h2 h4'} skeleton={isSkeletonTitle}>
                Самое интересное про рестораны
              </Title>

              <div className={s['sort-wrapper']} data-loading={loading.items_full}>
                {isSkeletonSortButtons ? (
                  Array.from(Array(5).keys()).map((el, index) => <BtnFilterTagSkeleton key={index} />)
                ) : (
                  <>
                    <BtnFilterTag active={!sort.selected.length} onClick={handleResetSort}>
                      Все
                    </BtnFilterTag>

                    {sort.list.map((item) => {
                      const isActive = sort.selected.some((itemSelected) => itemSelected.id === item.id)
                      const isVisible = item.show_on_main_page && item.active

                      return (
                        isVisible && (
                          <DefaultTag
                            key={item.id}
                            active={isActive}
                            onClick={() => dispatch(changeSelectSortButton(item.id))}>
                            {item.name}
                          </DefaultTag>
                        )
                      )
                    })}
                  </>
                )}
              </div>
            </div>

            <div className={s.list} data-loading={loading.items_full}>
              {listSection.map((section, index) => {
                if (isMobile && index > specSectionPosition.mobile) return
                if (!isMobile && index > specSectionPosition.desktop) return

                if (!isMobile && section.type === 'grid') {
                  return section.skeleton ? (
                    <CompilationGridSkeleton counts={'6 4 4'} className={s.grid} />
                  ) : (
                    <CompilationGrid
                      type={'news'}
                      key={section.list[0].id}
                      list={section.list}
                      counts={'6 4 4'}
                      className={s.grid}
                      analytic={{
                        section_name: sectionNameForProps,
                        analytic_indexes: getAnalyticIndexes(section),
                      }}
                    />
                  )
                } else if (section.type === 'cards') {
                  return isMobile ? (
                    section.list.map((item, i) =>
                      section.skeleton ? (
                        <NewsCardSkeleton key={`${index}-cards`} sizes={'S1x1 S1x1 1x1 1x1'} />
                      ) : (
                        <NewsCard
                          key={item.id}
                          sizes={'S1x1 S1x1 1x1 1x1'}
                          className={s.card}
                          data={item}
                          analytic={{
                            section_name: sectionNameForProps,
                            section_index: findListIndex(list, item),
                          }}
                        />
                      ),
                    )
                  ) : (
                    <div className={s.cards}>
                      {section.list.map((item) =>
                        section.skeleton ? (
                          <NewsCardSkeleton key={`${index}-cards`} sizes={'S1x1 S1x1 1x1 1x1'} />
                        ) : (
                          <NewsCard
                            key={item.id}
                            sizes={'S1x1 S1x1 1x1 1x1'}
                            className={s.card}
                            data={item}
                            analytic={{
                              section_name: sectionNameForProps,
                              section_index: findListIndex(list, item),
                            }}
                          />
                        ),
                      )}
                    </div>
                  )
                } else if (section.type === 'advert') {
                  return (
                    <AdvertTemplate type={section.componentType} count={section.count} className={s.advertInGallery} />
                  )
                }
              })}
            </div>
          </ContainerTwoColumns.Main>

          {!isMobile && !isTablet && (
            <ContainerTwoColumns.Aside>
              <AdvertTemplate type={'240x400'} container={AdvertSidebarSticky} />
            </ContainerTwoColumns.Aside>
          )}
        </ContainerTwoColumns>

        {(loading.items_full || special) && (
          <ContainerLongLeft data-loading={loading.items_full}>
            <NewsCardCover
              data={special}
              subtitles={['5 минут на чтение']}
              buttonText={'Смотреть'}
              analytic={{
                section_index: 18,
                section_name: `Галерея новостей`,
              }}
              skeleton={loading.items_full}
            />
          </ContainerLongLeft>
        )}

        {isShowTwoPart && (
          <ContainerTwoColumns>
            <ContainerTwoColumns.Main data-loading={loading.items_full}>
              <div className={s.list} data-loading={loading.items_full}>
                {listSection.map((section, index) => {
                  if (isMobile && index <= specSectionPosition.mobile) return
                  if (!isMobile && index <= specSectionPosition.desktop) return

                  if (!isMobile && section.type === 'grid') {
                    return section.skeleton ? (
                      <CompilationGridSkeleton counts={'6 4 4'} className={s.grid} />
                    ) : (
                      <CompilationGrid
                        type={'news'}
                        key={section.list[0].id}
                        list={section.list}
                        counts={'6 4 4'}
                        className={s.grid}
                        analytic={{
                          section_name: sectionNameForProps,
                          analytic_indexes: getAnalyticIndexes(section),
                        }}
                      />
                    )
                  } else if (section.type === 'cards') {
                    return isMobile ? (
                      section.list.map((item) =>
                        section.skeleton ? (
                          <NewsCardSkeleton key={`${index}-cards`} sizes={'S1x1 S1x1 1x1 1x1'} />
                        ) : (
                          <NewsCard
                            key={item.id}
                            sizes={'S1x1 S1x1 1x1 1x1'}
                            className={s.card}
                            data={item}
                            analytic={{
                              section_name: sectionNameForProps,
                              section_index: findListIndex(list, item),
                            }}
                          />
                        ),
                      )
                    ) : (
                      <div className={s.cards}>
                        {section.list.map((item) =>
                          section.skeleton ? (
                            <NewsCardSkeleton key={`${index}-cards`} sizes={'S1x1 S1x1 1x1 1x1'} />
                          ) : (
                            <NewsCard
                              key={item.id}
                              sizes={'S1x1 S1x1 1x1 1x1'}
                              className={s.card}
                              data={item}
                              analytic={{
                                section_name: sectionNameForProps,
                                section_index: findListIndex(list, item),
                              }}
                            />
                          ),
                        )}
                      </div>
                    )
                  } else if (section.type === 'advert') {
                    return (
                      <AdvertTemplate
                        type={section.componentType}
                        count={section.count}
                        className={s.advertInGallery}
                      />
                    )
                  }
                })}
              </div>
            </ContainerTwoColumns.Main>
          </ContainerTwoColumns>
        )}

        <ContainerTwoColumns>
          <ContainerTwoColumns.Main className={s.footer}>
            <Pagination
              mode={'load-more'}
              autoloadingOnMobile={true}
              currentPage={pagination?.page.current.number}
              pageCount={pagination?.page.count}
              handleLoadMore={() => dispatch(loadMorePage())}
              analytic={{ section_name: 'Галерея новостей' }}
              className={s.pagination}
            />
          </ContainerTwoColumns.Main>
        </ContainerTwoColumns>
      </div>
    </ErrorBoundary>
  )
}

export default NewsGallery
