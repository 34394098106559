export const CONST_CITY_COORDINATES = {
  msk: [55.755864, 37.617698],
  spb: [59.938784, 30.314997],
  sochi: [43.585472, 39.723098],
  vladivostok: [43.115542, 131.885494],
  ufa: [54.735152, 55.958736],
  samara: [53.195878, 50.100202],
  perm: [58.010455, 56.229443],
  novosibirsk: [55.030204, 82.92043],
  nnovgorod: [56.326797, 44.006516],
  krasnoyarsk: [56.010543, 92.852581],
  krasnodar: [45.03547, 38.975313],
  kazan: [55.796127, 49.106414],
  ekaterinburg: [56.838011, 60.597474],
  kaliningrad: [54.710162, 20.510137],
  chelyabinsk: [55.159902, 61.402554],
}
