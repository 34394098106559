import { sendClickHouseAnalytic } from '@shared/api/analytics/template'

// Уникальные события для карт
export const EGGMap = {
  // 1. Отобразился экран
  appear_screen_page: () =>
    sendClickHouseAnalytic({
      event_name: 'screen_appear',
      page_name: 'map',
    }),

  // Показать список
  click_show_list: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'show_list',
    }),

  // Скрыть список
  click_hide_list: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'hide_list',
    }),

  // '+' на карте
  click_zoom_in: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'zoom_in',
    }),

  // '-' на карте
  click_zoom_out: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'zoom_out',
    }),

  // Клик на определить геолокацию
  click_geolocation: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'geolocation',
    }),

  // Клик на крестик
  click_close: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'close',
    }),

  // Клик на стрелку слайдера галереи (вперед)
  click_rest_cover_forward: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'forward',
    }),

  // Клик на стрелку слайдера галереи (назад)
  click_rest_cover_back: () =>
    sendClickHouseAnalytic({
      event_name: 'element_click',
      element_type: 'back',
    }),
}
