import React, { FC, useContext } from 'react'
import s from './MapScaleControls.module.sass'
import { IconButton } from '@shared/ui/Actions/IconButton'
import IconZoomIn from '@icons/map/zoom-plus.svg'
import IconZoomOut from '@icons/map/zoom-minus.svg'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { MapContext } from '@widgets/Restaurant/Map/MapContext'
import { EGG } from '@shared/api/analytics'

interface Props {
  disabledZoomIn?: boolean
  disabledZoomOut?: boolean
  className?: string
}

const MapScaleControl: FC<Props> = ({ disabledZoomIn, disabledZoomOut, className = '' }) => {
  const [mapInstance] = useContext(MapContext)

  const onClickZoomIn = () => {
    mapInstance.mapCreated.setZoom(mapInstance.mapCreated.getZoom() + 1)
    EGG.pages.Map.click_zoom_in()
  }
  const onClickZoomOut = () => {
    mapInstance.mapCreated.setZoom(mapInstance.mapCreated.getZoom() - 1)
    EGG.pages.Map.click_zoom_out()
  }

  return (
    <ErrorBoundary>
      <div className={`${s.control} ${className}`}>
        <IconButton
          mode={'white'}
          sizes={'XL'}
          icon={IconZoomIn}
          disabled={disabledZoomIn}
          className={s.zoomIn}
          onClick={onClickZoomIn}
        />
        <div className={s.separator}></div>
        <IconButton
          mode={'white'}
          sizes={'XL'}
          icon={IconZoomOut}
          disabled={disabledZoomOut}
          className={s.zoomOut}
          onClick={onClickZoomOut}
        />
      </div>
    </ErrorBoundary>
  )
}

export default MapScaleControl
