/**
 * @function Анимация Fade-in Fade-out если выбран пин, слайд которого находится далеко
 */
export const customSlideToLoop = (swiper, newRealIndex) => {
  // Анимация Fade-in Fade-out если выбран пин, слайд которого находится далеко
  if (Math.abs(swiper.realIndex - newRealIndex) > 1) {
    const currentSlideWrapper = swiper.slides[swiper.activeIndex].querySelector('#rest-card-map-mobile')
    currentSlideWrapper.classList.add('hidden-view')
    currentSlideWrapper.firstChild.style.height = '141px'
    swiper.wrapperEl.style.transitionProperty = 'height'

    setTimeout(() => {
      swiper.slideToLoop(newRealIndex, 400, false)
      currentSlideWrapper.firstChild.style.height = null
      currentSlideWrapper.classList.remove('hidden-view')
    }, 400)
    setTimeout(() => {
      swiper.wrapperEl.style.transitionProperty = null
    }, 800)

    // Корректировка анимации чтоб слайдер иногда не улетал в соседний слайд
  } else {
    swiper.wrapperEl.style.transitionTimingFunction = 'cubic-bezier(.22,.11,0,1)'
    setTimeout(() => {
      swiper.wrapperEl.style.transitionTimingFunction = null
    }, 850)
    swiper.slideToLoop(newRealIndex, 800, false)
  }
}
