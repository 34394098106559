import React, { FC, useRef, useState } from 'react'
import s from './QuickBookingArticleD.module.sass'
import { Title } from '@shared/ui/Typography/Title'
import { QuickBookingSlots } from '@widgets/Restaurant/QuickBooking/components/QuickBookingSlots'
import Link from 'next/link'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { useNearestSlots } from '@widgets/Restaurant/QuickBooking/helpers/useNearestSlots'
import { EGG } from '@shared/api/analytics'
import { useIntersectionAnalytic } from '@shared/lib/hooks/useIntersectionAnalytic'

interface Props {
  restData: TypeMappingRestOutput
  className?: string
}

const QuickBookingArticleD: FC<Props> = ({ restData, className = '' }) => {
  const [paramsNearestSlots, setParamsNearestSlots] = useState({
    restData: restData,
    guests: 2,
  })
  const slotsData = useNearestSlots(paramsNearestSlots)
  const refLink = useRef()
  const hasBookingAfisha = restData.booking.afisha.active

  useIntersectionAnalytic(refLink, () => EGG.entity.news.rest_name_link_view(restData, { section_name: 'widget' }))

  if (!hasBookingAfisha) return
  const handleClickSlot = (data) => {
    EGG.entity.booking.slots_quick_booking_slot_click(data, null)
  }
  const handleClickDots = (data) => {
    EGG.entity.booking.slots_quick_booking_dots_click(data, null)
  }

  const handleClickLinkTitle = () => {
    EGG.entity.news.rest_name_link_click(restData, { section_name: 'widget' })
  }

  return (
    <ErrorBoundary>
      <div className={`${s.widget} ${className}`}>
        <div className={s.header}>
          <Title sizes={'h3 h5'} className={s.title}>
            Забронировать стол в{' '}
            <Link
              href={restData.url.path}
              target={'_blank'}
              className={s.link}
              ref={refLink}
              onClick={handleClickLinkTitle}>
              {restData.name}
            </Link>
          </Title>
        </div>

        <QuickBookingSlots
          classNameWrapper={s.schedule}
          slotsData={slotsData}
          restData={restData}
          classNameDots={s.dots}
          onClickSlot={handleClickSlot}
          onClickDots={handleClickDots}
        />
      </div>
    </ErrorBoundary>
  )
}

export default QuickBookingArticleD
