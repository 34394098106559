/**
 * @description Event Market, ticketmanager
 */

import React, { memo } from 'react'
import { useEffectOnce } from '@shared/lib/hooks/useEffectOnce'

const IS_PROD = process.env.GITLAB_STATUS === '0'

const LINK_EVENT_MARKET = 'https://www.afisha.ru/w/ticketmanager.js'

/**
 * @constant scriptData
 * @description описывает функцию для создания скрипта, и события onload
 */
const scriptData = `
  (function (_window, _document, _script, _src) {
    var n = _document.getElementsByTagName(_script)[0];
    var s = _document.createElement(_script);
    s.type = "text/javascript";
    s.async = true;
    s.src = _src;
    s.onload = function () {
      if (typeof _window.onEventMarketLoad === 'function') {
        _window.onEventMarketLoad();
      }
    };
    n.parentNode.insertBefore(s, n);
  })(window, document, "script", "${LINK_EVENT_MARKET}");
`

/**
 * @function addEventMarketScript
 * @description динамически создаёт скрипт и ждёт его загрузки
 */
const EventMarketScript = () => {
  useEffectOnce(() => {
    const addEventMarketScript = () => {
      return new Promise((resolve) => {
        // глобальный колбэк, который позволяет узнать о загрузке
        window.onEventMarketLoad = () => {
          console.info('EventMarket script loaded from onEventMarketLoad callback')
          resolve()
        }

        const scriptTag = document.createElement('script')
        scriptTag.type = 'text/javascript'
        scriptTag.text = scriptData

        // создаёт <script src="ticketmanager.js"> и вешает на него onload
        document.body.appendChild(scriptTag)
      })
    }

    // ***** запуск *****
    setTimeout(() => {
      if (IS_PROD) {
        addEventMarketScript().then(() => {
          console.info('Init "event market" script success!')
        })
      }
    }, 1)
  }, [])

  return <span className='visually-hidden' />
}

export default memo(EventMarketScript)
