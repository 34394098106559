import React, { FC } from 'react'
import s from './FilterMap.module.sass'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { BtnFilterMap } from '@shared/ui/Form/Single/Filters/ButtonMap'
import { openMapAC, TypePropsOpenMapAC } from '@widgets/Restaurant/Map/model/actions'

const FilterMap: FC = () => {
  const globalState = useAppSelector((store) => store)
  const { loading, output } = globalState.features.restaurant_filters
  const dispatch = useAppDispatch()

  const handleClick = () => {
    const params: TypePropsOpenMapAC = {}

    if (output.loading && !!output.slugs?.length) params.applyFilters = output.slugs
    else if (!output.loading && !!output.restaurants?.length) params.preloadRests = output.restaurants

    dispatch(openMapAC(params))
  }

  return <BtnFilterMap onClick={handleClick} />
}

export default FilterMap
