// main app page file

import './../public/index.css'
import { GlobalContext } from './context'

import React, { useCallback, useEffect, useState } from 'react'
import Head from 'next/head'

import { Router, useRouter } from 'next/router' // ? Next Pages Router ? - проверить и выбрать верный API
import { useParams } from 'next/navigation' // ? Next App Router ? - проверить и выбрать верный API
import { Provider } from 'react-redux'

import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from '@apollo/client'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { enableExperimentalFragmentVariables } from 'graphql-tag'
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev'

import app from '@app/app.module.sass'
import { wrapper } from '@app/model/store'
import { PageLayoutSkeleton } from '@app/PageLayout'
import { isSameRoute, routeMapping } from '@app/service/routing/routing'
import { isChangeCity, isUrlToMainPage } from '@app/PageLayout/handlers/useRouterHandler'

import AuthEventsScript from '@shared/lib/helpers/externalScripts/authEventsScript'
import EventMarketScript from '@shared/lib/helpers/externalScripts/eventMarketScript'
import CapirsAsyncScript from '@shared/lib/helpers/externalScripts/capirsAsyncScript'
import EggClickHouse from '@shared/lib/helpers/externalScripts/eggHouseScript'
import { YaMetrics } from '@shared/lib/helpers/yaMetrics'

import { sha256 } from '@shared/api/gql-service/sha-256-helper'
import { cityIDContext } from '@shared/api/gql-service/city-Id-context'
import { FontsProvider } from '../src/1_app/providers/FontsProvider'
import { HeaderAdvert } from '@app/PageLayout/components/HeaderAdvert'
import { addQaToUrl, getIsActiveQA } from '../src/6_shared/lib/helpers/autoTests/helpers'

// *** constants ***
const AFISHA_NAME = process.env.AFISHA_NAME
const IS_DEV = process.env.NODE_ENV === 'development'
const GITLAB_STATUS = process.env.GITLAB_STATUS || null
const SHOW_ANY_ADV = process.env.SHOW_ANY_ADV

const IS_PROD = GITLAB_STATUS === '0'
const IS_STAND = GITLAB_STATUS === '1'

// *** setup ***
Error.stackTraceLimit = 100

const GRAPHQL_AFISHA_PROD = 'https://graph.afisha.ru/graphql'
const GRAPHQL_AFISHA_STAGE = 'https://graph-dev.afisha.ru/graphql'
const GQL_AFISHA_URI = GRAPHQL_AFISHA_PROD

enableExperimentalFragmentVariables()

const httpLink = cityIDContext
  .concat(
    createPersistedQueryLink({
      sha256,
      useGETForHashedQueries: true,
    }),
  )
  .concat(
    createHttpLink({
      uri: GQL_AFISHA_URI,
      credentials: 'include',
    }),
  )

const clientAfisha = new ApolloClient({
  name: 'web',
  uri: GQL_AFISHA_URI,
  link: httpLink,
  cache: new InMemoryCache({
    // addTypename: false,
    // possibleTypes: possibleTypes,
  }),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
})

if (IS_DEV) {
  loadDevMessages()
  loadErrorMessages()
}

/**
 * @function MyApp — default next.js app component
 */

function MyApp(data) {
  const { store, props } = wrapper.useWrappedStore(data.pageProps)
  const route = useRouter()
  const params = useParams()
  // const dispatch = useAppDispatch()

  const [context, setContext] = useState({ isSkeletonPage: false })

  /**
   * @description START #rcmrclid=c579607b33b7efd3
   * @description RCM рекомендательная система
   * @todo takeout to custom hook
   * @todo перенести в node/express
   */

  const TARGET_RCM_KEY = 'rcmrclid'
  const [isRcmEnabled, setIsRcmEnabled] = useState(false)
  const [clientRcmValue, setClientRcmValue] = useState(null)

  const addRcmToUrl = (rcmHashValue) => {
    route.push({ hash: `${TARGET_RCM_KEY}=${rcmHashValue}` })
  }

  // TODO: логика может зациклиться, необходимо исправить
  useEffect(() => {
    const isRCM = window?.location?.hash?.indexOf(TARGET_RCM_KEY) > 0

    if (isRCM) {
      const rcmValue = window.location.hash
        .replace('#', '')
        .split('&')
        .filter((item) => item.includes(TARGET_RCM_KEY))[0]
        .split('=')[1]
      setClientRcmValue(rcmValue)
      setIsRcmEnabled(true)
    } else {
      // при отсутствии ключа в URL, передаем значение от сохраненного
      isRcmEnabled && clientRcmValue && addRcmToUrl(clientRcmValue)
    }
  }, [route, params])

  /**
   * @description END rcmrclid
   */

  // const AuthCallback = useCallback(() => {
  //   if (!Authorization.isInit) dispatch(initAuthAC())
  // }, [globalState.user, globalState.cityList])

  /**
   * @description START is_test_runner
   */

  const [isQaEnabled, setIsQaEnabled] = useState(false)

  useEffect(() => {
    const isQA = getIsActiveQA()

    if (isQA) setIsQaEnabled(true)
    // при отсутствии ключа в URL, передаем значение от сохраненного
    else isQaEnabled && addQaToUrl()
  }, [route, params])

  /**
   * @description END is_test_runner
   */

  const [loading, setLoading] = React.useState(false)
  const [skeletonRoute, setSkeletonRoute] = React.useState('')

  useEffect(() => {
    const start = (path, options) => {
      const isMobile = window.innerWidth <= 768

      // Если будет попытка перейти по ссылке на ресторан\новость другого города, то скелетон показывать не будем
      const isLinkWithAnotherCity = isChangeCity(path)
      if (isLinkWithAnotherCity) {
        const isLinkMainPage = isUrlToMainPage(path)
        if (!isLinkMainPage) return
      }

      // Если происходит переход на тот же тип страницы (напр. с главной Мск на главную Питера) и скроллинг меньше 2000px,
      // то скроллинга к шапке не происходит
      if (isMobile) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } else {
        if (!isSameRoute(path, route.asPath, routeMapping) || window.scrollY > 2000) {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }
      }

      // Если запрос данный (SSR) не нужно, то скелетон не показываем
      if (options.shallow) return

      setSkeletonRoute(path)
      setLoading(true)
      setContext(() => ({ isSkeletonPage: true }))
    }

    const end = (path) => {
      setTimeout(() => {
        setLoading(false)
        setContext(() => ({ isSkeletonPage: false }))
      }, 300)
      setTimeout(() => {
        setSkeletonRoute('')
      }, 1000)
    }

    Router.events.on('routeChangeStart', start)
    Router.events.on('routeChangeComplete', end)
    Router.events.on('routeChangeError', end)

    return () => {
      Router.events.off('routeChangeStart', start)
      Router.events.off('routeChangeComplete', end)
      Router.events.off('routeChangeError', end)
    }
  }, [route])

  const Component = data.Component

  return (
    <>
      <Head>
        <title>{AFISHA_NAME}</title>

        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1, user-scalable=no' />
        <meta name='theme-color' content='#ffffff' />

        <meta
          name='description'
          content='Вся информация, чтобы спланировать хороший отдых: кухня, средний чек, рейтинги и отзывы, фото, адреса и телефоны. Гид по ресторанам, барам, пабам и кафе Москвы.'
        />

        <link rel='icon' href='/favicon.ico' />
      </Head>

      <FontsProvider>
        <ApolloProvider client={clientAfisha}>
          <Provider store={store}>
            <>
              {/* important scripts */}
              <AuthEventsScript />
              <EventMarketScript />
              <CapirsAsyncScript />
            </>

            <GlobalContext.Provider value={[context, setContext]}>
              <HeaderAdvert />

              <div id='next-content' className={`${app.root}`}>
                {!loading ? <Component {...props} /> : <PageLayoutSkeleton route={skeletonRoute} />}
              </div>
            </GlobalContext.Provider>
          </Provider>
        </ApolloProvider>
      </FontsProvider>

      {/* Metrics script's START */}
      <>
        <EggClickHouse />
        <YaMetrics />
      </>
      {/* Metrics script's END */}
    </>
  )
}

export default MyApp
