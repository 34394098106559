import { createAsyncThunk } from '@reduxjs/toolkit'
import { setInitState, setLoading } from '@widgets/Common/ModalFoundMistake/model/slice'
import { API } from '@shared/api'
import { addTemporaryNotice } from '@widgets/Common/Notices'

type TypeData = {
  name: string
  email: string
  comment: string
  url: string
  type: string
}

export const sendRequest = createAsyncThunk(
  'foundMistake/sendRequest',
  async (data: TypeData, { dispatch, getState }) => {
    dispatch(setLoading(true))

    API.send_mistake({ body: data, error: { showUser: true } })
      .then((response) => {
        dispatch(addTemporaryNotice({ markup: 'Отправлено. Спасибо!' }))
        dispatch(setInitState())
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  },
)
