import { CityRetrieve } from '@shared/api/types/swaggerTypes'
import { TypeMappingCityReadOutput } from '@shared/api/middleware/mappingAPI/city/types'
import { QUICK_FILTERS_BY_CITIES } from '@shared/api/middleware/mappingAPI/city/constQuickFilters'
import { cityIn } from 'lvovich'
import { CONST_CITY_COORDINATES } from '@shared/api/middleware/mappingAPI/city/constCityCoordinates'

export const getMappingCityRead = (object: CityRetrieve): TypeMappingCityReadOutput => {
  const getCoordinates = () => {
    if (object.latitude && object.longitude) {
      return [object.latitude, object.longitude]
    } else if (object.slug in CONST_CITY_COORDINATES) {
      return CONST_CITY_COORDINATES[object.slug]
    } else return null
  }

  // @ts-ignore
  return {
    ...object,
    prepositional_case: cityIn(object.name),
    quick_filters: QUICK_FILTERS_BY_CITIES[object.slug] || null,
    coordinates: getCoordinates(),
  }
}
