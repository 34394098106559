import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchAdvSelection } from '@widgets/Common/PlugAds/model/thunks'
import { TypeMappingSelectionOutput } from '@shared/api/middleware/mappingAPI/selection/type'
import { TypeInitialState } from '@widgets/Common/PlugAds/model/types'
import { TypePlug } from '@widgets/Common/PlugAds/types/types'

const initialState: TypeInitialState = {
  status: 'empty',
  list: [],
  plugs: {},
  countUsed: 0,
}

const PlugAds = createSlice({
  name: 'plug-ads',
  initialState,
  reducers: {
    addIdPlugElement(state, action: PayloadAction<{ id: string; type: TypePlug }>) {
      if (action.payload.id in state.plugs) return
      if (!action.payload.id || !action.payload.type) return

      // ограничиваем по одному элементу каждого типа
      if (['desktop-large', 'desktop-shrink'].includes(action.payload.type)) {
        const countSimilarPlugs = Object.values(state.plugs).reduce((acc, plug) => {
          if (plug?.type === action.payload.type) return acc + 1
          return acc
        }, 0)
        if (countSimilarPlugs >= 1) return
      }

      if (state.list.length - state.countUsed >= 4) {
        state.plugs[action.payload.id] = {
          type: action.payload.type,
          list: state.list.slice(state.countUsed, state.countUsed + 4),
        }
      } else if (state.list.length === state.countUsed) {
        const selectionListMix = JSON.parse(JSON.stringify(state.list)).sort(() => Math.random() - 0.5)
        state.plugs[action.payload.id] = {
          type: action.payload.type,
          list: selectionListMix.slice(0, 4),
        }
      } else {
        state.plugs[action.payload.id] = {
          type: action.payload.type,
          list: [...state.list.slice(state.countUsed), ...state.list.slice(0, state.list.length - state.countUsed)],
        }
      }

      state.countUsed = state.countUsed + 4 > state.list.length ? state.list.length : state.countUsed + 4
    },

    removeIdPlugElement(state, action: PayloadAction<{ id: string }>) {
      if (action.payload.id in state.plugs) {
        state.plugs[action.payload.id] = undefined
      }
    },

    resetState() {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdvSelection.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchAdvSelection.fulfilled, (state, action: PayloadAction<TypeMappingSelectionOutput[]>) => {
        if (action.payload?.length) {
          state.list = action.payload
          state.status = 'success'
        } else {
          state.list = []
          state.status = 'empty'
        }
      })
      .addCase(fetchAdvSelection.rejected, (state, action) => {
        state.status = 'error'
      })
  },
})

export const { addIdPlugElement, removeIdPlugElement, resetState } = PlugAds.actions

export default PlugAds.reducer
