import Image from 'next/image'
import Link from 'next/link'
import s from './Footer.module.sass'

import { ContainerFooter } from '@shared/ui/Layout/Containers/ContainerFooter'
import { Text } from '@shared/ui/Typography/Text'
import { Title } from '@shared/ui/Typography/Title'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'

import IconApk from '@icons/footer-apps-apk.svg'
import IconAppStore from '@icons/footer-apps-app-store.svg'
import IconDzen from '@icons/social-zn.svg'
import IconRuStore from '@icons/footer-apps-ru-store.svg'
import IconTelegram from '@icons/social-tg.svg'
import IconVK from '@icons/social-vk.svg'
import QRCodeImage from '@images/afisha-qr.png'
import { useRouter } from 'next/router'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import React, { useState } from 'react'
import { openModal } from '@widgets/Common/ModalFoundMistake/model/actions'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { ModalAddEstablishment } from '@widgets/Common/ModalAddEstablishment'
import { EGG } from '@shared/api/analytics'

const srcAppStore =
  'https://apps.apple.com/ru/app/afisha-original/id6449671760?referrer=appmetrica_tracking_id%3D100434862846010941%26ym_tracking_id%3D4036302963600852144'
const srcRuStore =
  'https://apps.rustore.ru/app/ru.afisha.android?referrer=appmetrica_tracking_id%3D316607329581741903%26ym_tracking_id%3D9754951547239727150'
const srcApk =
  'https://www.afisha.ru/article/kak-ustanovit-afishu-na-android/?referrer=appmetrica_tracking_id%3D28376954520536502%26ym_tracking_id%3D6348018801305690714'

const addUTM = (thisLink, thisName = '') => {
  const UTM_STR = '?utm_source=restaurants&utm_medium=referral&utm_campaign=footer'
  const isRestsLink = thisName.toLowerCase() === 'рестораны'
  const filteredLink = thisLink.at(-1) === '/' ? thisLink : thisLink + '/'
  const output = isRestsLink ? thisLink : filteredLink + UTM_STR
  return output
}

function Footer() {
  const [openedForm, setOpenedForm] = useState(false)

  // const [emailInput, setEmailInput] = useState(getDefaultField('input_email'))
  const router = useRouter()
  const stateGlobal = useAppSelector((state) => state.global)
  const citySlug = stateGlobal.currentCity?.slug || router?.query?.city_slug || 'msk'
  const { isDesktop, isTablet, isMobile } = useClientResponsive()
  const dispatch = useAppDispatch()

  const handleOpenedForm = () => {
    setOpenedForm(true)
    EGG.footer.menu_item_click({ button_name: 'Добавить заведение' })
  }

  const navigation = {
    main: [
      { id: 'main-1', href: 'https://www.afisha.ru/rests/restaurants_mediakit.pdf', text: 'Медиа-кит' },
      { id: 'main-2', href: 'https://www.afisha.ru/article/about/', text: 'О компании' },
      { id: 'main-3', href: 'https://www.afisha.ru/article/advertising/', text: 'Реклама' },
      { id: 'main-4', href: `/${citySlug}/restaurants/tags/`, text: 'Карта сайта' },
      { id: 'main-5', href: 'https://www.afisha.ru/article/contact/', text: 'Контакты' },
    ],
    projects: [
      { id: 'projects-1', href: addUTM('https://daily.afisha.ru/'), text: 'Афиша Daily' },
      { id: 'projects-2', href: addUTM('https://picnic.afisha.ru/'), text: 'Пикник «Афиши»' },
      { id: 'projects-3', href: addUTM('https://www.afisha.ru/guides/'), text: 'Путеводители' },
      { id: 'projects-4', href: 'https://eda.ru/', text: 'Еда.ру' },
    ],
    other: [
      { id: 'other-1', href: `/${citySlug}/restaurants/restorator/`, text: 'Рестораторам' },
      { id: 'add-establishment', text: 'Добавить заведение', onClick: handleOpenedForm },
      { id: 'other-3', href: addUTM('https://www.afisha.ru/certificates/'), text: 'Сертификаты' },
    ],
  }

  const handleOpenFoundMistake = () => {
    EGG.footer.menu_item_click({ button_name: 'Нашли ошибку?' })
    dispatch(openModal())
  }

  const QRCode = () => (
    <div className={s['qr-code']}>
      <Image className={s['qr-code-image']} src={QRCodeImage.src || ''} alt='QR код' fill={true} />
      {/* <img className={s['qr-code-image']} src={QRCodeImage.src || ''} alt='QR код' /> */}
    </div>
  )

  const MobileAppsAfisha = () => (
    <div className={s.apps}>
      <Title sizes='h3 h5 h4' tag='h4' className={s.title}>
        Приложение Афиши&nbsp;&mdash; самый удобный способ забронировать стол
      </Title>

      <div className={s['apps-links']}>
        {/* AppStore */}
        <Link href={srcAppStore} className={`${s['app-link']} ${s['app-store']}`} target='_blank'>
          <IconAppStore />
        </Link>

        {/* RuStore */}
        <Link href={srcRuStore} className={s['app-link']} target='_blank'>
          <IconRuStore />
        </Link>

        {/* Apk */}
        <Link href={srcApk} className={s['app-link']} target='_blank'>
          <IconApk />
        </Link>
      </div>
    </div>
  )

  const handleClickRecommendationTech = () => {
    EGG.footer.menu_item_click({ button_name: 'recommendation technologies' })
  }
  const handleClickUserAgreement = () => {
    EGG.footer.menu_item_click({ button_name: 'user agreement' })
  }

  const handleClickPrivacyPolicy = () => {
    EGG.footer.menu_item_click({ button_name: 'privacy policy' })
  }
  // @ts-ignore
  // const handleValueEmail = (value) => {
  //   setEmailInput(value)
  // }

  const EmailBlock = () =>
    !isMobile ? (
      <div className={s['email-block']}>
        {/*<div className={s['email-block-text']}>*/}
        {/*  {isMobile ? (*/}
        {/*    <Title sizes='h4' tag='h5'>*/}
        {/*      Рассылка Афиши–Рестораны:*/}
        {/*    </Title>*/}
        {/*  ) : (*/}
        {/*    <Text sizes='M'>Рассылка Афиши–Рестораны:</Text>*/}
        {/*  )}*/}
        {/*  <Text sizes='M'>главные события недели — у вас на почте</Text>*/}
        {/*</div>*/}

        {/*<div className={s.subscribe}>*/}
        {/*  /!*@ts-ignore*!/*/}
        {/*  <Input handleValue={handleValueEmail} field={emailInput} className={s['subscribe-input']} />*/}
        {/*  <Button sizes='XL' mode='black' onClick={() => handleSubscribe()}>*/}
        {/*    Подписаться*/}
        {/*  </Button>*/}
        {/*</div>*/}

        <Text sizes={'S'} className={s.privacy}>
          <Link
            href='https://www.afisha.ru/article/pravila-primeneniya-rekomendatelnyh-tehnologiy/'
            className={s.footer_metaLink}
            target='_blank'
            onClick={handleClickRecommendationTech}>
            На&nbsp;информационном ресурсе применяются рекомендательные технологии в&nbsp;соответствии с&nbsp;Правилами
          </Link>{' '}
          <span>
            &copy;&nbsp;1999&ndash;2024&nbsp;ООО &laquo;Компания Афиша&raquo;. Все права защищены. Для лиц старше
            18&nbsp;лет.
          </span>{' '}
          <Link
            href={'https://www.afisha.ru/rests/usage/'}
            className={s.footer_metaLink}
            target='_blank'
            onClick={handleClickUserAgreement}>
            Пользовательское соглашение
          </Link>
          <span>, </span>
          <Link
            href='https://www.afisha.ru/article/privacy-policy/'
            target='_blank'
            onClick={handleClickPrivacyPolicy}
            scroll={false}>
            Политика конфиденциальности
          </Link>
        </Text>
      </div>
    ) : null

  const getLinksList = (array) => {
    return array.map((el) => {
      if ('href' in el) {
        return (
          <Text key={el.id} tag={'span'} sizes={'M'}>
            <Link
              href={el.href}
              target='_blank'
              onClick={() => EGG.footer.menu_item_click({ button_name: el.text })}
              rel='noreferrer'
              className={s['navigation-link']}
              scroll={false}>
              {el.text}
            </Link>
          </Text>
        )
      } else if ('onClick' in el) {
        return (
          <Text key={el.id} tag={'span'} sizes={'M'} className={s['navigation-link']} onClick={el.onClick}>
            {el.text}
          </Text>
        )
      } else {
        return null
      }
    })
  }

  const Navigation = () => (
    <div className={s.navigation}>
      {!isMobile ? (
        <>
          <div className={s['navigation-col']}>{getLinksList(navigation.main)}</div>
          <div className={`${s['navigation-col']} ${s['navigation-col--projects']}`}>
            {getLinksList(navigation.projects)}
          </div>
          <div className={s['navigation-col']}>{getLinksList(navigation.other)}</div>
        </>
      ) : (
        <>
          <div className={`${s['navigation-col']}`}>{getLinksList(navigation.projects)}</div>
          <div className={s['navigation-separator']}></div>
          <div className={s['navigation-col']}>
            {getLinksList(navigation.main)} {getLinksList(navigation.other)}{' '}
            <button onClick={handleOpenFoundMistake} type='button'>
              <Text tag={'span'} sizes={'S M'} className={s['navigation-link']}>
                Нашли ошибку?
              </Text>
            </button>
          </div>
        </>
      )}
    </div>
  )

  const Meta = () => (
    <div className={s.meta}>
      <div className={s['meta-row']}>
        <div className={s.social}>
          {/* Telegram */}
          <Link
            href='https://t.me/afisharestaurants'
            onClick={() => EGG.footer.social_network_click({ social_network: 'tg' })}
            className={`${s['social-link']}`}
            target='_blank'
            scroll={false}>
            <IconTelegram />
            Telegram канал Афиша Рестораны
          </Link>

          {/* VK */}
          <Link
            href='https://vk.com/afisharestaurants'
            onClick={() => EGG.footer.social_network_click({ social_network: 'vk' })}
            className={`${s['social-link']}`}
            target='_blank'>
            <IconVK />
            ВКонтакте группа Афиша Рестораны
          </Link>

          {/* Dzen */}
          <Link
            href='https://dzen.ru/afisharests'
            onClick={() => EGG.footer.social_network_click({ social_network: 'zen' })}
            className={`${s['social-link']}`}
            target='_blank'>
            <IconDzen />
            Яндекс.Дзен блог Афиша Рестораны
          </Link>
        </div>

        {!isMobile && (
          <button onClick={() => handleOpenFoundMistake()} type='button' className={s['meta-mistake-button']}>
            Нашли ошибку?
          </button>
        )}
      </div>

      {isMobile && (
        <Text sizes={'S'} className={s.privacy}>
          <Link
            href='https://www.afisha.ru/article/pravila-primeneniya-rekomendatelnyh-tehnologiy/'
            className={s.footer_metaLink}
            target='_blank'
            onClick={handleClickRecommendationTech}
            scroll={false}>
            На&nbsp;информационном ресурсе применяются рекомендательные технологии в&nbsp;соответствии с&nbsp;Правилами
          </Link>{' '}
          <span>
            &copy;&nbsp;1999&ndash;2024&nbsp;ООО &laquo;Компания Афиша&raquo;. Все права защищены. Для лиц старше
            18&nbsp;лет.
          </span>{' '}
          <Link
            href={'https://www.afisha.ru/rests/usage/'}
            className={s.footer_metaLink}
            target='_blank'
            onClick={handleClickUserAgreement}
            scroll={false}>
            Пользовательское соглашение
          </Link>
          <span>, </span>
          <Link href='https://www.afisha.ru/article/privacy-policy/' target='_blank' onClick={handleClickPrivacyPolicy}>
            Политика конфиденциальности
          </Link>
        </Text>
      )}
    </div>
  )

  return (
    <ErrorBoundary>
      {openedForm && (
        <ModalAddEstablishment
          handleSendStatus={(value) => console.log(value)}
          handleClose={() => setOpenedForm(false)}
          extended
        />
      )}

      <ContainerFooter classNameContainer={s.container} classNameContent={s.footer}>
        {/* ASIDE */}
        {isDesktop && (
          <div className={s.aside}>
            <QRCode />
          </div>
        )}
        {isTablet && (
          <div className={s.aside}>
            <div className={s['aside-top']}>
              <QRCode />
              <Title sizes='h3 h5 h4' tag='h4' className={s.title}>
                Приложение Афиши&nbsp;&mdash; самый удобный способ забронировать стол
              </Title>
            </div>
            <EmailBlock />
          </div>
        )}
        {isMobile && (
          <div className={s.aside}>
            <MobileAppsAfisha />
          </div>
        )}

        {/* MAIN */}
        <div className={s.main}>
          {!isTablet && !isMobile && (
            <div className={`${s['main-column']} ${s['main-column__1']}`}>
              {!isMobile && <MobileAppsAfisha />}
              <EmailBlock />
            </div>
          )}

          <div className={`${s['main-column']} ${s['main-column__2']}`}>
            <Navigation />
            <Meta />
          </div>
        </div>
      </ContainerFooter>
    </ErrorBoundary>
  )
}

export default Footer
