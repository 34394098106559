import { createSelector } from '@reduxjs/toolkit'

export const all = (state) => state.features.map
export const restaurants = (state) => all(state).restaurants
export const selected = (state) => all(state).selected
export const restaurantsIdsCity = (state) => all(state).restaurantsIdsCity
export const restaurantsIdsBbox = (state) => all(state).restaurantsIdsBbox
export const galleries = (state) => all(state).galleries

export const geolocation = (state) => all(state).geolocation

// export const restaurantsObjectsLength = (state) => Object.keys(restaurantsObjects(state)).length
//
// export const restaurantsArraySortDistance = createSelector(
//   [restaurantsObjects, restaurantsObjectsLength],
//   (objects, length) => {},
// )
