export const apiPublicMock = {
  booking_create: {},

  booking_get_slots: {},

  booking_discount: {},

  booking_restaurants_list: {},

  city_list: {},

  city_read: {},

  metro_list: {},

  news_list: {},

  news_read: {},

  photo_list: {},

  restaurant_list: {},

  restaurant_read: {},

  restaurant_nearest_rests: {},

  restaurant_popular_rests: {},

  restaurant_similar_rests: {},

  reviews_list: {},

  reviews_create: {},

  reviews_partial_update: {},

  reviews_update: {},

  reviews_delete: {},

  selection_list: {},

  selection_read: {},

  selection_restaurants: {},

  seo_read: {},

  tag_list: {},

  user_logout: {},

  user_me: {
    afisha_id: 7482887,
    phone: '+79997247512',
    email: 'alivedd@icloud.com',
    photo: 'https://avatars.rambler.ru/get/{id_a}/default?{id_b}',
    gender: 1,
    username: 'user_mail@mail.ru',
    first_name: 'Rick',
    last_name: 'Astley',
    stf: true,
    spr: true,

    favorites_objects: {
      restaurants: {},
    },

    owner_rests: [45826],

    owner_perms: {
      has_booking_access: true,
      has_special_access: true,
      has_description_access: true,
    },
  },

  variety_list: {},

  video_list: {},

  send_mistake: {},

  send_new_rest: {},
}
