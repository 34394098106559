import s from './OwnerReviewsPage.module.sass'

import React, { useEffect, useState } from 'react'

import { IconHasNoReviews } from '@pages/OwnerPersonalPage/ui/components/IconHasNoReviews'

import { Reviews } from '@entities/Restaurant/ReviewsNew'

import { Title } from '@shared/ui/Typography/Title'
import { Button } from '@shared/ui/Actions/Button'
import { initReviews } from '@pages/OwnerPersonalPage/model/OwnerReviewsModel/actions'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { LoadingSpinner } from '@shared/ui/Feedback/LoadingSpinner'
import {
  loadMoreReviews,
  sendCreateAnswer,
  updateListReviewsByFilter,
} from '@pages/OwnerPersonalPage/model/OwnerReviewsModel/thunks'
import { changeComponentCreate } from '@pages/OwnerPersonalPage/model/OwnerReviewsModel/slice'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

/**
 * @function OwnerTabChatbubbles - Отзывы
 */
// TODO: Сделать скелетоны
const OwnerReviewsPage = (props) => {
  const dispatch = useAppDispatch()
  const stateOwner = useAppSelector((store) => store.pages.owner_personal)
  const { is_ready, loading, restaurant, pagination, filters, reviewsByFilters } = stateOwner.reviews

  useEffect(() => {
    dispatch(initReviews({ restaurant: { id: props.data.id, name: props.data.name } }))
  }, [])

  const handleSelectFilter = (data) => {
    dispatch(updateListReviewsByFilter(data))
  }

  const handleShowAllReviews = (ev) => {
    dispatch(loadMoreReviews())
  }

  const handleSendAnswer = (data, comment) => {
    dispatch(changeComponentCreate({ body: comment, loading: true }))
    // @ts-ignore
    dispatch(sendCreateAnswer(data))
  }

  const ownerProps = {
    options: {
      loading: false,
      answerPossible: true,
      showSettings: true,
    },
    events: {
      onClickOpenSendAnswer: () => console.log('onClickOpenSendAnswer'),
      onClickSendAnswer: handleSendAnswer,
      onClickDeleteAnswer: () => console.log('onClickDeleteAnswer'),
      onClickEditAnswer: () => console.log('onClickEditAnswer'),
      onInputValueAnswer: () => console.log('onInputValueAnswer'),
    },
  }

  const commonProps = {
    options: {
      style: 'row' as 'row' | 'card',
    },
    events: {
      intersectionAnalyticMethod: () => console.log('intersectionAnalyticMethod'),
      onClickFullAnswer: () => console.log('onClickFullAnswer'),
      onClickDeleteReview: () => console.log('onClickDeleteReview'),
      onClickEditReview: () => console.log('onClickEditReview'),
      onClickFullReview: () => console.log('onClickFullReview'),
      onClickOpenSendAnswer: () => console.log('onClickOpenSendAnswer'),
    },
  }

  return (
    <ErrorBoundary>
      <div className={s['page-content']}>
        {loading.full ? (
          <LoadingSpinner loading={loading.full} size={40} />
        ) : reviewsByFilters?.length > 0 ? (
          <Reviews>
            <section className={s['page-content-section']} data-loading={loading.addition}>
              <div className={s['review-filter-header']}>
                {/*// @ts-ignore*/}
                <Reviews.Filters filters={filters} onSelect={handleSelectFilter} />
              </div>

              <div className={s['page-content-white-block']}>
                <div className={s['review-list-widjet']}>
                  {reviewsByFilters.map((review) => {
                    const sourceData = {
                      restName: restaurant.name,
                      reviewData: review,
                    }

                    return (
                      <div className={s['review-list-item']} key={review.id}>
                        <Reviews.Item sourceData={sourceData} ownerProps={ownerProps} commonProps={commonProps} />
                      </div>
                    )
                  })}
                </div>

                {loading.addition && <LoadingSpinner loading={loading.addition} size={40} />}
              </div>

              {pagination.page.next.number && (
                <div className={s['review-list-show-all-wrap']}>
                  <Button
                    className={s['review-list-show-all-button']}
                    onClick={(ev) => handleShowAllReviews(ev)}
                    sizes='XL'
                    mode='white-border'>
                    <span className={s['review-list-show-all-button-text']}>Смотреть все</span>
                  </Button>
                </div>
              )}
            </section>
          </Reviews>
        ) : (
          <section className={`${s['page-content-section']} ${s['page-content-section--centered']}`}>
            <IconHasNoReviews />

            <Title tag={'h3'} sizes={'h5'}>
              У заведения пока нет отзывов
            </Title>
          </section>
        )}
      </div>
    </ErrorBoundary>
  )
}

export default OwnerReviewsPage
