import React, { FC } from 'react'
import s from './FiltersReview.module.sass'
import { BtnFilterTag } from '@shared/ui/Form/Single/Filters/DefaultTag'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { updateReviewFilter } from '@entities/Restaurant/Reviews/model/thunks'
import { Carousel } from '@shared/ui/Layout/Carousel'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

const FiltersReview: FC = () => {
  const dispatch = useAppDispatch()
  const stateReviews = useAppSelector((state) => state.entities.restaurant_reviews)

  return (
    <ErrorBoundary>
      <div className={s['filters']}>
        <Carousel>
          <Carousel.Slider spacingMobile={4} spacingDesktop={4}>
            {stateReviews.filters.rates.map(
              (filter) =>
                filter.visible && (
                  <BtnFilterTag
                    key={filter.id}
                    active={filter.select}
                    counter={filter.count}
                    onClick={() => dispatch(updateReviewFilter({ type: 'rates', id: filter.id }))}>
                    {filter.title}
                  </BtnFilterTag>
                ),
            )}
          </Carousel.Slider>
        </Carousel>
      </div>
    </ErrorBoundary>
  )
}

export default FiltersReview
