import React, { FC, memo, useContext, useEffect, useMemo, useRef, useState } from 'react'
import s from './MapRestGrid.module.sass'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { Title } from '@shared/ui/Typography/Title'
import { RestCard, RestCardSkeleton } from '@widgets/Restaurant/ResturantCards/RestCard'
import { MapButtonActionForAside } from '@widgets/Restaurant/Map/ui/MapButtonActionForAside'
import { Button } from '@shared/ui/Actions/Button'
import { setRestSelectedAC } from '@widgets/Restaurant/Map/model/actions'
import IconVisibleGrid from '@icons/icon-arrow-small.svg'
import { MapContext } from '@widgets/Restaurant/Map/MapContext'
import { Text } from '@shared/ui/Typography/Text'
import { useMapTitle } from '@widgets/Restaurant/Map/hooks/useMapTitle'
import { EGG } from '@shared/api/analytics'
import Image from 'next/image'
import ImageNotFound from '@images/map/not_found.png'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

interface Props {
  className?: string
}

const MapRestGrid: FC<Props> = ({ className = '' }) => {
  const { restaurants, restaurantsIdsBbox, map } = useAppSelector((store) => store.features.map)
  const [visibleGrid, setVisibleGrid] = useState(true)
  const [mapInstance] = useContext(MapContext)
  const [restaurantsVisiblePart, setRestaurantsVisiblePart] = useState(1)
  const sizePart = 6

  const listRef = useRef<HTMLDivElement>()
  const dispatch = useAppDispatch()
  const elementGradient = useRef<HTMLDivElement>()
  const lastVisibleCardRef = useRef()
  const mapTitle = useMapTitle()
  const restsIdArray = restaurantsIdsBbox.data || []

  useEffect(() => {
    if (!elementGradient.current) return
    const handleScrollList = (e) => {
      const hasNextPart = restsIdArray.length > restaurantsVisiblePart * sizePart
      if (hasNextPart && e.target.scrollHeight - e.target.offsetHeight - e.target.scrollTop <= 750) {
        setRestaurantsVisiblePart(restaurantsVisiblePart + 1)
      }
      const isShowGradient = !!e.target.scrollTop
      elementGradient.current.dataset.visible = String(isShowGradient)
    }

    if (listRef.current) {
      listRef.current.addEventListener('scroll', handleScrollList)
    }
    return () => {
      listRef.current?.removeEventListener('scroll', handleScrollList)
    }
  }, [!!elementGradient.current, restaurantsVisiblePart, restsIdArray.length])

  useEffect(() => {
    setRestaurantsVisiblePart(1)
    if (listRef.current) listRef.current.scrollTop = 0
  }, [map.center, map.zoom])

  useEffect(() => {
    mapInstance.mapCreated?.setPadding({ left: visibleGrid ? 500 : 0 })
    return () => {
      mapInstance.mapCreated?.setPadding({ left: 0 })
    }
  }, [visibleGrid, !!mapInstance.mapCreated])

  const handleClickCard = (restData) => {
    dispatch(setRestSelectedAC({ restData, eventFrom: 'card', mapInstance }))
  }

  const handleHideGrid = () => {
    EGG.pages.Map.click_hide_list()
    setVisibleGrid(false)
  }

  const handleShowGrid = () => {
    EGG.pages.Map.click_show_list()
    setVisibleGrid(true)
  }

  const isShowList = restaurantsIdsBbox.status === 'success'
  const isShowSkeleton = restaurantsIdsBbox.status === 'loading'
  const isShowNotFound = restaurantsIdsBbox.status === 'empty'

  const getClassList = () => {
    let result = s.list
    if (isShowNotFound) result += ' ' + s['list-empty']
    return result
  }

  const classNamesFadeTransition = {
    appear: s['fade-appear'],
    appearActive: s['fade-appear-active'],
    appearDone: s['fade-appear-done'],
    enter: s['fade-enter'],
    enterActive: s['fade-enter-active'],
    enterDone: s['fade-enter-done'],
    exit: s['fade-exit'],
    exitActive: s['fade-exit-active'],
    exitDone: s['fade-exit-done'],
  }

  const classNamesTranslateTransition = {
    appear: s['translate-appear'],
    appearActive: s['translate-appear-active'],
    appearDone: s['translate-appear-done'],
    enter: s['translate-enter'],
    enterActive: s['translate-enter-active'],
    enterDone: s['translate-enter-done'],
    exit: s['translate-exit'],
    exitActive: s['translate-exit-active'],
    exitDone: s['translate-exit-done'],
  }

  const classNamesButtonTransition = {
    appear: s['button-animation-appear'],
    appearActive: s['button-animation-appear-active'],
    appearDone: s['button-animation-appear-done'],
    enter: s['button-animation-enter'],
    enterActive: s['button-animation-enter-active'],
    enterDone: s['button-animation-enter-done'],
    exit: s['button-animation-exit'],
    exitActive: s['button-animation-exit-active'],
    exitDone: s['button-animation-exit-done'],
  }

  return (
    <div className={`${s.wrapper} ${className}`}>
      <CSSTransition
        in={visibleGrid}
        key={'visibleGrid1'}
        timeout={300}
        classNames={classNamesTranslateTransition}
        unmountOnExit>
        <div className={s['grid-container']}>
          <MapButtonActionForAside onClick={handleHideGrid} icon={IconVisibleGrid} className={s.icon} />

          <div className={s.grid}>
            <Title sizes={'h2'} className={s.title}>
              {mapTitle}
            </Title>

            <div className={s['list-wrapper']}>
              <div className={s.gradient} ref={elementGradient}></div>

              <div className={getClassList()} ref={listRef}>
                <CSSTransition
                  in={isShowList}
                  key={1}
                  timeout={400}
                  classNames={classNamesFadeTransition}
                  unmountOnExit>
                  <div className={s['list-inner']}>
                    {restsIdArray.map((restId, index) => {
                      const restData = restaurants.data[restId]
                      if (!restData) {
                        // TODO: периодически появляется кейс, исправить
                        return
                      }
                      const currentMaxBlock = restaurantsVisiblePart * sizePart
                      if (index + 1 <= currentMaxBlock)
                        return (
                          <div ref={index + 1 === currentMaxBlock ? lastVisibleCardRef : null} key={restId}>
                            <RestCard
                              sizes={'M'}
                              onClickInsteadLink={() => handleClickCard(restData)}
                              type={'list'}
                              data={restData}
                              analytic={{
                                section_name: 'main',
                                section_index: index,
                                advertCardPlaceType: 'search',
                              }}
                            />
                          </div>
                        )
                      else if (index + 1 <= currentMaxBlock + 4)
                        return <RestCardSkeleton sizes={'M'} key={'addition-skeleton' + index} />
                    })}
                  </div>
                </CSSTransition>

                <CSSTransition
                  in={isShowSkeleton}
                  key={2}
                  timeout={400}
                  classNames={classNamesFadeTransition}
                  unmountOnExit>
                  <div className={s['list-inner']}>
                    {Array.from(Array(4).keys()).map((i, idx) => (
                      <RestCardSkeleton sizes={'M'} key={idx} />
                    ))}
                  </div>
                </CSSTransition>

                <CSSTransition
                  in={isShowNotFound}
                  key={3}
                  timeout={400}
                  classNames={classNamesFadeTransition}
                  unmountOnExit>
                  <div className={s['not-found']}>
                    <Image src={ImageNotFound.src} alt={'Не найдено'} width={338} height={329} />
                    <Title sizes={'h4'}>Ничего не найдено</Title>
                    <Text className={s['not-found__text']} sizes={'S'}>
                      Попробуйте поискать в другой области на карте
                    </Text>
                  </div>
                </CSSTransition>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        in={!visibleGrid}
        key={'visibleGrid2'}
        timeout={{
          appear: 300,
          enter: 0,
          exit: 300,
        }}
        classNames={classNamesButtonTransition}
        unmountOnExit>
        <Button sizes={'M'} mode={'white'} onClick={handleShowGrid} id={s['button-open-grid']}>
          <Text sizes={'S'}>Показать список</Text>
          <IconVisibleGrid />
        </Button>
      </CSSTransition>
    </div>
  )
}

export default memo(MapRestGrid)
