import React, { FC, useState } from 'react'
import s from './MenuMobile.module.sass'
import { useAppSelector } from '@app/model/store'
import { MenuLink } from '@widgets/Common/Header/ui/MenuLink'
import { Carousel } from '@shared/ui/Layout/Carousel'
import IconSearch from '@icons/icon-search.svg'
import { IconButton } from '@shared/ui/Actions/IconButton'
import { EGG } from '@shared/api/analytics'
import { useRouter } from 'next/router'

function removeURLParams(url) {
  const output = url.split('?')[0]
  return output
}

// TODO: add jsdoc, ? takeout thisName & lowercase to upper-fn, ? takeout func
const addUTM = (thisLink, thisName = '') => {
  const UTM_STR = '?utm_source=restaurants&utm_medium=referral&utm_campaign=headline'
  const isRestsLink = thisName.toLowerCase() === 'рестораны'

  let filteredLink
  filteredLink = thisLink.at(-1) === '/' ? thisLink : thisLink + '/'
  filteredLink = filteredLink.indexOf('?') >= 0 ? removeURLParams(filteredLink) : filteredLink

  return isRestsLink ? filteredLink : filteredLink + UTM_STR
}

const MenuMobile: FC = () => {
  const router = useRouter()
  const stateGlobal = useAppSelector((state) => state.global)

  const [menuItems, setMenuItems] = useState(() => {
    const filterArray = stateGlobal.topline?.filter((item) => item.MainLink.Name !== 'ВСЕ') || []
    return [
      {
        MainLink: {
          Name: 'search',
          Url: null,
        },
      },
      ...filterArray,
      {
        MainLink: {
          Name: 'daily',
          Url: addUTM('https://daily.afisha.ru/'),
        },
      },
    ]
  })

  const openSearch = () => {
    // ! TODO: add real search
    EGG.header.button_search_click()
    router.push('https://www.afisha.ru/search/restaurant/?query=')
  }

  return (
    <nav className={s.nav} role='navigation' aria-label='Разделы сайта'>
      {menuItems && (
        <Carousel>
          <Carousel.Slider spacingMobile={4}>
            {menuItems.map((item, i) => {
              switch (item.MainLink.Name) {
                case 'daily':
                  return (
                    <MenuLink
                      key={item.MainLink.Name}
                      dataId={item.MainLink.Name}
                      link={addUTM(item.MainLink.Url, item.MainLink.Name)}
                      role='menuitem'
                      className={s['nav-item']}
                      style={{ minWidth: '89px' }}
                      daily={item.MainLink.Name === 'daily'}
                    />
                  )
                case 'search':
                  return (
                    <IconButton
                      data-icon={'IconSearch'}
                      icon={IconSearch}
                      sizes='L M'
                      mode='white-border'
                      onClick={openSearch}
                    />
                  )
                default:
                  return (
                    <MenuLink
                      key={item.MainLink.Name}
                      dataId={item.MainLink.Name}
                      link={addUTM(item.MainLink.Url, item.MainLink.Name)}
                      text={item.MainLink.Name}
                      selected={item.MainLink.Name.toLowerCase() === 'рестораны'}
                      role='menuitem'
                      className={s['nav-item']}
                    />
                  )
              }
            })}
          </Carousel.Slider>
        </Carousel>
      )}
    </nav>
  )
}

export default MenuMobile
