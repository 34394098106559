import { Dispatch } from 'react'
import { AnyAction } from '@reduxjs/toolkit'
import {
  addRestaurantsData,
  deletePartRestaurants,
  resetMap,
  setActiveMap,
  setMapData,
  setRestaurantsIdsBboxStatus,
  setRestaurantsStatus,
  setSelectedData,
  setSelectedStatus,
} from '@widgets/Restaurant/Map/model/slice'
import { fetchRests, fetchRestGallery } from '@widgets/Restaurant/Map/model/thunks'
import { globalStore } from '@app/model/store'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { API } from '@shared/api'
import { TypeMapContextState } from '@widgets/Restaurant/Map/MapContext'
import { TypeMapStateDetail } from '@widgets/Restaurant/Map/model/types'

/**
 * Работа store карты происходит по следующему циклу (это касается как первого отображения, так дозагрузки данных):
 * 1. Получение, создание/расширение массива и отображение маркеров (markers)
 * 2. Заполнение restaurant.list пустыми элементами до соответствующей с markers.list длины массива
 * 3. Замещение пустых данных в restaurant.list полными по мере необходимости (клики на пины, переход на ресторан, показ листинга)
 */

export type TypePropsOpenMapAC = {
  selectedSlug?: string
  preloadRests?: TypeMappingRestOutput[]
  applyFilters?: any
}

export const openMapAC = (data?: TypePropsOpenMapAC) => async (dispatch: Dispatch<AnyAction>) => {
  const urlParams = new URLSearchParams(window.location.search)
  const getQueryMap = urlParams.get('map')

  if (!getQueryMap) {
    const baseUrl = window.location.origin + window.location.pathname
    const newUrl = baseUrl + '?map=true'
    history.pushState(null, null, newUrl)
  }

  // TODO: доработать
  // if (data?.preloadRests) {}
  // TODO: доработать
  // if (data?.selectedSlug) {}

  dispatch(setActiveMap(true))
}

export const updateMapCenter = (data: TypeMapStateDetail) => async (dispatch: Dispatch<AnyAction>) => {
  dispatch(setMapData(data))

  // Удаляем часть данных если зум отдаляется (чтобы не было большого количества маркеров)
  if (data.zoom < 10) dispatch(deletePartRestaurants(5))
  else if (data.zoom < 11) dispatch(deletePartRestaurants(15))
  else if (data.zoom < 12.5) dispatch(deletePartRestaurants(30))

  dispatch(setRestaurantsIdsBboxStatus('loading'))

  // @ts-ignore
  dispatch(fetchRests())
}

export const closeRestCardDesktopAC = () => (dispatch: Dispatch<AnyAction>) => {
  dispatch(setSelectedData(null))
  dispatch(setSelectedStatus('empty'))

  const baseUrl = window.location.origin + window.location.pathname
  const newUrl = baseUrl + '?map=true'
  history.pushState(null, null, newUrl)
}

export const closeMapAC = () => (dispatch: Dispatch<AnyAction>) => {
  const baseUrl = window.location.origin + window.location.pathname
  history.pushState(null, null, baseUrl)

  dispatch(setActiveMap(false))
  dispatch(resetMap())
}

type TypeSetRestSelectedAC = {
  restData?: TypeMappingRestOutput
  restSlug?: string

  eventFrom: 'slider' | 'pin' | 'card' | 'init'
  mapInstance?: TypeMapContextState
}
export const setRestSelectedAC =
  ({ restData, restSlug, eventFrom, mapInstance }: TypeSetRestSelectedAC) =>
  async (dispatch: Dispatch<AnyAction>) => {
    if (eventFrom !== 'init' && !mapInstance) return
    const { origin, pathname } = window.location
    const { restaurants, selected, galleries } = globalStore.getState().features.map
    const { isMobile } = globalStore.getState().global.client.responsive

    // Если данных на карте нет, то подразумевается, что карта была открыта с выбранным рестораном (в openMapAC data.selectedSlug)
    // В таком случае будет отображен только маркер выбранного ресторана
    if (restaurants.status === 'empty' || restSlug) {
      API.restaurant_read({ path: restSlug }).then((rest) => {
        dispatch(addRestaurantsData([rest]))
        dispatch(setRestaurantsStatus('success'))
        dispatch(setSelectedData(rest))
        dispatch(setSelectedStatus('success'))
      })
      return
    }

    if (!restData && !restSlug) {
      dispatch(setSelectedData(null))
      dispatch(setSelectedStatus('empty'))
      history.pushState(null, null, `${origin + pathname}?map=true`)
      return
    }

    if (selected.data && restData && restData.id === selected.data.id) return

    // Обновляем URL
    const linkHrefForCard = `${origin + pathname}?map=true&restaurant=${restData ? restData.url.slug : restSlug}`
    history.pushState(null, null, linkHrefForCard)

    // передвигаем карту только если был клик по карточке или при перелистывании слайдера
    if (mapInstance && ['card'].includes(eventFrom)) {
      // если десктоп, то смещаем центр вправо, так как левую часть занимает карточка ресторана (примерно 500px шириной)
      !isMobile && mapInstance.mapCreated.setPadding({ left: 500 })

      mapInstance.mapCreated.setZoom(15)
      mapInstance.mapCreated.setCenter(restData.address.coordinates)
    }

    // Для десктопа проверяем загружена ли галерея. Если нет, то подгружаем
    if (!isMobile) {
      const isLoadedGallery = restData.id in galleries.data

      // @ts-ignore
      !isLoadedGallery && dispatch(fetchRestGallery(restData.id))
    }

    dispatch(setSelectedData(restData))
    dispatch(setSelectedStatus('success'))
  }
