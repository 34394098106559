import { useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import { getIsActiveQA } from '@shared/lib/helpers/autoTests/helpers'

const DSN_PROD = 'https://3d5735a6c90d01a535e2292dd8ea6a43@sentry-saas.rambler-co.ru/227' // EQEQEQ
const DSN_DEV = 'https://3d5735a6c90d01a535e2292dd8ea6a43@sentry-saas.rambler-co.ru/227' // EQEQEQ

const GITLAB_STATUS = process.env.GITLAB_STATUS || null
const IS_PROD = GITLAB_STATUS === '0'
const IS_STAGE = process.env.IS_STAGE || null

// Подключение и настройка Sentry
export const useErrorCatcher = () => {
  useEffect(() => {
    const isTestRunner = getIsActiveQA()

    if ((IS_PROD || IS_STAGE) && !isTestRunner) {
      Sentry.init({ dsn: IS_PROD ? DSN_PROD : DSN_DEV })
    }
  }, [])
}
