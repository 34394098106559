import React, { FC, useState } from 'react'
import s from './QuickBookingRestC.module.sass'
import { Title } from '@shared/ui/Typography/Title'
import { getShortTextSchedule } from '@shared/lib/helpers/schedule/schedule'
import { Text } from '@shared/ui/Typography/Text'
import { useAppSelector } from '@app/model/store'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { QuickBookingRestCSkeleton } from '@widgets/Restaurant/QuickBooking/variants/QuickBookingRestC/index'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { QuickBookingSlots } from '@widgets/Restaurant/QuickBooking/components/QuickBookingSlots'
import { useNearestSlots } from '@widgets/Restaurant/QuickBooking/helpers/useNearestSlots'
import { EGG } from '@shared/api/analytics'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

interface Props {
  className?: string
}

const QuickBookingRestCWrapper: FC<Props> = ({ className = '' }) => {
  const stateRestaurant = useAppSelector((state) => state.pages.rest_item.data)
  const hasBookingAfisha = stateRestaurant.booking.afisha.active
  if (!hasBookingAfisha) return

  return stateRestaurant ? <QuickBookingRestC className={className} /> : <QuickBookingRestCSkeleton />
}

const QuickBookingRestC: FC<Props> = ({ className = '' }) => {
  const stateRestaurant = useAppSelector((state) => state.pages.rest_item.data)
  const { isMobile } = useClientResponsive()
  const { name, schedule } = stateRestaurant

  const [paramsNearestSlots, setParamsNearestSlots] = useState({
    restData: stateRestaurant as TypeMappingRestOutput,
    guests: 2,
  })

  const slotsData = useNearestSlots(paramsNearestSlots)

  const getTitle = () => {
    const tailRestName = !isMobile && ` в ${name}`
    if (!slotsData?.slots?.length) return `Забронировать стол ${tailRestName || ''}`
    const nearSlot = slotsData.slots[0]

    const nearSlotIsToday =
      new Date(nearSlot.day.value).toLocaleDateString() === new Date(Date.now()).toLocaleDateString()

    const tailDate = !nearSlotIsToday ? `на ${nearSlot.day.itemTextShort.toLowerCase()}` : null

    return `Забронировать стол ${tailDate || tailRestName || ''}`
  }

  const whenOpens = getShortTextSchedule(schedule.week)
  const handleClickSlot = (data) => {
    EGG.entity.booking.slots_quick_booking_slot_click(data, null)
  }
  const handleClickDots = (data) => {
    EGG.entity.booking.slots_quick_booking_dots_click(data, null)
  }

  return (
    <ErrorBoundary>
      <div className={`${s.widget} ${className}`}>
        <div className={s.header}>
          <Title sizes={'h3 h4'} className={s.title}>
            {getTitle()}
          </Title>
          <Text sizes={'M S'} className={s['work-time']}>
            {whenOpens}
          </Text>
        </div>

        <QuickBookingSlots
          slotsData={slotsData}
          restData={stateRestaurant as TypeMappingRestOutput}
          classNameWrapper={s.schedule}
          onClickSlot={handleClickSlot}
          onClickDots={handleClickDots}
        />
      </div>
    </ErrorBoundary>
  )
}

export default QuickBookingRestCWrapper
