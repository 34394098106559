import { Dispatch } from 'react'
import { AnyAction } from '@reduxjs/toolkit'
import {
  setList,
  setLoadingPage,
  setPagination,
  setSelectionsSection,
  setParamsRequest,
  setTitle,
  setDescription,
  setReady,
} from '@pages/Listing/model/slice'

export const initListingItem = (data) => (dispatch: Dispatch<AnyAction>) => {
  dispatch(setSelectionsSection(data.selections))
  dispatch(setParamsRequest(data.paramsRequest))
  dispatch(setReady(true))
}
export const initRestaurantList = (data) => (dispatch: Dispatch<AnyAction>) => {
  dispatch(setList(data.list))
  dispatch(setPagination(data.pagination))
  dispatch(setLoadingPage(false))
}

export const setTitleAndDescriptionListingAC =
  (seoTitle: string, seoDescription: string) => (dispatch: Dispatch<AnyAction>) => {
    dispatch(setDescription(seoDescription || ''))
    dispatch(setTitle(seoTitle || ''))
  }
