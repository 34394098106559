export const TARGET_QA_KEY_URL = process.env.TARGET_QA_KEY_URL

export const getIsActiveQA = () => {
  return typeof window !== 'undefined' && window.location.href.indexOf(TARGET_QA_KEY_URL) >= 0
}

export const addQaToUrl = () => {
  const urlParams = new URLSearchParams(window.location.search.split('?')[1])
  urlParams.append(`${TARGET_QA_KEY_URL}`, `true`)
  // @ts-ignore
  window.location.search = urlParams
}
