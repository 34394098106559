import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  removePrecheckedListFilters,
  resetFilter,
  revertRestaurantData,
  setCheckedFilterItem,
  setFilterListByType,
  setFilterVisibleByType,
  setLoading,
  setOutput,
  setOutputLoading,
  setRestaurantsData,
  updateParamsRequest,
} from '@features/Restaurant/RestaurantFilters/model/slice'
import { API } from '@shared/api'
import { TypeFiltersByKeyAll, TypeTag } from '@features/Restaurant/RestaurantFilters/model/types'
import { LIST_METRO_BY_CITY } from '@features/Restaurant/RestaurantFilters/model/const'
import { globalStore } from '@app/model/store'
import { getFormattedUrlRestFilter } from '@features/Restaurant/RestaurantFilters/helpers/helper'

// ссылки на new AbortController() текущего запроса для возможности прерывания.
const controllerSignalStack = []

const getControllerSignal = () => {
  const controller = new AbortController()
  // останавливаем последний запрос
  controllerSignalStack[controllerSignalStack.length - 1]?.abort()

  // добавляем новый в стек
  controllerSignalStack.push(controller)
  return controller.signal
}
const delControllerSignal = () => {
  if (controllerSignalStack.length) controllerSignalStack.shift()
}

const abortControllerSignal = () => {
  if (controllerSignalStack.length) controllerSignalStack[controllerSignalStack.length - 1]?.abort()
}

const getControllerSignals = () => {
  return controllerSignalStack.length
}

export const loadCatalogTags = createAsyncThunk(
  'restaurantFilters/loadCatalogTags',
  async (data, { dispatch, getState }) => {
    const stateGlobal = getState()
    // @ts-ignore
    const { filters } = stateGlobal.features.restaurant_filters
    // @ts-ignore
    const citySlug = stateGlobal.global.currentCity.slug

    dispatch(setLoading(true))

    // kitchen
    filters.kitchen.visible &&
      (await API.tag_list({ params: { catalogs: '1173', page: 1, page_size: 1000 } })
        .then((response) => dispatch(setFilterListByType({ filterType: 'kitchen', list: response.results })))
        .catch(() => dispatch(setFilterVisibleByType({ filterType: 'kitchen', visible: false }))))

    // type restaurants
    filters.establishment.visible &&
      (await API.tag_list({ params: { catalogs: '1174', page: 1, page_size: 1000 } })
        .then((response) => dispatch(setFilterListByType({ filterType: 'establishment', list: response.results })))
        .catch(() => dispatch(setFilterVisibleByType({ filterType: 'establishment', visible: false }))))

    // metro
    // TODO: запрос получит все станции метро (москва, питер, нижний и т.д.) вне зависимости от указанного города
    // TODO: когда бек будет отдавать станции в зависимости от города то можно будет раскоментировать
    // filters.metro.visible &&
    //   (await API.metro_list({ params: { page: 1, page_size: 1000 } })
    //     .then((response) => dispatch(setFilterListByType({ filterType: 'metro', list: response.results })))
    //     .catch(() => dispatch(setFilterVisibleByType({ filterType: 'metro', visible: false }))))
    const metroList = LIST_METRO_BY_CITY[globalStore.getState().global.currentCity.slug]
    const metroListSlugLowerCase = metroList.map((tag) => ({ ...tag, slug: tag.slug.toLowerCase() }))
    if (metroListSlugLowerCase && filters.metro.visible) {
      dispatch(setFilterListByType({ filterType: 'metro', list: metroListSlugLowerCase }))
    } else {
      dispatch(setFilterVisibleByType({ filterType: 'metro', visible: false }))
    }

    if (filters.price.visible) {
      dispatch(
        setFilterListByType({
          filterType: 'price',
          list: [
            { id: 11283, name: '₽', slug: 'less700', abs_url: `/${citySlug}/restaurants/restaurant_list/less700/` },
            { id: 11284, name: '₽₽', slug: '700-1700', abs_url: `/${citySlug}/restaurants/restaurant_list/700-1700/` },
            {
              id: 11285,
              name: '₽₽₽',
              slug: '1700-3000',
              abs_url: `/${citySlug}/restaurants/restaurant_list/1700-3000/`,
            },
            {
              id: 11286,
              name: '₽₽₽₽',
              slug: 'more3000',
              abs_url: `/${citySlug}/restaurants/restaurant_list/more3000/`,
            },
          ],
        }),
      )
    }

    if (filters.rating.visible) {
      dispatch(
        setFilterListByType({
          filterType: 'rating',
          list: [
            {
              id: 9991,
              name: '6-7',
              slug: '/rating_from_6/rating_to_7/',
              abs_url: `/${citySlug}/restaurants/restaurant_list/rating_from_6/rating_to_7/`,
            },
            {
              id: 9992,
              name: '7-8',
              slug: '/rating_from_7/rating_to_8/',
              abs_url: `/${citySlug}/restaurants/restaurant_list/rating_from_7/rating_to_8/`,
            },
            {
              id: 9993,
              name: '8-9',
              slug: '/rating_from_8/rating_to_9/',
              abs_url: `/${citySlug}/restaurants/restaurant_list/rating_from_8/rating_to_9/`,
            },
            {
              id: 9994,
              name: '9-10',
              slug: '/rating_from_9/rating_to_10/',
              abs_url: `/${citySlug}/restaurants/restaurant_list/rating_from_9/rating_to_10/`,
            },
          ],
        }),
      )
    }

    dispatch(setLoading(false))
  },
)

export const setCheckedFilterItemAsync = createAsyncThunk(
  'restaurantFilters/setCheckedFilterItemAsync',
  async (
    data: {
      filterType: TypeFiltersByKeyAll
      checked: boolean
      itemId?: number
    },
    { dispatch, getState },
  ) => {
    try {
      // @ts-ignore
      const stateFilters = getState().features.restaurant_filters
      if (!stateFilters.ready) return

      dispatch(setCheckedFilterItem(data))
      dispatch(setLoading(true))

      const typesFilterOnce = ['afishaSelected', 'hasBooking', 'openNow']
      const isFilterOnce = typesFilterOnce.includes(data.filterType)
      isFilterOnce && dispatch(setOutputLoading(true))

      return await dispatch(getRestaurantsByFilters())
        .catch((err) => {
          console.error(err)
          dispatch(setCheckedFilterItem({ ...data, checked: !data.checked }))
        })
        .finally(() => {
          if (!getControllerSignals()) {
            dispatch(setLoading(false))
          }
        })
    } catch (err) {
      console.error(err)
    }
  },
)

export const closeModalFilterList = createAsyncThunk(
  'restaurantFilters/closeModalFilterList',
  async (filterType: TypeFiltersByKeyAll, { dispatch, getState }) => {
    // @ts-ignore
    const stateFilters = getState().features.restaurant_filters
    if (!stateFilters.ready) return

    abortControllerSignal()
    delControllerSignal()
    dispatch(removePrecheckedListFilters())
    setTimeout(() => dispatch(revertRestaurantData()), 0)
  },
)

export const resetFilterAsync = createAsyncThunk(
  'restaurantFilters/resetFilterAsync',
  async (filterType: TypeFiltersByKeyAll, { dispatch, getState }) => {
    try {
      // @ts-ignore
      const stateFilters = getState().features.restaurant_filters
      if (!stateFilters.ready) return

      // Если все пункты списка которые пользователь сбрасывает являются предвыбранными,
      // то предыдущие полученые данные по рестикам становятся актуальными без доп. запроса
      const filter = stateFilters.filters[filterType]
      if ('list' in filter && !filter.checkedList.length) {
        dispatch(revertRestaurantData())
        dispatch(resetFilter(filterType))
        return
      }

      dispatch(resetFilter(filterType))
      dispatch(setLoading(true))
      dispatch(setOutputLoading(true))

      return await dispatch(getRestaurantsByFilters()).finally(() => {
        if (!getControllerSignals()) {
          dispatch(updateOutput())
          dispatch(setLoading(false))
          dispatch(setOutputLoading(false))
        }
      })
    } catch (err) {
      console.error(err)
    }
  },
)

export const getRestaurantsByFilters = createAsyncThunk(
  'restaurantFilters/getRestaurantsByFilters',
  async (data, { dispatch, getState }) => {
    try {
      // @ts-ignore
      dispatch(updateParamsRequest({ cityId: getState().global.currentCity.id }))

      // @ts-ignore
      const stateFilters = getState().features.restaurant_filters
      if (!stateFilters.isPreloaderResults) return

      const response = await API.restaurant_list({
        params: stateFilters.paramsRequest,
        config: { signal: getControllerSignal() },
      })
        .then((res) => {
          dispatch(setRestaurantsData({ restaurants: res.results, pagination: res.pagination }))
        })
        .catch((err) => {
          dispatch(setRestaurantsData({ restaurants: [], pagination: null }))
          console.error(err)
        })
        .finally(() => {
          delControllerSignal()
        })
      return response
    } catch (err) {
      console.error(err)
    }
  },
)

export const updateOutput = createAsyncThunk('restaurantFilters/updateOutput', async (data, { dispatch, getState }) => {
  const state = globalStore.getState().features.restaurant_filters
  const { filters } = state
  const { establishment, metro, kitchen, rating, price } = filters
  const { afishaSelected, hasBooking, openNow, ownerOffer } = filters

  // slugs
  const outputSlugs = [
    ...metro.checkedList.map((tag: TypeTag) => tag.slug),
    ...price.checkedList.map((tag: TypeTag) => tag.slug),
    ...kitchen.checkedList.map((tag: TypeTag) => tag.slug),
    ...establishment.checkedList.map((tag: TypeTag) => tag.slug),
  ]

  openNow.checked && outputSlugs.push('is_open_now')
  hasBooking.checked && outputSlugs.push('has_booking')
  afishaSelected.checked && outputSlugs.push('has_selection')
  ownerOffer.checked && outputSlugs.push('owner_offer')

  // rating_from and rating_to
  const sortPrecheckedList: TypeTag[] | [] = [...rating.preCheckedList].sort((tagA, tagB) => tagA.id - tagB.id)
  const firstCheckedRating = sortPrecheckedList.find((tag) => tag.checked)
  // @ts-ignore
  const lastCheckedRating = sortPrecheckedList.findLast((tag) => tag.checked)
  if (firstCheckedRating && lastCheckedRating) {
    const ratingFrom = Number(firstCheckedRating.name.split('-')[0])
    const ratingTo = Number(lastCheckedRating.name.split('-')[1])
    outputSlugs.push(`rating_from_${ratingFrom}`)
    outputSlugs.push(`rating_to_${ratingTo}`)
  }
  // urlQuery
  const outputUrlQuery = await getFormattedUrlRestFilter({
    slugs: outputSlugs,
    city_id: globalStore.getState().global.currentCity.id,
  })
  dispatch(setOutput({ slugs: outputUrlQuery.split('/'), urlQuery: outputUrlQuery }))
  dispatch(setOutputLoading(false))
})
