import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '@shared/api'
import { addTemporaryNotice } from '@widgets/Common/Notices'
import { setList, setLoading, setPaginationPage } from '@pages/Listing/model/slice'
import { setTitleAndDescriptionListingAC } from '@pages/Listing/model/actions'
import { globalStore } from '@app/model/store'

export const loadMorePage = createAsyncThunk('listing/loadMorePage', async (action, { dispatch, getState }) => {
  // @ts-ignore
  const stateGlobal = getState().global
  // @ts-ignore
  const state = getState().pages.listing

  const { items_addition, items_full } = state.loading
  if (items_addition || items_full || !state.pagination.page.next.number) return

  dispatch(setLoading({ ...state.loading, items_addition: true }))

  const params = {
    page: state.pagination.page.next.number,
    page_size: state.pagination.page.size,
    city: stateGlobal.currentCity.id,
    ...state.paramsRequest,
  }

  API.restaurant_list({ params })
    .then((response) => {
      dispatch(setList([...state.list, ...response.results]))
      dispatch(setPaginationPage(response.pagination))
    })
    .catch((error) => {
      console.error(`'Ошибка загрузки списка ресторанов' - ${error}`)
      dispatch(addTemporaryNotice({ markup: 'Ошибка загрузки списка ресторанов' }))
    })
    .finally(() => {
      dispatch(setLoading({ ...state.loading, items_addition: false }))
    })
})

export const loadPage = createAsyncThunk('listing/loadPage', async (pageNumber, { dispatch, getState }) => {
  // @ts-ignore
  const stateGlobal = getState().global
  // @ts-ignore
  const state = getState().pages.listing

  const { items_addition, items_full } = state.loading
  if (items_addition || items_full) return

  dispatch(setLoading({ ...state.loading, items_full: true }))

  const params = {
    page: pageNumber,
    page_size: state.pagination.page.size,
    city: stateGlobal.currentCity.id,
    ...state.paramsRequest,
  }

  API.restaurant_list({ params })
    .then((response) => {
      dispatch(setList(response.results))
      dispatch(setPaginationPage(response.pagination))
    })
    .catch((error) => {
      console.error(`'Ошибка загрузки списка ресторанов' - ${error}`)
      dispatch(addTemporaryNotice({ markup: 'Ошибка загрузки списка ресторанов' }))
    })
    .finally(() => {
      dispatch(setLoading({ ...state.loading, items_full: false }))
    })
})

export const updateTitleAndDescriptionAsync = createAsyncThunk(
  'listing/updateTitleAndDescriptionAsync',
  async (tags: string[], { dispatch, getState }) => {
    try {
      const globalState = globalStore.getState().global
      const cityId = globalState.currentCity.id

      let seoData = null

      if (tags) {
        const requestTags = tags.map((tag) => `&tags=${tag}`).join('')
        seoData = await API.seo_read({ path: `search/?city_id=${cityId}${requestTags}` })
      } else {
        seoData = await API.seo_read({ path: `search/?city_id=${cityId}` })
      }

      dispatch(setTitleAndDescriptionListingAC(seoData.request, seoData.description))
    } catch (error) {
      console.error(error)
    }
  },
)
