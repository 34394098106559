import React, { FC } from 'react'
import s from './QuickBookingRestB.module.sass'
import { Title } from '@shared/ui/Typography/Title'
import { getShortTextSchedule } from '@shared/lib/helpers/schedule/schedule'
import { Text } from '@shared/ui/Typography/Text'
import { useAppSelector } from '@app/model/store'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { QuickBookingRestCSkeleton } from '@widgets/Restaurant/QuickBooking/variants/QuickBookingRestC/index'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { QuickBookingSelectors } from '@widgets/Restaurant/QuickBooking/components/QuickBookingSelectors'
import { EGG } from '@shared/api/analytics'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

interface Props {
  className?: string
}

const QuickBookingRestBWrapper: FC<Props> = ({ className = '' }) => {
  const stateRestaurant = useAppSelector((state) => state.pages.rest_item.data)
  const hasBookingAfisha = stateRestaurant.booking.afisha.active
  if (!hasBookingAfisha) return

  return stateRestaurant ? <QuickBookingRestB className={className} /> : <QuickBookingRestCSkeleton />
}

const QuickBookingRestB: FC<Props> = ({ className = '' }) => {
  const stateRestaurant = useAppSelector((state) => state.pages.rest_item.data)
  const { isMobile } = useClientResponsive()
  const { name, schedule } = stateRestaurant

  const whenOpens = getShortTextSchedule(schedule.week)

  const handleSelectGuest = (data, value) => {
    EGG.entity.booking.selectors_quick_booking_select_guest(data, { input: value })
  }

  const handleSelectDate = (data, value) => {
    EGG.entity.booking.selectors_quick_booking_select_date(data, { input: value })
  }

  const handleSelectTime = (data, value) => {
    EGG.entity.booking.selectors_quick_booking_select_time(data, { input: value })
  }

  const handleOpenBooking = (data) => {
    EGG.entity.booking.selectors_quick_booking_apply_click(data)
  }

  return (
    <ErrorBoundary>
      <div className={`${s.widget} ${className}`}>
        <div className={s.header}>
          <Title sizes={'h3 h4'} className={s.title}>
            Забронировать стол {!isMobile && ` в ${name}`}
          </Title>

          <Text sizes={'M S'} className={s['work-time']}>
            {whenOpens}
          </Text>
        </div>

        <QuickBookingSelectors
          restData={stateRestaurant as TypeMappingRestOutput}
          onSelectGuest={handleSelectGuest}
          onSelectDate={handleSelectDate}
          onSelectTime={handleSelectTime}
          onOpenBooking={handleOpenBooking}
        />
      </div>
    </ErrorBoundary>
  )
}

export default QuickBookingRestBWrapper
