/* eslint-disable @typescript-eslint/no-explicit-any */

import { TypeMappingCityReadOutput } from '@shared/api/middleware/mappingAPI/city/types'
import { TypeMappingFavoritesGQLOutput } from '../../6_shared/api/middleware/mappingAPI/gql_favorites/type'

export type globalState = {
  storeLoaded: boolean
  user: {
    loading: boolean
    data: globalStateUser | null
  }
  client: {
    responsive: TypeClientResponsive
    service: {
      device_uuid: null | any
      session_uuid: null | any
    }
  }
  services: {
    // UTM метки
    utm: TypeUTMItem[]
  }

  currentPage: string | null
  needAskCity: boolean
  currentCity: {
    name: string
    genitive_case: string
    highlight: boolean
    id: number
    slug: string
  } | null
  cityRead: TypeMappingCityReadOutput | null
  cityList: TypeCityListItem[] | null
  topline: TypeTopline[] | null
}

export type TypeUTMItem = {
  key: string
  value: any
}

export type TypeCityListItem = {
  id: number
  name: string
  slug: string
  order: number
  visible: boolean
  highlight: boolean
  genitive_case: string
}

export type TypeTopline = {
  Layout: object
  MainLink: {
    Name: string
    Url: string
  }
}

export type TypeClientResponsive = {
  designEnv: TypeDesignEnv | 'default'
  isDesktopLarge: boolean
  isDesktop: boolean
  isTablet: boolean
  isMobile: boolean
}

export type globalStateUser = {
  afisha_id: number
  username: string
  first_name: string
  last_name: string
  photo: string | null
  email: string | null
  gender: string | null
  stf: false // Стафф - имеет доступ в админку
  spr: false // superUser
  favorites_objects: TypeMappingFavoritesGQLOutput
  owner_rests: object[]
  owner_perms: {
    has_booking_access?: boolean
    has_special_access?: boolean
    has_description_access?: boolean
  }
}

export type TypeDesignEnv = 'desktop' | 'tablet' | 'mobile'

export type TypeCategoryFavoriteObject =
  | 'restaurant'
  | 'selection'
  | 'news'
  | 'concert'
  | 'exhibition'
  | 'movie'
  | 'performance'
  | 'sport'

export enum FavoritePropsToStore {
  'restaurant' = 'restaurants',
  'selection' = 'selections',
  'news' = 'news',
  'concert' = 'concerts',
  'exhibition' = 'exhibitions',
  'movie' = 'movies',
  'performance' = 'performances',
  'sport' = 'sports',
}
