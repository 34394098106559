import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { globalStore, useAppSelector } from '@app/model/store'
import { getState } from '@app/model/slice'

export const getCitySlug = (url) => {
  const storeGlobal = globalStore.getState().global

  const delimitedUrl = url.replaceAll('/', ' ').trim().split(' ')
  const firstPath = delimitedUrl[0]
  const hasCity = storeGlobal?.cityList?.some((city) => city.slug === firstPath)
  return hasCity ? firstPath : false
}

export const isChangeCity = (url) => {
  const storeGlobal = globalStore.getState().global

  const city = getCitySlug(url)
  if (!city) return false
  return city !== storeGlobal.currentCity.slug
}

export const isUrlToMainPage = (url) => {
  const delimitedUrl = url.replaceAll('/', ' ').trim().split(' ')
  const city = getCitySlug(url)
  const twoPath = delimitedUrl.includes('restaurants')
  const isTwoPaths = delimitedUrl.length === 2

  return city && twoPath && isTwoPaths
}

// Обработчик для определения типа перехода на новую страницу. (в текущей вкладке или в новой)
// + Возврат текущего статуса загрузки страницы (isLoadingPage)
// Откроется новая страница - город в ссылке отличается от текущего, указанного в store
// Откроется на текущей странице - город совпадает, либо в ссылке нет slug города, либо переход на главную страницу другого города (попап изменения города)
export const useRouterHandler = () => {
  const router = useRouter()
  const [isLoadingPage, setIsLoadingPage] = useState(false)
  const storeGlobal = useAppSelector((store) => store.global)

  const handleRouteChangeStart = (url, { shallow }) => {
    const isLinkWithAnotherCity = isChangeCity(url)
    if (isLinkWithAnotherCity) {
      const isLinkToCurrentProjectPage = isUrlToMainPage(url)
      if (!isLinkToCurrentProjectPage) {
        window.open(url, '_blank')
        // Единственный вариант прервать выполнение текущего роутинга - выбросить ошибку
        throw 'Canceled next.js router transition to open the page in a new tab.'
      }
    }

    !shallow && setIsLoadingPage(true)
  }
  const handleRouteChangeComplete = (url, { shallow }) => {
    !shallow && setIsLoadingPage(false)
  }

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [storeGlobal])

  return [isLoadingPage]
}
