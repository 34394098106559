import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  addListOtherReviews,
  changeComponentCreate,
  changeComponentDelete,
  changeComponentEdit,
  changeFilter,
  hideComponentDelete,
  hideComponentEdit,
  initFilterRates,
  setListOtherReviews,
  setListUserReviews,
  setLoading,
  setPagination,
  setPaginationPage,
  setParamsRequest,
} from '@entities/Restaurant/Reviews/model/slice'
import { API } from '@shared/api'
import { addTemporaryNotice } from '@widgets/Common/Notices'
import { initSendReviews } from '@entities/Restaurant/Reviews/model/actions'
import { EGG } from '@shared/api/analytics'

export const loadReviewsLists = createAsyncThunk(
  'restaurantReviews/loadReviewsLists',
  async (restaurantId: string | number, { dispatch, getState }) => {
    // @ts-ignore
    const state = getState().entities.restaurant_reviews
    // @ts-ignore
    const hasUser = getState().global.user.data

    dispatch(setLoading({ ...state.loading, full: true }))

    const params = { page: 1, ...state.paramsRequest }

    if (hasUser) {
      // Загрузка и запись в state всех отзывов кроме отзывов текущего пользователя
      const requestOtherReviews = new Promise((resolve) => {
        API.reviews_list({ params: { ...params, is_my: false } })
          .then((response) => {
            const { results, pagination } = response
            dispatch(setListOtherReviews(results))
            dispatch(setPagination(pagination))
            dispatch(
              initFilterRates({
                all: pagination.total,
                positive: pagination.positive,
                negative: pagination.negative,
              }),
            )
          })
          .catch(() => dispatch(addTemporaryNotice({ markup: 'Ошибка загрузки отзывов' })))
          .finally((response) => resolve({ type: 'other', response }))
      })

      // Загрузка и запись в state всех отзывов кроме отзывов текущего пользователя
      const requestUserReviews = new Promise((resolve) => {
        API.reviews_list({ params: { ...params, is_my: true } })
          .then((response) => dispatch(setListUserReviews(response.results)))
          .catch(() => dispatch(addTemporaryNotice({ markup: 'Ошибка загрузки отзывов пользователя' })))
          .finally((response) => resolve({ type: 'user', response }))
      })

      return await Promise.all([requestOtherReviews, requestUserReviews]).then((responses) => {
        dispatch(setLoading({ ...state.loading, full: false }))
      })
    } else {
      return API.reviews_list({ params })
        .then((response) => {
          const { results, pagination } = response
          dispatch(setListOtherReviews(results))
          dispatch(setPagination(pagination))
          dispatch(
            initFilterRates({
              all: pagination.total,
              positive: pagination.positive,
              negative: pagination.negative,
            }),
          )
        })
        .catch(() => dispatch(addTemporaryNotice({ markup: 'Ошибка загрузки отзывов' })))
        .finally(() => {
          dispatch(setLoading({ ...state.loading, full: false }))
        })
    }
  },
)

export const updateReviewFilter = createAsyncThunk(
  'restaurantReviews/updateReviewsFilter',
  async (action: { type: 'rates' | 'date'; id: number }, { dispatch, getState }) => {
    // @ts-ignore
    const hasUser = getState().global.user.data
    // @ts-ignore
    const state = getState().entities.restaurant_reviews
    const getFilter = state.filters[action.type].find((filter) => filter.id === action.id)

    const { addition, full } = state.loading
    if (addition || full || !getFilter) return

    EGG.entity.reviews.filter_reviews_click({ filter_apply_value: getFilter.type })

    dispatch(setLoading({ ...state.loading, full: true }))
    dispatch(setParamsRequest({ ...state.paramsRequest, ...getFilter.paramsRequest }))
    dispatch(changeFilter(action))

    const params = {
      page: 1,
      ...state.paramsRequest,
      ...getFilter.paramsRequest,
    }
    if (hasUser) params.is_my = false

    API.reviews_list({ params })
      .then((response) => {
        dispatch(setListOtherReviews(response.results))
        dispatch(setPagination(response.pagination))
      })
      .catch(() => {
        dispatch(addTemporaryNotice({ markup: 'Ошибка загрузки отзывов' }))
      })
      .finally(() => {
        dispatch(setLoading({ ...state.loading, full: false }))
      })
  },
)

export const loadMoreReviews = createAsyncThunk(
  'restaurantReviews/loadMoreReviews',
  async (action, { dispatch, getState }) => {
    // @ts-ignore
    const hasUser = getState().global.user.data
    // @ts-ignore
    const state = getState().entities.restaurant_reviews

    const { addition, full } = state.loading
    if (addition || full || !state.pagination.page.next.number) return

    dispatch(setLoading({ ...state.loading, addition: true }))

    const params = {
      page: state.pagination.page.next.number,
      ...state.paramsRequest,
    }
    if (hasUser) params.is_my = false

    API.reviews_list({ params })
      .then((response) => {
        dispatch(addListOtherReviews(response.results))
        dispatch(setPaginationPage(response.pagination))
      })
      .catch((error) => {
        console.error(error)
        dispatch(addTemporaryNotice({ markup: 'Ошибка загрузки отзывов' }))
      })
      .finally(() => {
        dispatch(setLoading({ ...state.loading, addition: false }))
      })
  },
)

export const loadPageReviews = createAsyncThunk(
  'restaurantReviews/loadPageReviews',
  async (action: { page: number }, { dispatch, getState }) => {
    // @ts-ignore
    const hasUser = getState().global.user.data
    // @ts-ignore
    const state = getState().entities.restaurant_reviews

    const { addition, full } = state.loading
    if (addition || full) return

    dispatch(setLoading({ ...state.loading, full: true }))

    const params = {
      page: action.page,
      ...state.paramsRequest,
    }
    if (hasUser) params.is_my = false

    API.reviews_list({ params })
      .then((response) => {
        dispatch(setListOtherReviews(response.results))
        dispatch(setPaginationPage(response.pagination))
      })
      .catch((error) => {
        console.error(error)
        dispatch(addTemporaryNotice({ markup: 'Ошибка загрузки отзывов' }))
      })
      .finally(() => {
        dispatch(setLoading({ ...state.loading, full: false }))
      })
  },
)

export const sendCreateReview = createAsyncThunk(
  'restaurantReviews/sendCreateReview',
  async (action, { dispatch, getState }) => {
    // @ts-ignore
    const hasUser = getState().global.user.data
    // @ts-ignore
    const state = getState().entities.restaurant_reviews
    const { id, body, is_like } = state.components.create

    const { addition, full } = state.loading
    if (addition || full) return

    dispatch(changeComponentCreate({ loading: true }))

    const params = {
      body: {
        restaurant: id,
        body,
        is_positive_like: is_like,
      },
      repeated: true,
    }

    API.reviews_create(params)
      .then(async () => {
        dispatch(addTemporaryNotice({ markup: 'Отзыв отправлен на модерацию' }))
        localStorage.setItem(
          'review-process',
          JSON.stringify({
            // @ts-ignore
            user: getState().global.user.data.afisha_id,
            restaurant: id,
          }),
        )
        // @ts-ignore
        await dispatch(loadReviewsLists())
        dispatch(initSendReviews())
      })
      .catch((error) => {
        dispatch(addTemporaryNotice({ markup: 'Ошибка отправки отзыва' }))
      })
      .finally(() => {
        dispatch(changeComponentCreate({ loading: false }))
      })
  },
)

export const sendEditReview = createAsyncThunk(
  'restaurantReviews/sendEditReview',
  async (action, { dispatch, getState }) => {
    // @ts-ignore
    const hasUser = getState().global.user.data
    // @ts-ignore
    const state = getState().entities.restaurant_reviews
    const { id, body, is_like } = state.components.edit

    const { addition, full } = state.loading
    if (addition || full) return

    dispatch(changeComponentEdit({ loading: true }))

    const params = {
      path: id,
      body: { body, is_positive_like: is_like },
      repeated: true,
    }

    API.reviews_partial_update(params)
      .then(() => {
        dispatch(hideComponentEdit())
        dispatch(addTemporaryNotice({ markup: 'Отзыв обновлен' }))
        // @ts-ignore
        dispatch(loadReviewsLists())
      })
      .catch((error) => {
        dispatch(addTemporaryNotice({ markup: 'Ошибка обновления отзыва' }))
      })
      .finally(() => {
        dispatch(changeComponentEdit({ loading: false }))
      })
  },
)

export const sendDeleteReview = createAsyncThunk(
  'restaurantReviews/sendDeleteReview',
  async (action, { dispatch, getState }) => {
    // @ts-ignore
    const hasUser = getState().global.user.data
    // @ts-ignore
    const state = getState().entities.restaurant_reviews

    const { addition, full } = state.loading
    if (addition || full) return

    dispatch(changeComponentDelete({ loading: true }))

    const params = {
      path: state.components.delete.id,
      repeated: true,
    }

    API.reviews_delete(params)
      .then(() => {
        dispatch(hideComponentDelete())
        dispatch(addTemporaryNotice({ markup: 'Отзыв успешно удален' }))
        // @ts-ignore
        dispatch(loadReviewsLists())
      })
      .catch((error) => {
        dispatch(addTemporaryNotice({ markup: 'Ошибка удаления отзыва' }))
      })
      .finally(() => {
        dispatch(changeComponentDelete({ loading: false }))
      })
  },
)
