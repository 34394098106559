import React, { ChangeEvent, FC } from 'react'
import { BookingInput } from '@widgets/Restaurant/Booking/ui/components/BookingInput'
import l10n from '@widgets/Restaurant/Booking/lib/booking-localization'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { setCommentFieldValue } from '@widgets/Restaurant/Booking/model/slice'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { EGG } from '@shared/api/analytics'

const BookingComment: FC = () => {
  const dispatch = useAppDispatch()
  const { restaurantSource, form } = useAppSelector((store) => store.booking)
  const { commentField, isErrorsVisible } = form

  const handleCommentClick = () => {
    EGG.entity.booking.click_reserve_field_comment()
  }

  const handleCommentTyping = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value
    dispatch(setCommentFieldValue(value))

    if (value.length === 1) {
      EGG.entity.booking.input_reserve_field_comment()
    }
  }

  return (
    <ErrorBoundary>
      <BookingInput
        field={commentField}
        placeholder={l10n('guest_comment_placeholder')}
        isErrorsVisible={isErrorsVisible}
        onClick={handleCommentClick}
        onChange={handleCommentTyping}
      />
    </ErrorBoundary>
  )
}

export default BookingComment
