import { TypeVarietyItem } from '@pages/NewsGallery/model/types'
import { Dispatch } from 'react'
import { AnyAction } from '@reduxjs/toolkit'
import {
  changeSortSelected,
  removeSelectedSort,
  setList,
  setLoadingPage,
  setPagination,
  setSortList,
  setSpecialSection,
} from '@pages/NewsGallery/model/slice'
import { globalStore } from '@app/model/store'
import { loadNewPage } from '@pages/NewsGallery/model/thunks'
import { TypeMappingPaginationOutput } from '@shared/api/middleware/mappingAPI/pagination/type'
import { TypeMappingNewsOutput } from '@shared/api/middleware/mappingAPI/news/type'

export const initNewsGallery =
  (data: {
    pagination: TypeMappingPaginationOutput
    list: TypeMappingNewsOutput[]
    varieties: TypeVarietyItem[]
    special: object | null
  }) =>
  (dispatch: Dispatch<AnyAction>) => {
    const getItemForSpecialIndex = 18
    // Вытаскиваем новость для диспатча в specialSection для отображения в большой карточке
    const getItemForSpecial = data.list.find((el, index) => index == getItemForSpecialIndex)
    if (getItemForSpecial) dispatch(setSpecialSection(getItemForSpecial))
    // else if (data.special) dispatch(setSpecialSection(getItemForSpecial))

    if (getItemForSpecial) {
      const listWithoutSpecialItem = data.list.filter((el, index) => index != getItemForSpecialIndex)
      dispatch(setList(listWithoutSpecialItem))
    } else {
      dispatch(setList(data.list))
    }

    dispatch(setPagination(data.pagination))
    dispatch(setSortList(data.varieties))
    dispatch(setLoadingPage(false))
  }

export const setSortFromUrl = (slugs: string[] | null) => (dispatch: Dispatch<AnyAction>) => {
  const varietiesList = globalStore.getState().pages.news_gallery.sort.list

  if (slugs) {
    slugs.forEach((itemUrl) => {
      // @ts-ignore
      const findInList = varietiesList.find((item) => item.slug === itemUrl)
      findInList && dispatch(changeSortSelected(findInList.id))
    })
    // @ts-ignore
    dispatch(loadNewPage())
  }
}

export const changeSelectSortButton = (id: number) => (dispatch: Dispatch<AnyAction>) => {
  dispatch(changeSortSelected(id))
  // @ts-ignore
  dispatch(loadNewPage())
}

export const resetSort = () => (dispatch: Dispatch<AnyAction>) => {
  dispatch(removeSelectedSort())
  // @ts-ignore
  dispatch(loadNewPage())
}
