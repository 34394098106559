import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'

/**
 * @function generateSeoAnswers - TODO описание
 */

export const generateSeoAnswers = ({ city, restData }: { city: string; restData: TypeMappingRestOutput }) => {
  const data = {
    isOpen: restData.status.open,
    hasDelivery: restData.status.open && restData.services.delivery,
    hasBooking: restData.status.open && (restData.booking.tomesto.active || restData.booking.afisha.active),
    address: restData.address.address,
    city: city,
    price: restData.price.name.short,
    schedule: restData.schedule.common,
    restName: restData.name,
  }

  const getBookingAnswer = () => {
    const phoneNumber = restData.contacts.phone?.main
    const textPhoneNumber = phoneNumber ? `по телефону ${phoneNumber},` : ''

    const answerCanBooked = `Забронировать стол в ресторане ${data.restName} вы можете на сайте и в приложении Афиши, для этого нажмите на кнопку "Забронировать стол" и заполните форму заявки.`
    const answerCannotBooked = `Забронировать стол в ресторане ${data.restName} вы можете ${textPhoneNumber} через форму бронирования на официальном сайте ресторана или написав в сообщество ресторана в социальных сетях.`

    return data.hasDelivery ? answerCanBooked : answerCannotBooked
  }

  const seoArray = [
    {
      id: 1,
      question: `Как забронировать стол в ресторане ${data.restName}?`,
      answer: getBookingAnswer(),
    },
    {
      id: 2,
      question: `Можно ли заказать доставку из ресторана ${data.restName}?`,
      answer: `${data.hasDelivery ? 'Можно заказать доставку' : 'Нельзя заказать доставку'}.`,
    },
    {
      id: 3,
      question: `Какой средний чек в ресторане ${data.restName}?`,
      answer: `Средний чек в ресторане ${data.price}.`,
    },
    {
      id: 4,
      question: `Какой график работы ресторана ${data.restName}?`,
      answer: `${data.isOpen ? data.schedule : 'Ресторан закрыт'}.`,
    },
    {
      id: 5,
      question: `Где ${data.isOpen ? 'находится' : 'находился'} ресторан ${data.restName}?`,
      answer: `${data.city}, ${data.address}.`,
    },
  ]

  return seoArray
}
