import s from './OwnerHomePage.module.sass'

import React, { useEffect, useState, useCallback } from 'react'
import Image from 'next/image'

import { useAppDispatch, useAppSelector } from '@app/model/store'

import { initPostsPage } from '@pages/OwnerPersonalPage/model/OwnerPostsModel/actions'
import { initReviews } from '@pages/OwnerPersonalPage/model/OwnerReviewsModel/actions'
import { OwnerPublishedOffer } from '@pages/OwnerPersonalPage/ui/components/OwnerPublishedOffer'
import { RestSelectionItem } from '@pages/OwnerPersonalPage/ui/pages/OwnerHomePage/ui/RestSelectionItem'
import { StatisticFromSource } from '@pages/OwnerPersonalPage/ui/pages/OwnerHomePage/ui/StatisticFromSource'
import { StatisticItem } from '@pages/OwnerPersonalPage/ui/pages/OwnerHomePage/ui/StatisticItem'

import { Reviews } from '@entities/Restaurant/ReviewsNew'

import { getPlaceholderImage } from '@shared/lib/helpers/getPlaceholderImage'
import { PopupTrigger } from '@shared/ui/Overlays/Popup/ui/PopupTrigger'
import { PopupContent } from '@shared/ui/Overlays/Popup/ui/PopupContent'
import { Title } from '@shared/ui/Typography/Title'
import { Button } from '@shared/ui/Actions/Button'
import { Text } from '@shared/ui/Typography/Text'
import { Popup } from '@shared/ui/Overlays/Popup'
import { API } from '@shared/api'

import IconArrowDown24 from '@icons/icon-select-arrow-down-24.svg'
import { changeComponentCreate } from '@pages/OwnerPersonalPage/model/OwnerReviewsModel/slice'
import { sendCreateAnswer } from '@pages/OwnerPersonalPage/model/OwnerReviewsModel/thunks'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'

const IS_SUPERUSER = false
const IS_STATS_ACTIVE = true
const IS_CHANGE_REST_ENABLED = false

const getAccessText = (thisAccessState) => {
  const accessDateGranted = '25.06.2023'
  const accessDateExpire = '00-00 25.06.2023'
  const accessTextWithDate = thisAccessState
    ? `Доступ открыт до ${accessDateGranted}`
    : `Доступ скоро закончится в ${accessDateExpire}`
  const hasAccessDate = false
  const accessTextOutput = hasAccessDate ? accessTextWithDate : 'Доступ открыт'
  return accessTextOutput
}

/**
 * @function OwnerTabHome - Главная
 */
const OwnerHomePage = (props) => {
  const stateOwner = useAppSelector((store) => store.pages.owner_personal)
  const dispatch = useAppDispatch()

  const reviewsByFilters = stateOwner?.reviews?.reviewsByFilters

  // const { can_published, published } = stateOwner.posts

  useEffect(() => {
    dispatch(initReviews({ restaurant: { id: props.data.id, name: props.data.name }, unansweredMode: true }))
    dispatch(initPostsPage({ id: props.data.id, name: props.data.name }))
  }, [])

  const [isOpenRestaurantChange, setIsOpenRestaurantChange] = useState(false)
  // TODO avatar
  const restAvatarPhoto = null || getPlaceholderImage(props.data.id, 'square')

  const restName = props?.data?.name || 'Ресторан'
  const restAddress = props?.data?.address?.address || ''

  const accessState = true
  const accessText = getAccessText(accessState)
  const accessDateClassName = `${s['rest-info-access-date']} ${
    accessState ? s['rest-info-access-date--green'] : s['rest-info-access-date--red']
  }`

  const handleSendAnswer = (data, comment) => {
    dispatch(changeComponentCreate({ body: comment, loading: true }))
    // @ts-ignore
    dispatch(sendCreateAnswer(data))
  }

  const ownerProps = {
    options: {
      loading: false,
      answerPossible: true,
      showSettings: true,
    },
    events: {
      onClickOpenSendAnswer: () => console.log('onClickOpenSendAnswer'),
      onClickSendAnswer: handleSendAnswer,
      onClickDeleteAnswer: () => console.log('onClickDeleteAnswer'),
      onClickEditAnswer: () => console.log('onClickEditAnswer'),
      onInputValueAnswer: () => console.log('onInputValueAnswer'),
    },
  }

  const commonProps = {
    options: {
      style: 'row' as 'row' | 'card',
    },
    events: {
      intersectionAnalyticMethod: () => console.log('intersectionAnalyticMethod'),
      onClickFullAnswer: () => console.log('onClickFullAnswer'),
      onClickDeleteReview: () => console.log('onClickDeleteReview'),
      onClickEditReview: () => console.log('onClickEditReview'),
      onClickFullReview: () => console.log('onClickFullReview'),
      onClickOpenSendAnswer: () => console.log('onClickOpenSendAnswer'),
    },
  }

  const isReviewBlockAvailable = reviewsByFilters?.length > 0

  const restInfoMainDataClassName = `${s['rest-info-main-data']}`
  // const restInfoMainDataClassName = `${s['rest-info-main-data']} ${
  //   restAvatarPhoto ? '' : s['rest-info-main-data--no-photo']
  // }`

  const [weekStat, setWeekStat] = useState(null)
  const [weekStatPast, setWeekStatPast] = useState(null)

  const formatDate = (thisDate: any): string => {
    const curr_date = thisDate.getDate().toString().padStart(2, '0')
    const curr_month = (thisDate.getMonth() + 1).toString().padStart(2, '0')
    const curr_year = thisDate.getFullYear()
    return `${curr_year}-${curr_month}-${curr_date}`
  }

  const getDateRange = (gap = 0): string => {
    const now_end = new Date(Date.now())
    const now_start = now_end.setDate(now_end.getDate() - gap)
    return formatDate(new Date(now_start))
  }

  /**
   * @function handleStatCallback - получение данных для блока "Статистика за неделю"
   * @param cityId - цифирный идентификатор города
   * @param restId - цифирный идентификатор ресторана
   * @param mode - данный параметр используется для повторного запроса,
   *    для сравнения недель между друг другом
   */
  const handleStatCallback = useCallback(async (cityId, restId, mode) => {
    // dispatch(changeLoadState({ loading: true }))

    const fromRange = mode !== 'past' ? 6 : 13
    const toRange = mode !== 'past' ? 0 : 7

    await API.r_read_stat({
      params: {
        rid: `${restId}`,
        begin: getDateRange(fromRange),
        end: getDateRange(toRange),
        city_id: cityId,
      },
    })
      .then((data) => {
        if (mode !== 'past' && data?.length > 0) setWeekStat(data[0])
        if (mode === 'past' && data?.length > 0) setWeekStatPast(data[0])
      })
      .catch((error) => {
        console.error('handleStatCallback error', error)
      })
      .finally(() => {
        // dispatch(changeLoadState({ loading: false }))
      })
  }, [])

  useEffect(() => {
    const cityId = props?.global_data?.city_read?.id || '2'
    const restId = props?.data?.id

    if (cityId && restId) handleStatCallback(cityId, restId, null)
    if (cityId && restId) handleStatCallback(cityId, restId, 'past')
  }, [props?.global_data?.city_read?.id, props?.data?.id])

  const getIntPercent = (pastStat, actualStat) => {
    return Math.trunc(100 - 100 / (actualStat / pastStat))
  }

  const statViewArr = [
    {
      type: 'nearest',
      title: 'Показы «Места рядом»',
      value: weekStat?.nearest || 0,
      diff:
        !!weekStat?.nearest && !!weekStatPast?.nearest ? getIntPercent(weekStatPast.nearest, weekStat.nearest) : null,
    },
    {
      type: 'similar',
      title: 'Показы «Похожие»',
      value: weekStat?.similar || 0,
      diff:
        !!weekStat?.similar && !!weekStatPast?.similar ? getIntPercent(weekStatPast.similar, weekStat.similar) : null,
    },
    {
      type: 'search',
      title: 'Показы «Поиск»',
      value: weekStat?.search || 0,
      diff: !!weekStat?.search && !!weekStatPast?.search ? getIntPercent(weekStatPast.search, weekStat.search) : null,
    },
    {
      type: 'dynamics',
      title: 'Показы, динамика по сайту',
      value: weekStat?.dynamics || 0,
      diff:
        !!weekStat?.dynamics && !!weekStatPast?.dynamics
          ? getIntPercent(weekStatPast.dynamics, weekStat.dynamics)
          : null,
    },
    {
      type: 'selections',
      title: 'Показы, динамика в подборках',
      value: weekStat?.selections || 0,
      diff:
        !!weekStat?.selections && !!weekStatPast?.selections
          ? getIntPercent(weekStatPast.selections, weekStat.selections)
          : null,
    },
    {
      type: 'all',
      title: 'Показы всего',
      value: weekStat?.all || 0,
      diff: !!weekStat?.all && !!weekStatPast?.all ? getIntPercent(weekStatPast.all, weekStat.all) : null,
    },
  ]

  const isStatViewAvailable =
    statViewArr?.filter((statItem) => {
      return statItem && statItem.value !== 0
    }).length > 0

  return (
    <ErrorBoundary>
      <div className={s['page-content']}>
        <section className={s['page-content-section']}>
          <div className={s['page-content-white-block']}>
            <div className={restInfoMainDataClassName}>
              <div className={s['rest-info-logo-wrap']}>
                {restAvatarPhoto && (
                  <Image
                    className={s['rest-info-logo-image']}
                    src={restAvatarPhoto || ''}
                    alt={'Логотип ресторана ' + restName}
                    layout={'fill'}
                  />
                )}
              </div>

              <div className={s['rest-info-wrap']}>
                <Title sizes={'h2'} tag={'h2'} className={s['rest-info-name']}>
                  {restName}
                </Title>

                <Text sizes={'S'} className={s['rest-info-address']}>
                  {restAddress}
                </Text>

                <div className={s['rest-info-access-date-wrap']}>
                  <Text className={accessDateClassName} sizes={'S'}>
                    {accessText}
                  </Text>
                </div>
              </div>

              {IS_CHANGE_REST_ENABLED && (
                <Popup
                  open={isOpenRestaurantChange}
                  onOpenChange={(value) => setIsOpenRestaurantChange(value)}
                  placement={'bottom-end'}
                  offset={10}
                  flip={false}>
                  <PopupTrigger>
                    <Button
                      sizes={'L'}
                      mode={'white-border'}
                      className={s['rest-info-toggle-button']}
                      onClick={() => setIsOpenRestaurantChange(!isOpenRestaurantChange)}>
                      <span className={s['rest-info-toggle-icon']}>
                        <IconArrowDown24 />
                      </span>
                    </Button>
                  </PopupTrigger>

                  <PopupContent className={s['rest-selection-block-wrapper']}>
                    <RestSelectionItem
                      name={'O2 Lounge'}
                      address={'Москва, Тверская, 3, гостиница The Carlton, 12 этаж'}
                      slug={'test'}
                    />

                    <RestSelectionItem name={'O2 Lounge'} address={'Москва, Петровский б-р, 14'} slug={'test'} />

                    <RestSelectionItem
                      name={'O2 Lounge'}
                      address={'Москва, Краснопрудная, 12, гостиница Moscow Marriott Imperial Plaza'}
                      slug={'test'}
                    />
                  </PopupContent>
                </Popup>
              )}
            </div>
          </div>
        </section>

        {isStatViewAvailable && IS_STATS_ACTIVE && (
          <>
            {!IS_SUPERUSER ? (
              <>
                <section className={s['page-content-section']}>
                  <Title sizes={'h5'} tag={'h3'} className={s['stats-title']}>
                    Статистика за неделю
                  </Title>

                  <div className={s['stats-block']} data-two-block={true}>
                    {statViewArr.map((thisItem, i) => (
                      <StatisticItem key={i} title={thisItem.title} value={thisItem.value} diff={thisItem.diff} />
                    ))}
                  </div>

                  <>
                    <StatisticFromSource className={s['stat-source-descr']} />
                  </>
                </section>
              </>
            ) : (
              <>
                {/* temp unavailable block statement */}
                <section className={s['page-content-section']}>
                  <Title sizes={'h5'} tag={'h3'} className={s['stats-title']}>
                    Показы в динамике
                  </Title>

                  <div className={s['stats-block']}>
                    <StatisticItem title={'Показы «Места рядом»'} value={6476} diff={25} />
                    <StatisticItem title={'Показы «Похожие»'} value={37951} diff={12} />
                    <StatisticItem title={'Показы «Поиск»'} value={88678} diff={-8} />
                    <StatisticItem title={'Показы, динамика по сайту:'} value={133106} diff={-8} />
                    <StatisticItem title={'Показы, динамика в подборках:'} value={54147} diff={2} />
                    <StatisticItem title={'Показы всего:'} value={187123} diff={12} />
                  </div>
                </section>

                <section className={s['page-content-section']}>
                  <Title sizes={'h5'} tag={'h3'} className={s['stats-title']}>
                    Клики
                  </Title>

                  <div className={s['stats-block']}>
                    <StatisticItem title={'Клики по карточке «Промо»'} value={6476} diff={25} />
                    <StatisticItem title={'Клики по карточке «Спецпредложения»'} value={37951} diff={12} />
                    <StatisticItem title={'Клики по кнопке «Позвонить»'} value={88678} diff={-8} />
                    <StatisticItem title={'Клики по кнопке «Перейти на сайт»'} value={133106} diff={-8} />
                    <StatisticItem title={'Клики по кнопке «Перейти в соц.сеть»'} value={54147} diff={2} />
                    <StatisticItem title={'Клики по кнопке «Забронировать»'} value={187123} diff={12} />
                    <StatisticFromSource className={s['stats-block-source']} />
                  </div>
                </section>
              </>
            )}
          </>
        )}

        {!!stateOwner.posts.published && (
          <section className={s['page-content-section']}>
            <OwnerPublishedOffer />
          </section>
        )}

        {isReviewBlockAvailable && (
          <section className={s['page-content-section']}>
            <Title sizes={'h5'} tag={'h3'}>
              Отзывы без ответа
            </Title>

            <div className={s['review-data-wrap-margin']}>
              <div className={s['page-content-white-block']}>
                {reviewsByFilters.map((review, i) => {
                  const lastAvailableReviewItem = i === 0
                  const sourceData = {
                    restName: props.data.name,
                    reviewData: review,
                  }
                  return (
                    lastAvailableReviewItem && (
                      <div className={s['review-data-item']} key={i}>
                        <Reviews.Item
                          sourceData={sourceData}
                          ownerProps={ownerProps}
                          commonProps={commonProps}
                          key={review.id}
                        />
                      </div>
                    )
                  )
                })}
              </div>
            </div>
          </section>
        )}
      </div>
    </ErrorBoundary>
  )
}

export default OwnerHomePage
