import { Dispatch } from 'react'
import { AnyAction } from '@reduxjs/toolkit'
import { globalStore } from '@app/model/store'
import { fetchAdvSelection } from '@widgets/Common/PlugAds/model/thunks'

export const getSelectionsCountCurrentPage = (): number => {
  const store = globalStore.getState()

  try {
    switch (store.global.currentPage) {
      case 'app_home':
        return store.pages.app_home.sections.afishaSelections.list.length
      case 'rest_item':
        return 0
      case 'news_gallery':
        return 0
      case 'selections_gallery':
        return 0
      case 'news_item':
        return 0
      case 'selection_item':
        return 0
      case 'listing':
        return store.pages.listing.selections.list.length
    }
  } catch (e) {
    return 0
  }
}

export const initPlugState = () => (dispatch: Dispatch<AnyAction>) => {
  const store = globalStore.getState()
  const plugAdsState = store.features.plugAds
  if (plugAdsState.status === 'loading' || !store.global.currentCity) return

  const selectionsCountCurrentPage = getSelectionsCountCurrentPage()

  // @ts-ignore
  dispatch(fetchAdvSelection(selectionsCountCurrentPage))
}
