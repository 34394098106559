/**
 * @description Авторизация, Rambler ID, Сбер ID
 */

import React, { memo } from 'react'
import { useEffectOnce } from '@shared/lib/hooks/useEffectOnce'

import { initAuthAC } from '@app/model/actions'
import { useAppDispatch } from '@app/model/store'
import Authorization from '@app/service/authorization'

const IS_PROD = process.env.GITLAB_STATUS === '0'

const LINK_AUTH_EVENTS = 'https://id.rambler.ru/rambler-id-helper/auth_events.js'

/**
 * @constant scriptData
 * @description описывает функцию для создания скрипта, и события onload
 */
const scriptData = `
  (function (_window, _document, _script, _src) {
    var n = _document.getElementsByTagName(_script)[0];
    var s = _document.createElement(_script);
    s.type = "text/javascript";
    s.async = true;
    s.src = _src;
    s.onload = function () {
      if (typeof _window.onRamblerIdLoad === 'function') {
        _window.onRamblerIdLoad();
      }
    };
    n.parentNode.insertBefore(s, n);
  })(window, document, "script", "${LINK_AUTH_EVENTS}");
`

const AuthEventsScript = () => {
  const dispatch = useAppDispatch()

  useEffectOnce(() => {
    const addRamblerScript = () => {
      return new Promise((resolve) => {
        // глобальный колбэк, который позволяет узнать о загрузке
        window.onRamblerIdLoad = () => {
          console.info('Auth script loaded from onRamblerIdLoad callback')
          resolve()
        }

        const scriptTag = document.createElement('script')
        scriptTag.type = 'text/javascript'
        scriptTag.text = scriptData

        // создаёт <script src="auth_events.js"> и вешает на него onload
        document.body.appendChild(scriptTag)
      })
    }

    // ***** запуск *****
    setTimeout(() => {
      if (IS_PROD) {
        addRamblerScript().then(() => {
          if (!Authorization.isInit) {
            dispatch(initAuthAC())
            console.info('Init "RamblerID" script success!')
          }
        })
      } else {
        dispatch(initAuthAC('dev'))
      }
    }, 1)
  }, [])

  return <span className='visually-hidden' />
}

export default memo(AuthEventsScript)
