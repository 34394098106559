import { Dispatch } from 'react'
import { AnyAction } from '@reduxjs/toolkit'
import {
  resetStateReviews,
  setParamsRequest,
  setReadyWidget,
  setRestaurant,
} from '@pages/OwnerPersonalPage/model/OwnerReviewsModel/slice'
import { loadListReviewsByFilter } from '@pages/OwnerPersonalPage/model/OwnerReviewsModel/thunks'
import { TypeParamsRequest } from './types'

type TypeProps = {
  unansweredMode?: boolean
  restaurant: { id: number; name: string }
}
// Инициализация для страницы отзывов
export const initReviews = (data: TypeProps) => async (dispatch: Dispatch<AnyAction>) => {
  const paramsRequest: TypeParamsRequest = {
    restaurant: data.restaurant.id,
    page_size: 4,
    page: 1,
    is_positive_like: null,
    owner_comment_created_isnull: null,
  }

  if (data.unansweredMode) paramsRequest.owner_comment_created_isnull = true

  dispatch(resetStateReviews())
  dispatch(setRestaurant(data.restaurant))
  dispatch(setParamsRequest(paramsRequest))
  // @ts-ignore
  await dispatch(loadListReviewsByFilter())

  dispatch(setReadyWidget(true))
}
