import { useEffect } from 'react'
import { useAppDispatch } from '@app/model/store'
import { setDeviceType } from '@app/model/slice'

// Подписка на ресайз изменения размера экрана
export const useResizeWindow = () => {
  const dispatch = useAppDispatch()

  const handleResize = (e) => dispatch(setDeviceType({ width: e.target.innerWidth }))

  useEffect(() => {
    dispatch(setDeviceType({ width: window.innerWidth }))
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
}
