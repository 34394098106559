import React, { useEffect, useRef } from 'react'
import { TypeMapContextState } from '@widgets/Restaurant/Map/MapContext'
import { globalStore, useAppSelector } from '@app/model/store'
import { renderToString } from 'react-dom/server'
import { MapPinLabel } from '@widgets/Restaurant/Map/ui/MapPinLabel'
import { Marker } from '@2gis/mapgl/global'
import IconPinRed from '@icons/map/pin-red.svg?url'
import { setRestSelectedAC } from '@widgets/Restaurant/Map/model/actions'
import IconPinGreen from '@icons/map/pin-green.svg?url'
import IconPinGreenLight from '@icons/map/pin-green-light.svg?url'
import IconPinUser from '@icons/map/pin-user.svg?url'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { EGG } from '@shared/api/analytics'

/**
 * Создание и вывод маркеров на карту по массиву ресторанов
 */
type TypeCreateMarker = {
  mapInstance: TypeMapContextState
  rest: TypeMappingRestOutput
  labelHover: boolean
}

const createRestMarker = ({ mapInstance, rest, labelHover = false }: TypeCreateMarker) => {
  if (!rest.address.coordinates) return

  const iconSrc = rest.feedback.rating >= 6 || rest.isShowUI.booking ? IconPinGreen.src : IconPinGreenLight.src
  const marker = new mapInstance.mapglAPI.Marker(mapInstance.mapCreated, {
    userData: {
      restData: rest,
      defaultIcon: iconSrc,
    },
    coordinates: rest.address.coordinates,
    interactive: true,
    icon: iconSrc,
  })

  // Добавление события для перехода на карточку рестика
  marker.on('click', () => {
    handleRestSelect(marker, mapInstance)
  })

  if (labelHover) addEventHoverMarkerLabel(marker, mapInstance)

  return marker
}

/**
 * Добавление слушателей событий для отображения всплывающих подсказок для маркеров (десктоп)
 */
const addEventHoverMarkerLabel = (marker: Marker, mapInstance) => {
  let timerHoverId = null

  marker.on('mouseover', (e) => {
    timerHoverId = setTimeout(() => {
      // @ts-ignore
      const isActive = e.targetData._impl.normalStateData.icon.includes('pin-red')
      if (!isActive) addHoverMarkerLabel(e.targetData, mapInstance)
    }, 500)
  })

  marker.on('mouseout', () => clearTimeout(timerHoverId))
  marker.on('click', () => clearTimeout(timerHoverId))
}

/**
 * Работа с отображением всплывающих подсказок маркеров (десктоп)
 */
const addHoverMarkerLabel = (marker: Marker, mapInstance) => {
  const markerLabel = new mapInstance.mapglAPI.HtmlMarker(mapInstance.mapCreated, {
    coordinates: marker.userData.restData.address.coordinates,
    html: renderToString(<MapPinLabel restData={marker.userData.restData} />),
    anchor: [22, 24],
  })
  const markerLabelElement = markerLabel.getContent().querySelector('#marker-label')

  markerLabelElement.addEventListener('click', () => {
    handleRestSelect(marker, mapInstance)
    markerLabel.destroy()
  })
  markerLabelElement.addEventListener('mouseleave', () => markerLabel.destroy(), { once: true })
}

const handleRestSelect = (marker: Marker, mapInstance) => {
  EGG.entity.restaurant.card_click(marker.userData.restData, { section_name: 'map', section_index: 0 })
  globalStore.dispatch(setRestSelectedAC({ restData: marker.userData.restData, eventFrom: 'pin', mapInstance }))
}

export type TypeMarkersSources = {
  [id: number]: Marker
}

/**
 * @description Хук для работы с маркерами карты
 */
export const useHandleMarkers = (mapInstance: TypeMapContextState) => {
  const { isMobile } = useClientResponsive()
  const { restaurants, selected, geolocation } = useAppSelector((store) => store.features.map)

  /**
   * Объект для хранения экземпляров маркеров
   * В нем всегда должны быть актуальные данные, они должны быть синхронизированы с markers.list в store
   */
  const mapSourceMarkers = useRef<TypeMarkersSources>({})

  const mapUserMarker = useRef<Marker>()

  /**
   * Синхронизация со списком маркером в store (store -> markers -> list)
   * Создание нового и удаление лишнего
   */
  useEffect(() => {
    if (mapInstance.mapglAPI) syncRestsStoreAndMarkers()
  }, [!!mapInstance.mapglAPI, restaurants.data])

  useEffect(() => {
    if (!selected.data) return
    mapSourceMarkers.current[selected.data.id]?.setIcon({ icon: IconPinRed.src })
    return () => {
      const prevMarkerSelect = mapSourceMarkers.current[selected.data.id]
      prevMarkerSelect?.setIcon({ icon: prevMarkerSelect.userData.defaultIcon })
    }
  }, [selected.data])

  /**
   * Синхронизация данных для геолокации пользователя
   */
  useEffect(() => {
    if (mapInstance.mapglAPI) updateUserMarker(geolocation.coords)
  }, [geolocation.coords])
  const updateUserMarker = (coords) => {
    if (mapUserMarker.current) {
      if (coords) mapUserMarker.current.setCoordinates(coords)
      else {
        mapUserMarker.current.destroy()
        mapUserMarker.current = null
      }
    }

    if (!mapUserMarker.current && coords) {
      mapUserMarker.current = new mapInstance.mapglAPI.Marker(mapInstance.mapCreated, {
        userData: {
          defaultIcon: IconPinUser.src,
        },
        coordinates: coords,
        interactive: false,
        icon: IconPinUser.src,
      })
    }
  }

  /**
   * Синхронизация данных для маркеров mapSourceMarkers и restaurants.data
   */
  const syncRestsStoreAndMarkers = () => {
    addMissingMarkers()
    removeUnnecessaryMarkers()
  }

  /**
   * Добавление маркеров для которых появились данные в store restaurants.data
   */
  const addMissingMarkers = () => {
    Object.keys(restaurants.data).forEach((restId) => {
      if (!(restId in mapSourceMarkers.current)) {
        mapSourceMarkers.current[restId] = createRestMarker({
          mapInstance,
          rest: restaurants.data[restId],
          labelHover: !isMobile,
        })
      }
    })
  }

  /**
   * Удаление маркеров, данные которых отсутствуют в store restaurants.data
   */
  const removeUnnecessaryMarkers = () => {
    Object.keys(mapSourceMarkers.current).forEach((idOldMarker) => {
      if (!(idOldMarker in restaurants.data)) deleteMarkerById(idOldMarker)
    })
  }

  /**
   * Удаление маркера по id
   */
  const deleteMarkerById = (id: string | number) => {
    mapSourceMarkers.current[id].destroy()
    delete mapSourceMarkers.current[id]
  }
}
