import s from './BookingScreenForm.module.sass'

import React, { useState, useEffect, useCallback, FC } from 'react'

import l10n from '../../../lib/booking-localization'
import { getGuestCountString } from '../../../lib/helper'

import { useAppDispatch, useAppSelector } from '@app/model/store'
import { RamblerIDAPI } from '@app/service/getUserData'

import { BookingButton } from '@widgets/Restaurant/Booking/ui/components/BookingButton'
import { BookingComment } from '@widgets/Restaurant/Booking/ui/components/BookingComment'
import { BookingDateTime } from '@widgets/Restaurant/Booking/ui/components/BookingDateTime'
import { BookingFullName } from '@widgets/Restaurant/Booking/ui/components/BookingFullName'
import { BookingGuests } from '@widgets/Restaurant/Booking/ui/components/BookingGuests'
import { BookingPhone } from '@widgets/Restaurant/Booking/ui/components/BookingPhone'
import { closeBooking } from '@widgets/Restaurant/Booking'
import { handlePhoneFormatting } from '@widgets/Restaurant/Booking/lib/booking-phone-formatting'
import { submitBooking } from '@widgets/Restaurant/Booking/model/actions'

import { setNameFieldValue } from '@widgets/Restaurant/Booking/model/slice'
import { setPhoneFieldValue } from '@widgets/Restaurant/Booking/model/slice'

import { BookingAlerts } from '@widgets/Restaurant/Booking/ui/components/BookingAlerts'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { IconButton } from '@shared/ui/Actions/IconButton'
import { Text } from '@shared/ui/Typography/Text'
import { Title } from '@shared/ui/Typography/Title'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'

import IconClose from '@icons/icon--x-thin.svg'
import { EGG } from '@shared/api/analytics'

declare const window: Window &
  typeof globalThis & {
    rcm: any
  }

export interface RIDUserData {
  email: string
  phone: string
  firstName: string
  lastName: string
}

/**
 * @function BookingPhone
 * @description
 * @todo вынести за пределы бронирования: userData, setUserData, RIDUserData
 *    в отдельный сервис, тк это части для работы с Rambler_ID
 *    получение данных необходимо userData осуществлять средствами redux
 */
const BookingScreenForm: FC = () => {
  const { isDesktop } = useClientResponsive()

  const dispatch = useAppDispatch()
  const storeBooking = useAppSelector((state) => state.booking)
  const { isOpenScreenConfirm, restaurantSource } = storeBooking
  const { dateField, timeField, guestsCountField, phoneField, checkDiscountLoading } = storeBooking.form
  const bookingCheckoutId = null // TODO: AREST-5043, нужно отправлять id бронирования в любом формате

  const handleAbortBookingCallback = useCallback(() => {
    !isOpenScreenConfirm && dispatch(closeBooking(null))
    EGG.entity.booking.click_reserve_close()
  }, [dispatch, isOpenScreenConfirm, restaurantSource.id, restaurantSource.name])

  // rcm rec-sys event
  useEffect(() => {
    // TODO: useRCM('bookingCheckout', { itemId: 'Restaurant_' + restaurantSource.id, checkoutId: bookingCheckoutId, })
    // window.rcm &&
    //   window.rcm('bookingCheckout', {
    //     itemId: 'Restaurant_' + restaurantSource.id,
    //     checkoutId: bookingCheckoutId,
    //   })
  }, [restaurantSource.name, restaurantSource.id])

  // egg click-house event
  useEffect(() => {
    EGG.entity.booking.appear_screen_reserve()
  }, [restaurantSource.name, restaurantSource.id])

  const handleClickSubmit = () => {
    dispatch(submitBooking())
  }

  const getDisabledStatusButton = () => {
    return dateField.loading || timeField.loading || checkDiscountLoading
  }

  /**
   * @description получение польовательских данных
   */
  const [userData, setUserData] = useState<RIDUserData>(null)

  const handleNameFormatting = (inputVal = '') => {
    const capitalize = (str) => (str ? String(str[0]).toUpperCase() + String(str).slice(1) : str)
    const lowercase = (str) => (str ? str.toLowerCase() : str)
    return inputVal.trim() ? capitalize(lowercase(inputVal.trim())) : inputVal
  }

  const getProfileInfoCallback = useCallback(() => {
    try {
      RamblerIDAPI.getProfileInfo(['contacts', 'chain_id'])
        .then(async (data) => {
          // console.log('!!! getProfileInfo data', data)

          const thisPhones = data.info?.phones && data.info.phones.length > 0 ? data.info.phones : []
          const thisEmails =
            data.info?.emails?.confirmed && data.info.emails.confirmed.length > 0 ? data.info.emails.confirmed : []
          const firstName = data.profile?.firstname ? handleNameFormatting(data.profile.firstname) : null
          const lastName = data.profile?.lastname ? handleNameFormatting(data.profile.lastname) : null

          const userDataOutput = {
            phone: handlePhoneFormatting(thisPhones[0], 'set'),
            email: thisEmails[0],
            firstName: firstName,
            lastName: lastName,
          }

          setUserData(userDataOutput)

          return userDataOutput
        })
        .catch((err) => {
          console.error(`RamblerIDAPI catch error: ${err}`)
        })
      // .then(() => this._onChangeCallbacksPool.invoke())
    } catch (error) {
      console.error(`RamblerIDAPI error from useEffect: ${error}`)
    }
  }, [restaurantSource.id])

  useEffect(() => {
    getProfileInfoCallback()
  }, [restaurantSource.id])

  useEffect(() => {
    const newFullNameValue = userData ? `${userData.firstName} ${userData.lastName}`.trim() : null
    if (newFullNameValue) dispatch(setNameFieldValue(newFullNameValue))

    const newPhoneValue = userData ? `${userData.phone}`.trim() : null
    if (newPhoneValue) dispatch(setPhoneFieldValue(newPhoneValue))

    const newEmailValue = userData ? `${userData.email}`.trim() : null
    // if (newEmailValue) dispatch(setEmailFieldValue(newEmailValue)) // TODO email input
  }, [userData])

  return (
    <ErrorBoundary>
      <header className={s.header}>
        <div className={s['title-wrapper']}>
          <Title tag={'h4'} sizes={'h3'} className={s.title}>
            {l10n('form_header_title_booking')}
          </Title>
          <span className={s['rest-name']}>{restaurantSource.name}</span>
        </div>

        <IconButton
          sizes={'L M'}
          mode={'white'}
          icon={IconClose}
          onClick={handleAbortBookingCallback}
          className={s['button-close']}
        />
      </header>

      <main className={s.main}>
        <BookingAlerts />

        <BookingGuests />

        {restaurantSource.schedule && <BookingDateTime />}

        <div className={s.fields}>
          <BookingFullName />

          <BookingPhone />

          <BookingComment />
        </div>

        <Text sizes={'S'} className={s.policy}>
          Нажимая кнопку &laquo;забронировать стол&raquo;, вы&nbsp;принимаете условия{' '}
          <a href={'https://www.afisha.ru/rests/usage/'} target='_blank' className={s['policy-link']}>
            Пользовательского соглашения{' '}
          </a>
          и{' '}
          <a href='https://www.afisha.ru/article/privacy-policy/' target='_blank' className={s['policy-link']}>
            Политики конфиденциальности
          </a>
        </Text>
      </main>

      <footer className={s.footer}>
        {isDesktop && (
          <span>
            {/* 2 guests */}
            {getGuestCountString(guestsCountField.value)}

            {/* 1 January */}
            {dateField.selected?.value && ', ' + dateField.selected.itemTextShort}

            {/* 12:00 */}
            {timeField.selected?.value && ', ' + timeField.selected.value}
          </span>
        )}

        <BookingButton className={s.submit} onClick={handleClickSubmit} disabled={getDisabledStatusButton()}>
          {l10n('form_submit_button_text')}
        </BookingButton>
      </footer>
    </ErrorBoundary>
  )
}

export default BookingScreenForm
