/**
 * utm_medium=sbol - переход из ПДЖ (Проект Сбера)
 * скрывает:
 * плашку куки
 * все баннеры от маркетинга (mindbox script)
 * топ-баннер с рекламой
 */
const UTM_SBOL = 'utm_medium=sbol'

export const getIsActiveUTMSbol = () => {
  if (typeof window === 'undefined') return
  const inQueryParams = typeof window !== 'undefined' && window.location.href.indexOf(UTM_SBOL) >= 0
  const inLocalStorage = window.localStorage.getItem('utm_medium') === 'sbol'

  return inQueryParams || inLocalStorage
}
