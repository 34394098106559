import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchRests, fetchRestGallery, setMapGeolocation } from '@widgets/Restaurant/Map/model/thunks'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import {
  TypeInitialState,
  TypeMapBbox,
  TypeMapCoords,
  TypeMapStateDetail,
  TypeMapStatus,
} from '@widgets/Restaurant/Map/model/types'
import { PhotoList } from '@shared/api/types/swaggerTypes'
import { distanceBetweenLocations } from '@widgets/Restaurant/Map/helpers/getDistanceBetweenCoords'

const initialState: TypeInitialState = {
  active: false,
  filters: null,
  maxRests: 200,
  nearestCityId: null,

  restaurants: {
    status: 'loading',
    data: {},
  },

  restaurantsIdsCity: {
    status: 'loading',
    data: {},
  },

  restaurantsIdsBbox: {
    status: 'loading',
    data: [],
  },

  galleries: {
    status: 'empty',
    data: {},
  },

  selected: {
    status: 'empty',
    data: null,
  },

  geolocation: {
    status: 'empty',
    coords: null,
  },

  map: {
    bbox: null,
    center: null,
    zoom: null,
  },
}

const Map = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setActiveMap(state, action) {
      state.active = action.payload
    },

    setMapData(state, action: PayloadAction<TypeMapStateDetail>) {
      state.map.center = action.payload.center
      state.map.bbox = action.payload.bbox
      state.map.zoom = action.payload.zoom
    },

    // restaurants
    addRestaurantsData(state, action: PayloadAction<TypeMappingRestOutput[]>) {
      action.payload.forEach((rest) => {
        state.restaurants.data[rest.id] = rest
      })
    },
    deleteRestaurantsData(state, action: PayloadAction<number[]>) {
      // todo
    },
    setRestaurantsStatus(state, action: PayloadAction<TypeMapStatus>) {
      state.restaurants.status = action.payload
    },

    // Удаление ресторанов по городам до переданного количества. т.е. В каждом городе может быть не более n ресторанов
    deletePartRestaurants(state, action: PayloadAction<number>) {
      const countDeleteItems = action.payload

      const deletedIds = []

      Object.values(state.restaurantsIdsCity.data).forEach((restsId) => {
        const needToDelete = restsId.length - countDeleteItems
        if (needToDelete) {
          restsId.splice(0, needToDelete).forEach((deletedId) => {
            delete state.restaurants.data[deletedId]
            deletedIds.push(deletedId)
          })
        }
      })

      deletedIds.forEach((delId) => {
        const findIndex = state.restaurantsIdsBbox.data.findIndex((id) => id == delId)
        state.restaurantsIdsBbox.data.splice(findIndex, 1)
      })
    },

    // restaurantsIdsBbox
    setRestaurantsIdsBboxStatus(state, action: PayloadAction<TypeMapStatus>) {
      state.restaurantsIdsBbox.status = action.payload
    },

    // selected
    setSelectedData(state, action: PayloadAction<TypeMappingRestOutput>) {
      state.selected.data = action.payload
    },
    setSelectedStatus(state, action: PayloadAction<TypeMapStatus>) {
      state.selected.status = action.payload
    },

    // galleries
    addGalleriesDataItem(state, action: PayloadAction<{ restId: number; media: PhotoList[] }>) {
      if (!action.payload.restId || !action.payload.media) return
      state.galleries.data[action.payload.restId] = action.payload.media
    },
    deleteGalleriesDataItem(state, action: PayloadAction<{ restId: number; media: PhotoList[] }>) {
      if (!action.payload.restId || !action.payload.media) return
      state.galleries.data[action.payload.restId] = action.payload.media
    },
    setGalleriesStatus(state, action: PayloadAction<TypeMapStatus>) {
      state.selected.status = action.payload
    },

    resetMap() {
      return initialState
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchRests.fulfilled, (state, action) => {
        if (!state.active) return
        const { data, requestsLength } = action.payload
        const { nearestCityId, needToCreateRests, needToDeleteRestsIdx, restaurantsIdsBbox } = data

        needToDeleteRestsIdx.forEach((restId) => {
          try {
            const cityId = state.restaurants.data[restId].address.city.id
            const idsCityIndex = state.restaurantsIdsCity.data[cityId].findIndex((el) => el == restId)
            state.restaurantsIdsCity.data[cityId].splice(idsCityIndex, 1)
            if (!state.restaurantsIdsCity.data[cityId].length) delete state.restaurantsIdsCity.data[cityId]
            delete state.restaurants.data[restId]
          } catch (e) {
            return
          }
        })

        needToCreateRests.forEach((rest) => {
          state.restaurants.data[rest.id] = rest
          const cityId = rest.address.city.id
          if (!state.restaurantsIdsCity.data[cityId]) {
            state.restaurantsIdsCity.data[cityId] = []
          }
          state.restaurantsIdsCity.data[cityId].push(rest.id)
        })

        state.restaurantsIdsBbox.data = restaurantsIdsBbox
        state.nearestCityId = nearestCityId

        if (!requestsLength) {
          state.restaurants.status = Object.keys(state.restaurants.data).length ? 'success' : 'empty'
        }
      })
      .addCase(fetchRests.rejected, (state, action) => {
        state.restaurants.status = 'error'
      })

      .addCase(fetchRestGallery.pending, (state, action) => {
        state.galleries.status = 'loading'
      })
      .addCase(fetchRestGallery.fulfilled, (state, action) => {
        const { restId, ...response } = action.payload

        state.galleries.data[restId] = response.MAPPING.results
        state.galleries.status = 'success'
      })
      .addCase(fetchRestGallery.rejected, (state, action) => {
        state.galleries.status = 'error'
      })

      .addCase(setMapGeolocation.pending, (state, action) => {
        state.geolocation.status = 'loading'
      })
      .addCase(setMapGeolocation.fulfilled, (state, action) => {
        if (!action.payload) {
          state.geolocation.status = 'empty'
          state.geolocation.coords = null
        }

        state.geolocation.status = 'success'
        state.geolocation.coords = action.payload
      })
      .addCase(setMapGeolocation.rejected, (state, action) => {
        state.geolocation.status = 'error'
        state.geolocation.coords = null
      })
  },
})

export const {
  setActiveMap,
  setMapData,
  addRestaurantsData,
  deleteRestaurantsData,
  setRestaurantsStatus,
  setSelectedData,
  deletePartRestaurants,
  setSelectedStatus,
  setRestaurantsIdsBboxStatus,
  addGalleriesDataItem,
  deleteGalleriesDataItem,
  setGalleriesStatus,
  resetMap,
} = Map.actions

export default Map.reducer
