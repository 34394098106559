import { globalStore } from '@app/model/store'
import { loginAC } from '@app/model/actions'

/**
 * @function middlewareAuthorized - TODO описание
 */
export const middlewareAuthorized = (callback?: () => void) => {
  const store = globalStore.getState()

  if (typeof window !== 'undefined') {
    if (!store.global.user.data) {
      loginAC()
    } else {
      callback && callback()
    }
  }
}
