import React, { FC } from 'react'
import * as Sentry from '@sentry/browser'
import { getIsActiveQA } from '@shared/lib/helpers/autoTests/helpers'

const GITLAB_STATUS = process.env.GITLAB_STATUS || null
const IS_PROD =
  GITLAB_STATUS === '0' && typeof window !== 'undefined' && window?.document?.location?.host === 'www.afisha.ru'
const IS_LOCAL = process.env.NODE_ENV === 'development'

/**
 * @class ErrorBoundary
 * @todo transform name to component's services
 * @description данный модуль кроме обработки ошибок
 *    отвечает за отображение data-test аттрибудов во время прогона авто-тестов
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)

    this.state = { hasError: false, isTestRunner: false }

    /**
     * @description className for qa-autotests
     */

    // @ts-ignore
    if (typeof window !== 'undefined' && this?.props?.children?.props?.className) {
      // @ts-ignore
      const classNamesArr = this?.props?.children?.props?.className
        ?.split(' ')
        ?.filter((item) => !!item && item !== 'undefined')
        ?.map((item) => item.split('__')[0])

      // @ts-ignore
      this.state.testIds = classNamesArr.join(' ')

      // TODO
      // if (typeof this?.props?.children?.type === 'object') {
      // } else if (typeof this?.props?.children?.type === 'string') {
      // }
    }

    if (getIsActiveQA()) {
      // @ts-ignore
      this.state.isTestRunner = true
    }
  }

  static getDerivedStateFromError(error) {
    // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    if (typeof Sentry !== 'undefined') {
      // сохраняем информацию об ошибке в службу Sentry
      Sentry.withScope((scope: any) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key])
        })
        Sentry.captureException(error)
      })
    }
  }

  render() {
    /**
     * @description hasError
     * @todo Можно отрендерить запасной UI произвольного вида
     */
    // @ts-ignore
    if (this.state.hasError) {
      return <></>
    }

    /**
     * @description this.props.children
     */
    // @ts-ignore
    return typeof window !== 'undefined' && this.state.isTestRunner ? (
      <>
        {/* @ts-ignore */}
        <div data-test={this.state.testIds}>{this.props.children}</div>
      </>
    ) : (
      // @ts-ignore
      this.props.children
    )
  }
}

export default ErrorBoundary
