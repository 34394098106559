import { createAsyncThunk } from '@reduxjs/toolkit'
import { addNotice, removeNotice } from '@widgets/Common/Notices'
import { TypeNotice, TypePriorityNotice } from '@widgets/Common/Notices/model/types'

type TypePropsAddTemporaryNotice = {
  markup: string
  priority?: TypePriorityNotice
  type?: TypeNotice
  autoHideMS?: number | false // ms or "false"
}

export const addTemporaryNotice = createAsyncThunk(
  'notices/addTemporaryNotice',
  async (data: TypePropsAddTemporaryNotice, { dispatch }) => {
    const id = Math.random().toFixed(4)
    const startTimer = !('autoHideMS' in data) || typeof data.autoHideMS === 'number'
    const delayTimer = typeof data.autoHideMS === 'number' ? data.autoHideMS : 6000
    const priority = data.priority || 1

    dispatch(addNotice({ id, markup: data.markup, type: data.type || 'default', priority: priority }))
    if (startTimer) setTimeout(() => dispatch(removeNotice(id)), delayTimer)
  },
)
