import React, { FC, useMemo, useState } from 'react'
import s from './CreateReviewModal.module.sass'

import { useAppDispatch, useAppSelector } from '@app/model/store'
import { changeComponentCreate } from '@entities/Restaurant/Reviews/model/slice'

import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { FieldBase, FieldTextarea } from '@shared/types/fields/fields'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { LoadingSpinner } from '@shared/ui/Feedback/LoadingSpinner'
import { BuildTextarea } from '@shared/ui/Form/Build/BuildTextarea'
import { middlewareAuthorized } from '@shared/lib/helpers/auth'
import { InputHint } from '@shared/ui/Form/Single/InputHint'
import { IconButton } from '@shared/ui/Actions/IconButton'
import { Title } from '@shared/ui/Typography/Title'
import { Button } from '@shared/ui/Actions/Button'
import { Modal } from '@shared/ui/Overlays/Modal'

import iconThumb from '@icons/icon-thumb.svg'

interface Props {
  rateError: boolean
  handleClickDislike: () => void
  handleClickLike: () => void
  handleSubmit: () => void
  comment: FieldTextarea
  setComment: React.Dispatch<React.SetStateAction<FieldBase>>
}

const CreateReviewModal: FC<Props> = ({
  rateError,
  handleClickDislike,
  handleClickLike,
  handleSubmit,
  comment,
  setComment,
}) => {
  const stateReviews = useAppSelector((state) => state.entities.restaurant_reviews)
  const stateReviewCreate = stateReviews.components.create
  const { isMobile } = useClientResponsive()
  const [isOpenedModal, setIsOpenedModal] = useState(false)
  const dispatch = useAppDispatch()
  const handleOpenModal = () => setIsOpenedModal(true)
  const handleCloseModal = () => {
    setIsOpenedModal(false)
    setComment((prev) => ({ ...prev, value: '' }))
    dispatch(changeComponentCreate({ body: '', is_like: null }))
  }

  const isLike = stateReviewCreate.is_like === true
  const isDislike = stateReviewCreate.is_like === false

  const classRateLike = useMemo(() => {
    return isLike ? `${s['rate-like']} ${s['active']}` : s['rate-like']
  }, [isLike])

  const classRateDislike = useMemo(() => {
    return isDislike ? `${s['rate-dislike']} ${s['active']}` : s['rate-dislike']
  }, [isDislike])

  return (
    <ErrorBoundary>
      <Button
        sizes={'XL'}
        mode={'black'}
        className={s['button-fixed']}
        onClick={() => middlewareAuthorized(handleOpenModal)}>
        Оставить отзыв
      </Button>
      {isOpenedModal && (
        <Modal size={'M'} handleClose={handleCloseModal} title={isMobile && 'Ваш отзыв'} className={s.modal}>
          <div className={s.wrapper} data-loading={stateReviewCreate.loading}>
            <div className={s.header}>
              {!isMobile && (
                <Title tag='h3' sizes='h2 h4'>
                  Оставить отзыв
                </Title>
              )}
              <div className={s['rate-group']}>
                <IconButton
                  icon={iconThumb}
                  mode='white-border'
                  sizes='XL L'
                  active={isDislike}
                  onClick={() => middlewareAuthorized(handleClickDislike)}
                  className={classRateDislike}
                />
                <IconButton
                  icon={iconThumb}
                  mode='white-border'
                  sizes='XL L'
                  active={isLike}
                  onClick={() => middlewareAuthorized(handleClickLike)}
                  className={classRateLike}
                />
                {rateError && (
                  <InputHint
                    status='error'
                    text='Поставьте «Нравится» или «Не нравится»'
                    sizes={'M S'}
                    className={s.error}
                  />
                )}
              </div>
            </div>
            <form className={s.inner}>
              {/*@ts-ignore*/}
              <BuildTextarea field={comment} setField={setComment} className={s.textarea} />
              <Button
                sizes={'XL L'}
                mode={'black'}
                onClick={() => middlewareAuthorized(handleSubmit)}
                className={s.button}>
                Отправить
              </Button>
            </form>
          </div>
          <LoadingSpinner loading={stateReviewCreate.loading} />
        </Modal>
      )}
    </ErrorBoundary>
  )
}

export default CreateReviewModal
