import { useEffect } from 'react'
import { useAppDispatch } from '@app/model/store'
import { setUTMs } from '@app/model/slice'
import { getIsActiveUTMSbol } from '@shared/lib/helpers/UTM/helpers'

export const useUTMhandler = () => {
  const dispatch = useAppDispatch()
  /**
   * utm_medium=sbol
   */
  useEffect(() => {
    const isUTMFromURLOrLocalStorage = getIsActiveUTMSbol()

    isUTMFromURLOrLocalStorage && dispatch(setUTMs([{ key: 'utm_medium', value: 'sbol' }]))
  }, [])
}
