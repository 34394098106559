import { Dispatch } from 'react'
import { AnyAction } from '@reduxjs/toolkit'

import { globalStore } from '@app/model/store'
import {
  resetCityRead,
  resetTopline,
  setCityList,
  setCityRead,
  setCurrentCity,
  setNeedAskCity,
  setStoreLoaded,
  setTopline,
} from '@app/model/slice'

import { COOKIE_CITY_USER, SESSION_STORAGE_CITY_CURRENT } from '@shared/consts/storage'
import getGlobalProps, { TypeGlobalOutput } from '@shared/api/mappingGlobalProps'
import parseCookie from '@shared/lib/helpers/parseCookie'
import { resetStateFilters } from '@features/Restaurant/RestaurantFilters/model/slice'

// Инициализация города
export const initCityAC = (data: TypeGlobalOutput | null) => async (dispatch: Dispatch<AnyAction>) => {
  const globalState = globalStore.getState().global

  const cookieCityUser = parseCookie(COOKIE_CITY_USER)
  const sessionStorageCurrentCity = sessionStorage.getItem(SESSION_STORAGE_CITY_CURRENT)

  if (!cookieCityUser && !sessionStorageCurrentCity) dispatch(setNeedAskCity(true))

  // При определении города в первую очередь ориентируемся на slug города в url (если он есть/если есть объект data)
  // При его отсутствии - на куки и localStorage
  // При их отсутствии или истечения срока жизни устанавливаем дефолтное значение msk
  if (data) {
    dispatch(setCurrentCity(data.this_city))
    dispatch(setCityRead(data.city_read))
    dispatch(setTopline(data.topline))
    dispatch(setCityList(data.city_list))
    dispatch(setStoreLoaded(!!data))
  }
  // data может быть null т.к. некоторые страницы не имеют slug через который определяется город (/.../msk/.../)
  else {
    if (globalState.currentCity?.slug) return

    const citySlug = cookieCityUser ? cookieCityUser.split('-')[1] : 'msk'

    getGlobalProps({ city_slug: citySlug, current_page: null }).then((res) => {
      dispatch(setCurrentCity(res.this_city))
      dispatch(setCityRead(res.city_read))
      dispatch(setTopline(res.topline))
      dispatch(setCityList(res.city_list))
      dispatch(setStoreLoaded(!!res))
    })
  }
}

// При выборе города в попапе "Ваш город <название города>?" сохраняем данные в куки и не показываем попап 2 месяца
export const setConfirmUserCity = (city: { id: number; slug: string }) => (dispatch: Dispatch<AnyAction>) => {
  if (!city) return

  // 2 месяца от текущей даты
  let date: Date | string = new Date(Date.now())
  date.setDate(date.getDate() + 60)
  date = date.toUTCString()
  document.cookie = `${COOKIE_CITY_USER}=${city.id}-${city.slug}; expires=${date}; domain=.afisha.ru; path=/`

  dispatch(setNeedAskCity(false))
}

// При нажатии на крестик попапа "Ваш город <название города>?" больше не показываем данный попап пару дней, чтоб не бесить пользователя
export const setCancelUserCity = () => (dispatch: Dispatch<AnyAction>) => {
  const globalState = globalStore.getState().global
  const cityFromStore = globalState.currentCity

  // 3 дня от текущей даты
  let date: Date | string = new Date(Date.now())
  date.setDate(date.getDate() + 3)
  date = date.toUTCString()
  document.cookie = `${COOKIE_CITY_USER}=${cityFromStore.id}-${cityFromStore.slug}; expires=${date}; domain=.afisha.ru; path=/`

  dispatch(setNeedAskCity(false))
}

export const changeCityAC = () => (dispatch: Dispatch<AnyAction>) => {
  // @ts-ignore
  dispatch(resetCityRead())
  // @ts-ignore
  dispatch(resetTopline())

  dispatch(resetStateFilters())
}
