import s from './Map.module.sass'
import { MapRestCarouselMobile } from './ui/MapRestCarouselMobile'

import React, { FC, useContext, useEffect, useState, useMemo } from 'react'
import { load } from '@2gis/mapgl'
import { useRouter } from 'next/router'

import { useAppDispatch, useAppSelector } from '@app/model/store'

import { closeMapAC, updateMapCenter } from '@widgets/Restaurant/Map/model/actions'
import { Header } from '@widgets/Common/Header'
import { MapButtonClose } from '@widgets/Restaurant/Map/ui/MapButtonClose'
import { MapContext } from '@widgets/Restaurant/Map/MapContext'
import { MapRestCard } from '@widgets/Restaurant/Map/ui/MapRestCard'
import { MapRestGrid } from '@widgets/Restaurant/Map/ui/MapRestGrid'
import { MapScaleControls } from '@widgets/Restaurant/Map/ui/MapScaleControls'
import { useBlockScrollOverlay } from '@shared/lib/hooks/useBlockScrollOverlay'
import { useHandleMarkers } from '@widgets/Restaurant/Map/hooks/useHandleMarkers'
import { useMapTitle } from '@widgets/Restaurant/Map/hooks/useMapTitle'

import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { Title } from '@shared/ui/Typography/Title'
import LoadingSpinner from '../../../6_shared/ui/Feedback/LoadingSpinner/LoadingSpinner'
import { MapSelectors } from '.'
import { EGG } from '@shared/api/analytics'
import { MapUserLocation } from '@widgets/Restaurant/Map/ui/MapUserLocation'
import { setMapGeolocation } from '@widgets/Restaurant/Map/model/thunks'

const MapContent: FC = () => {
  const cityCoordinates = useAppSelector((store) => store.global.cityRead.coordinates)
  const { isMobile } = useClientResponsive()
  const { active, selected } = useAppSelector(MapSelectors.all)
  const { data: restsData, status: restsStatus } = useAppSelector(MapSelectors.restaurants)
  const [mapInstance, setMapInstance] = useContext(MapContext)
  const [mapLoaded, setMapLoaded] = useState(false)
  const dispatch = useAppDispatch()
  const router = useRouter()

  useHandleMarkers(mapInstance)

  useBlockScrollOverlay()

  const handleClose = () => {
    EGG.pages.Map.click_close()
    dispatch(closeMapAC())
  }

  // Создание карты
  useEffect(() => {
    try {
      if (!active) return
      load().then((mapglAPI) => {
        if (!document.getElementById('global-map')) return
        const map = new mapglAPI.Map('global-map', {
          lang: 'ru',
          controlsLayoutPadding: { top: 20, bottom: 20, left: 16, right: 16 },
          center: cityCoordinates ? [cityCoordinates[1], cityCoordinates[0]] : [37.6156, 55.7522],
          zoom: 13,
          maxZoom: 19,
          minZoom: 7,
          zoomControl: false,
          key: '9c84424f-237a-4c66-bc7e-26bc4f49e606',
          style: '4eda4784-9adb-4c0f-8380-367cad53599c',
          enableTrackResize: true,
          copyright: 'bottomRight',
          trafficControl: false,
        })

        setMapInstance(() => ({ mapglAPI, mapCreated: map }))

        dispatch(setMapGeolocation({ mapInstance: { mapglAPI, mapCreated: map }, eventType: 'init' }))

        const sendUpdateMapCenter = () => {
          const { northEast, southWest } = map.getBounds()

          // @ts-ignore
          dispatch(updateMapCenter({ bbox: { northEast, southWest }, center: map.getCenter(), zoom: map.getZoom() }))
        }

        map.on('centerend', sendUpdateMapCenter)
        map.on('zoomend', sendUpdateMapCenter)

        sendUpdateMapCenter()

        setMapLoaded(true)
      })
    } catch (error) {
      setMapLoaded(true)
      console.error('Ошибка инициализации карты', error)
    }
    return () => {
      mapInstance?.mapCreated?.destroy()
      setMapInstance(() => ({ mapglAPI: null, mapCreated: null }))
    }
  }, [active])

  const isShowHeader = !isMobile && router.pathname !== '/[city_slug]/restaurants/restorator'
  const isShowRestItem = selected.data !== null
  const isShowRestGrid = !isShowRestItem

  const mapTitle = useMapTitle()

  return (
    <div className={s.container} data-map-loaded={mapLoaded ? 'true' : 'false'}>
      {isShowHeader && (
        <div className={s['header-wrapper']}>
          <Header />
        </div>
      )}

      {isMobile ? (
        <div className={s['content-mobile']} style={{ height: window.innerHeight + 'px' }}>
          <div id='global-map' className={s.map}></div>
          <div className={s.header}>
            <Title className={s.title} sizes={'h4'}>
              {mapTitle}
            </Title>
            <MapButtonClose onClick={handleClose} className={s['button-close']} />
          </div>

          <div className={s.footer}>
            <div className={s['footer-buttons']}>
              <div className={s['footer-button-location']}>
                <MapUserLocation />
              </div>
              {/*<Button sizes={'S'} mode={'white'}>*/}
              {/*  Пример кнопки*/}
              {/*</Button>*/}
            </div>
            <MapRestCarouselMobile />
          </div>
        </div>
      ) : (
        <div className={s['content-desktop']}>
          <div id='global-map' className={s.map}></div>

          <div className={s.aside}>
            {isShowRestGrid && <MapRestGrid />}
            {isShowRestItem && <MapRestCard />}
          </div>

          <div className={s.controls}>
            <MapButtonClose onClick={handleClose} className={s['button-close']} />
            <LoadingSpinner loading={restsStatus === 'loading'} size={20} className={s.loading} />
            <div className={s['zoom-and-location']}>
              <MapScaleControls />
              <MapUserLocation />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default MapContent
