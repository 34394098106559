import { globalStore, useAppDispatch, useAppSelector } from '@app/model/store'
import { useEffect } from 'react'
// import Authorization from '@app/service/authorization'
// import { initAuthAC } from '@app/model/createActions'
import { initCityAC } from '@app/model/actionCreatorCity'
import { TypeGlobalOutput } from '@shared/api/mappingGlobalProps'
import { setCurrentPage } from '@app/model/slice'
import { initPlugState } from '@widgets/Common/PlugAds/model/actions'
import { resetState } from '@widgets/Common/PlugAds/model/slice'
import { addTemporaryNotice } from '@widgets/Common/Notices'
import { getIsActiveQA } from '@shared/lib/helpers/autoTests/helpers'
import { getIsActiveUTMSbol } from '@shared/lib/helpers/UTM/helpers'

interface Props {
  data?: TypeGlobalOutput
  page: string
}

/**
 * @function useGlobalData
 * @description Обработчик глобальных данных
 * @param data
 * @param page
 */
export const useGlobalData = ({ data, page }: Props) => {
  const globalState = useAppSelector((state) => state.global)
  const dispatch = useAppDispatch()

  /**
   * @description Получение и запись в стор данных о пользователе (если он авторизирован), если ранее данных не было
   */
  // useEffect(() => {
  //   if (!Authorization.isInit) dispatch(initAuthAC())
  // }, [globalState.user, globalState.cityList])

  /**
   * @description Установка данных по выбранному городу
   */
  useEffect(() => {
    dispatch(initCityAC(data))
  }, [data, page])

  /**
   * @description
   */
  useEffect(() => {
    dispatch(setCurrentPage(page))
  }, [page])

  /**
   * @description
   */
  useEffect(() => {
    dispatch(initPlugState())
    return () => {
      dispatch(resetState)
    }
  }, [globalState.currentPage, globalState.cityRead?.id])

  /**
   * @description Показ окна куки
   */
  useEffect(() => {
    const listNotice = globalStore.getState().notices.notices
    const cookieNotice = listNotice.find((notice) => notice.type === 'cookies')
    const isTestRunner = typeof window !== 'undefined' && getIsActiveQA()
    const showCookieConditions =
      !localStorage.getItem('cookie-apply') && !cookieNotice && !isTestRunner && !getIsActiveUTMSbol()

    if (showCookieConditions) {
      setTimeout(
        () =>
          dispatch(
            addTemporaryNotice({
              type: 'cookies',
              autoHideMS: false,
              priority: 5,
              markup: `На сайте используются cookies. Продолжая использовать сайт,</br>вы принимаете <a href="https://www.afisha.ru/article/usloviya-ispolzovaniya/" target="_blank">условия</a>`,
            }),
          ),
        3000,
      )
    }
  }, [])
}
