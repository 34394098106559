import React, { FC } from 'react'
import Script from 'next/script'
import { getIsActiveQA } from '@shared/lib/helpers/autoTests/helpers'
import { getIsActiveUTMSbol } from '@shared/lib/helpers/UTM/helpers'

const Mindbox: FC = () => {
  const IS_PROD = typeof window !== 'undefined' && window.document.location.host === 'www.afisha.ru'
  const IS_QA_TESTS = getIsActiveQA()
  const IS_SBOL_USER = getIsActiveUTMSbol()

  const isLoadMindbox = IS_PROD && !IS_QA_TESTS && !IS_SBOL_USER

  return (
    <>
      {isLoadMindbox && (
        <Script id='show-banner'>
          {`
            mindbox = window.mindbox || function() { mindbox.queue.push(arguments); };
            mindbox.queue = mindbox.queue || [];
            mindbox('create', {endpointId: 'Afisha-Restaurants'});
          `}
        </Script>
      )}

      {isLoadMindbox && <Script src={'https://api.mindbox.ru/scripts/v1/tracker.js'} strategy={'lazyOnload'} />}
    </>
  )
}

export default Mindbox
