import React, { FC, useContext, useState } from 'react'
import s from './MapUserLocation.module.sass'
import { IconButton } from '@shared/ui/Actions/IconButton'
import IconMapUserLocation from '@icons/map/user-location.svg'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { LoadingSpinner } from '@shared/ui/Feedback/LoadingSpinner'
import { EGG } from '@shared/api/analytics'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { MapContext } from '@widgets/Restaurant/Map/MapContext'
import { MapSelectors } from '../..'
import { setMapGeolocation } from '@widgets/Restaurant/Map/model/thunks'

interface Props {
  disabled?: boolean
  className?: string
}

const MapUserLocation: FC<Props> = ({ disabled, className = '' }) => {
  const dispatch = useAppDispatch()
  const { status } = useAppSelector(MapSelectors.geolocation)
  const [mapInstance] = useContext(MapContext)

  const isLoading = status === 'loading'

  const handleClickInner = () => {
    dispatch(setMapGeolocation({ mapInstance: mapInstance, eventType: 'click' }))
  }

  return (
    <ErrorBoundary>
      <div className={s.wrapper}>
        <IconButton
          id={s.button}
          mode={'white'}
          sizes={'XL M'}
          icon={IconMapUserLocation}
          disabled={disabled}
          className={className}
          data-loading={isLoading}
          onClick={handleClickInner}
        />
        <LoadingSpinner loading={isLoading} size={12} className={s.spinner} />
      </div>
    </ErrorBoundary>
  )
}

export default MapUserLocation
