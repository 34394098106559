import React, { memo, useContext, useEffect, useMemo, useRef, useState } from 'react'
import s from './MapRestCarouselMobile.module.sass'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import {
  MapRestCarouselMobileItem,
  MapRestCarouselMobileItemSkeleton,
} from '@widgets/Restaurant/Map/ui/MapRestCarouselMobile/ui/MapRestCarouselMobileItem'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { MapContext } from '@widgets/Restaurant/Map/MapContext'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { setRestSelectedAC } from '@widgets/Restaurant/Map/model/actions'
import { customSlideToLoop } from '@widgets/Restaurant/Map/ui/MapRestCarouselMobile/helpers/customSlideToLoop'
import { MapRestCarouselMobileNotFound } from '@widgets/Restaurant/Map/ui/MapRestCarouselMobile/ui/MapRestCarouselMobileNotFound'

const MapRestCarouselMobile: React.FC = () => {
  const { restaurants, restaurantsIdsBbox, selected, map } = useAppSelector((store) => store.features.map)
  const dispatch = useAppDispatch()
  const [mapInstance, setMapInstance] = useContext(MapContext)
  const swiperRef = useRef(null)

  const restsIdsArray = useMemo(() => {
    if (!swiperRef.current || !selected.data) return restaurantsIdsBbox.data

    // Если выбранный ресторан пропадает из области видимости карты, то сбрасываем в store ранее выбранный рестик
    if (!restaurantsIdsBbox.data.includes(selected.data.id)) {
      dispatch(setRestSelectedAC({ restData: null, mapInstance, eventFrom: 'slider' }))
      return restaurantsIdsBbox.data
    }

    // Если выбранный ресторан находится в области видимости,
    // то находим индекс слайда выбранного ресторана и перемещаем слайдер на него
    const indexSelectedRestSlide = swiperRef.current.slides.findIndex((el) => el.dataset.restId == selected.data.id)
    const indexCurrentSlide = swiperRef.current.realIndex
    const indexInArray = restaurantsIdsBbox.data.findIndex((el) => el == selected.data.id)
    if (indexSelectedRestSlide == indexCurrentSlide && indexCurrentSlide === indexInArray) {
      return restaurantsIdsBbox.data
    } else {
      const results = [...restaurantsIdsBbox.data]
      const moveFirstElem = results[indexInArray]
      const moveSecondElem = results[indexCurrentSlide]

      results[indexCurrentSlide] = moveFirstElem
      results[indexInArray] = moveSecondElem
      return results
    }
  }, [restaurantsIdsBbox.data, map.center])

  const getRestIdCurrentSlide = (swiper) => {
    try {
      return swiper.slides[swiper.activeIndex].dataset.restId
    } catch (e) {
      return 0
    }
  }

  const handleChangeSlide = (swiper) => {
    const restIdNewSlide = getRestIdCurrentSlide(swiper)
    if (selected.data?.id === restIdNewSlide || !restsIdsArray.includes(Number(restIdNewSlide))) return

    dispatch(setRestSelectedAC({ restData: restaurants.data[restIdNewSlide], eventFrom: 'slider', mapInstance }))
  }

  useEffect(() => {
    if (!swiperRef.current || !selected.data) return
    const selectedIndex = restsIdsArray.findIndex((el) => el == selected.data.id)
    if (swiperRef.current.realIndex === selectedIndex) return

    customSlideToLoop(swiperRef.current, selectedIndex)
  }, [selected.data])

  const isShowSkeleton = restaurantsIdsBbox.status === 'loading'
  const isShowNotFound = restaurantsIdsBbox.status === 'empty'

  return (
    <ErrorBoundary>
      <Swiper
        className={s.slider}
        speed={800}
        slidesPerView={1}
        loop={true}
        initialSlide={0}
        autoHeight={true}
        spaceBetween={8}
        onTransitionEnd={handleChangeSlide}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        data-loading={isShowSkeleton}>
        {!!restsIdsArray.length &&
          restsIdsArray.map((restId, index) => {
            const rest = restaurants.data[restId]
            if (!rest) return // TODO: периодически появляется кейс, исправить
            return (
              <SwiperSlide key={`${index} ${rest.id}`} data-rest-id={rest.id}>
                <MapRestCarouselMobileItem data={rest} />
              </SwiperSlide>
            )
          })}
      </Swiper>

      <div className={s.skeleton} data-show={isShowSkeleton}>
        <MapRestCarouselMobileItemSkeleton />
        <MapRestCarouselMobileItemSkeleton />
        <MapRestCarouselMobileItemSkeleton />
      </div>

      <div className={s['not-found']} data-show={isShowNotFound}>
        <MapRestCarouselMobileItemSkeleton />
        <MapRestCarouselMobileNotFound />
        <MapRestCarouselMobileItemSkeleton />
      </div>
    </ErrorBoundary>
  )
}

export default memo(MapRestCarouselMobile)
