import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '@shared/api'
import { addTemporaryNotice } from '@widgets/Common/Notices'
import { setLoading, setPaginationPage, setSelectionList } from '@pages/SelectionItem/model/slice'

export const loadMorePage = createAsyncThunk('selectionItem/loadMorePage', async (action, { dispatch, getState }) => {
  // @ts-ignore
  const stateGlobal = getState().global
  // @ts-ignore
  const state = getState().pages.selection_item.selection

  const { items_addition, items_full } = state.loading
  if (items_addition || items_full || !state.pagination.page.next.number) return

  dispatch(setLoading({ ...state.loading, items_addition: true }))

  const params = {
    ...state.paramsRequest,
    params: {
      ...state.paramsRequest.params,
      page: state.pagination.page.next.number,
    },
  }

  API.selection_restaurants(params)
    .then((response) => {
      dispatch(setSelectionList([...state.restaurants, ...response.results]))
      dispatch(setPaginationPage(response.pagination))
    })
    .catch((error) => {
      console.error(`'Ошибка загрузки списка ресторанов' - ${error}`)

      dispatch(addTemporaryNotice({ markup: 'Ошибка загрузки списка ресторанов' }))
    })
    .finally(() => {
      dispatch(setLoading({ ...state.loading, items_addition: false }))
    })
})

export const loadPage = createAsyncThunk('selectionItem/loadPage', async (pageNumber, { dispatch, getState }) => {
  // @ts-ignore
  const stateGlobal = getState().global
  // @ts-ignore
  const state = getState().pages.selection_item.selection

  const { items_addition, items_full } = state.loading
  if (items_addition || items_full) return

  dispatch(setLoading({ ...state.loading, items_full: true }))

  const params = {
    ...state.paramsRequest,
    params: {
      ...state.paramsRequest.params,
      page: pageNumber,
    },
  }

  API.selection_restaurants(params)
    .then((response) => {
      dispatch(setSelectionList(response.results))
      dispatch(setPaginationPage(response.pagination))
    })
    .catch((error) => {
      console.error(`'Ошибка загрузки списка ресторанов' - ${error}`)
      dispatch(addTemporaryNotice({ markup: 'Ошибка загрузки списка ресторанов' }))
    })
    .finally(() => {
      dispatch(setLoading({ ...state.loading, items_full: false }))
    })
})
