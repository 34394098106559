/**
 * @description Реклама, ADF, Capirs
 */

import React, { memo } from 'react'
import { useEffectOnce } from '@shared/lib/hooks/useEffectOnce'
import { getIsActiveUTMSbol } from '@shared/lib/helpers/UTM/helpers'

const IS_PROD = process.env.GITLAB_STATUS === '0'
const SHOW_ANY_ADV = process.env.SHOW_ANY_ADV

const LINK_CAPIRS_ASYNC = 'https://ssp.rambler.ru/capirs_async.js'

/**
 * @constant scriptData
 * @description описывает функцию для создания скрипта и события onload
 */
const scriptData = `
  (function (_window, _document, _script, _src) {
    var n = _document.getElementsByTagName(_script)[0];
    var s = _document.createElement(_script);
    s.type = "text/javascript";
    s.async = true;
    s.src = _src;
    s.onload = function () {
      if (typeof _window.onCapirsAsyncLoad === 'function') {
        _window.onCapirsAsyncLoad();
      }
    };
    n.parentNode.insertBefore(s, n);
  })(window, document, "script", "${LINK_CAPIRS_ASYNC}");
`

/**
 * @component CapirsAsyncScript
 * @description добавляет в DOM скрипт capirs_async.js с обработкой события загрузки
 */
const CapirsAsyncScript = () => {
  useEffectOnce(() => {
    const addCapirsAsyncScript = () => {
      return new Promise((resolve) => {
        // глобальный колбэк, который позволяет узнать о загрузке
        window.onCapirsAsyncLoad = () => {
          console.info('Capirs async script loaded from callback onCapirsAsyncLoad')
          resolve()
        }

        const scriptTag = document.createElement('script')
        scriptTag.type = 'text/javascript'
        scriptTag.text = scriptData

        // создаёт <script src="capirs_async.js"> и вешает на него onload
        document.body.appendChild(scriptTag)
      })
    }

    // ***** запуск *****
    setTimeout(() => {
      const launchConditions = IS_PROD && SHOW_ANY_ADV && !getIsActiveUTMSbol()

      if (launchConditions) {
        addCapirsAsyncScript().then(() => {
          console.info('Init "capirs async" script success!')
        })
      }
    }, 1)
  }, [])

  return <span className='visually-hidden' />
}

export default memo(CapirsAsyncScript)
