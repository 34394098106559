import React, { FC, useEffect, ReactNode } from 'react'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { initReviews } from '@entities/Restaurant/Reviews/model/actions'
import { EditReview } from '@entities/Restaurant/Reviews/ui/EditReview'
import { DeleteReview } from '@entities/Restaurant/Reviews/ui/DeleteReview'
import { FiltersReview } from '@entities/Restaurant/Reviews/ui/FiltersReview'
import { ReviewItem } from '@entities/Restaurant/Reviews/ui/ReviewItem'
import { ReviewPagination } from '@entities/Restaurant/Reviews/ui/ReviewPagination'
import { CreateReview } from '@entities/Restaurant/Reviews/ui/CreateReview'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { resetStateReviews } from '@entities/Restaurant/Reviews/model/slice'

type Props = {
  restaurant: {
    name: string
    id: number
  }
  reviewsOptions?: {
    pageSize: number
  }
  children: ReactNode
}

type Components = {
  Item: typeof ReviewItem
  Create: typeof CreateReview
  Filters: typeof FiltersReview
  Pagination: typeof ReviewPagination
}

const Reviews: FC<Props> & Components = ({ restaurant, reviewsOptions = { pageSize: 10 }, children }) => {
  const dispatch = useAppDispatch()
  const stateGlobal = useAppSelector((state) => state.global)
  const stateReviews = useAppSelector((state) => state.entities.restaurant_reviews)
  const { create: componentCreate, edit: componentEdit, delete: componentDelete } = stateReviews.components

  useEffect(() => {
    dispatch(initReviews(restaurant, reviewsOptions))
    return () => {
      dispatch(resetStateReviews())
    }
  }, [stateGlobal.user.data, restaurant.id])

  return (
    <ErrorBoundary>
      <>{children}</>
      <EditReview />
      <DeleteReview />
    </ErrorBoundary>
  )
}

export default Reviews

Reviews.Item = ReviewItem
Reviews.Create = CreateReview
Reviews.Filters = FiltersReview
Reviews.Pagination = ReviewPagination
