import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '@shared/api'
import { TypeMappingRestOutput } from '@shared/api/middleware/mappingAPI/restaurant/type'
import { globalStore } from '@app/model/store'
import { setRestaurantsIdsBboxStatus, setRestaurantsStatus } from '@widgets/Restaurant/Map/model/slice'
import { TypeMapContextState } from '@widgets/Restaurant/Map/MapContext'
import { EGG } from '@shared/api/analytics'

// TODO: Вынести глобально
class ControllerSignal {
  private maxSignals: number
  // ссылки на new AbortController() текущего запроса для возможности прерывания.
  signalStack = []

  constructor(maxSignals = 2) {
    this.maxSignals = maxSignals
  }

  getSignal = () => {
    const controller = new AbortController()
    // Если количество активных запросов превышает лимит, то останавливаем их и отправляем новый

    if (this.signalStack.length >= this.maxSignals) {
      this.abortSignal()
      this.delSignal()
    }
    // добавляем новый в стек
    this.signalStack.push(controller)
    return controller.signal
  }
  delSignal = () => {
    if (this.signalStack.length) this.signalStack.shift()
  }
  abortSignal = () => {
    if (this.signalStack.length) this.signalStack[this.signalStack.length - 1]?.abort()
  }
  getSignals = () => {
    return this.signalStack
  }
}

const ControllerSignalMap = new ControllerSignal(2)

const pageSize = (zoom) => {
  if (zoom < 9) return 5
  else if (zoom < 10) return 10
  else if (zoom < 11) return 15
  else return 25
}

const maxPage = (zoom) => {
  if (zoom >= 15.5) return 100
  else if (zoom >= 15) return 10
  else if (zoom >= 14) return 5
  else if (zoom >= 12) return 3
  else if (zoom >= 10) return 2
  else return 1
}

let timeout = null
export const fetchRests = createAsyncThunk(
  'map/fetchRests',
  async (
    page: number,
    { dispatch },
  ): Promise<{
    data: {
      nearestCityId: number
      needToCreateRests: TypeMappingRestOutput[]
      needToDeleteRestsIdx: number[]
      restaurantsIdsBbox: number[]
    }
    requestsLength: number
  }> => {
    const storeMap = globalStore.getState().features.map
    const { center, bbox, zoom } = storeMap.map
    const centerBeforeResponse = [...center]
    const timeBeforeRequest = Date.now()
    if (!page) clearTimeout(timeout)

    dispatch(setRestaurantsStatus('loading'))

    const response = await API.restaurant_list({
      params: {
        bbox: `${bbox.southWest.join(',')},${bbox.northEast.join(',')}`,
        page: page || 1,
        page_size: pageSize(zoom),
      },
      config: { signal: ControllerSignalMap.getSignal() },
    })
      .then((res) => {
        // Логика отправки запросов для получения след. пачки данных
        // - истек минимальный временной интервал между запросами (debounce)
        // - за пределами области просмотра есть рестораны, которые можно будет если что удалить (new Worker)
        // - центр карты до запроса совпадает с центром после получения данных

        const storeMap = globalStore.getState().features.map
        if (!storeMap.active) return

        const nextPageNumber = res.pagination.page.next.number
        const centersSame = centerBeforeResponse.every((coords, idx) => coords === storeMap.map.center[idx])

        if (centersSame && nextPageNumber && nextPageNumber <= maxPage(zoom)) {
          const worker = new Worker(new URL('../workers/webWorker.ts', import.meta.url), { type: 'module' })
          worker.postMessage({
            type: 'check-need-load-page',
            restaurants: storeMap.restaurants.data,
            bbox: storeMap.map.bbox,
            maxRests: storeMap.maxRests,
          })

          worker.onmessage = (e) => {
            if (e.data) dispatch(fetchRests(nextPageNumber))
            worker.terminate()
          }
        }
        return res
      })
      .finally(() => ControllerSignalMap.delSignal())

    return new Promise((resolve) => {
      const store = globalStore.getState()
      const storeMap = store.features.map
      const worker = new Worker(new URL('../workers/webWorker.ts', import.meta.url), { type: 'module' })
      worker.postMessage({
        type: 'handle-response-rests',
        restaurants: {
          old: storeMap.restaurants.data,
          new: response.results,
          oldIdsBbox: storeMap.restaurantsIdsBbox.data,
        },

        mapCenter: center,
        mapBbox: storeMap.map.bbox,
        maxRests: storeMap.maxRests,
      })

      worker.onmessage = (e) => {
        const requestsLength = ControllerSignalMap.getSignals().length
        const timeAfterRequest = Date.now()
        const timeDiff = timeAfterRequest - timeBeforeRequest

        resolve({ data: e.data, requestsLength })

        if (!page) {
          clearTimeout(timeout)
          timeout = setTimeout(
            () => {
              dispatch(setRestaurantsIdsBboxStatus(e.data.restaurantsIdsBbox.length ? 'success' : 'empty'))
            },
            timeDiff > 2000 ? 0 : 2000 - timeDiff,
          )
        }

        worker.terminate()
      }
    })
  },
)

type TypeSetMapGeolocation = {
  mapInstance: TypeMapContextState
  eventType: 'init' | 'click'
}
export const setMapGeolocation = createAsyncThunk(
  'map/setMapGeolocation',
  async (data: TypeSetMapGeolocation, { dispatch, getState }): Promise<[number, number]> =>
    new Promise((resolve, reject) => {
      if (data.eventType === 'init') {
        if (!navigator.permissions) resolve(null)

        navigator.permissions
          .query({ name: 'geolocation' })
          .then((permission) =>
            permission.state === 'granted'
              ? navigator.geolocation.getCurrentPosition((position) =>
                  resolve([position.coords.longitude, position.coords.latitude]),
                )
              : resolve(null),
          )
      }

      if (data.eventType === 'click') {
        EGG.pages.Map.click_geolocation()
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const coords = [position.coords.longitude, position.coords.latitude]
            data.mapInstance.mapCreated.setCenter(coords)
            data.mapInstance.mapCreated.setZoom(13)
            resolve([position.coords.longitude, position.coords.latitude])
          },
          () => {
            resolve(null)
          },
        )
      }
    }),
)

export const fetchRestsBySlugs = createAsyncThunk(
  'map/fetchRestsBySlugs',
  async (action: string[], { dispatch, getState }) => {
    return Promise.all(action.map((slug) => API.restaurant_read({ path: slug })))
  },
)

export const fetchRestGallery = createAsyncThunk('map/fetchRestGallery', async (id: number, { dispatch, getState }) => {
  return API.photo_list({ params: { page_size: 50, restaurant: Number(id) } }).then((res) => ({ ...res, restId: id }))
})
