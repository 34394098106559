import React, { FC, FocusEvent, MouseEvent, useCallback, useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@app/model/store'

import { BookingInput } from '@widgets/Restaurant/Booking/ui/components/BookingInput'
import { handlePhoneFormatting } from '@widgets/Restaurant/Booking/lib/booking-phone-formatting'
import { setPhoneFieldValue } from '@widgets/Restaurant/Booking/model/slice'
import l10n from '@widgets/Restaurant/Booking/lib/booking-localization'

import { EGG } from '@shared/api/analytics'
import { ErrorBoundary } from '@shared/lib/components/ErrorBoundary'
import { checkDiscountByNumber } from '@widgets/Restaurant/Booking/model/actions'

const DEFAULT_PHONE_VALUE = '+7 '

/**
 * @function BookingPhone
 * @description
 */
const BookingPhone: FC = () => {
  const dispatch = useAppDispatch()
  const { restaurantSource, form } = useAppSelector((store) => store.booking)
  const { isErrorsVisible, phoneField } = form

  /**
   * @param inputData
   */
  const checkDiscountHandler = (inputData: { phone: string } = null) => {
    if (phoneField.value?.length > 7) {
      const thisPhone = !inputData ? phoneField.value : inputData.phone
      dispatch(checkDiscountByNumber({ phone: thisPhone, restId: restaurantSource.id }))
    }
  }

  // Первая проверка при рендере
  useEffect(() => {
    checkDiscountHandler()
  }, [])

  const handlePhoneFocus = () => {
    EGG.entity.booking.click_reserve_field_phone()
  }

  const handlePhoneTypingCallback = useCallback(
    (enteredPhoneString: string) => {
      const isPhoneRemoving = enteredPhoneString.length < phoneField.value.length
      const thisPhoneNumber = handlePhoneFormatting(enteredPhoneString, isPhoneRemoving ? 'remove' : 'set')

      dispatch(setPhoneFieldValue(thisPhoneNumber))

      if (enteredPhoneString.length === 4) {
        EGG.entity.booking.input_reserve_field_phone()
      }
    },
    [dispatch, phoneField.value.length, restaurantSource.id, restaurantSource.name],
  )

  const handlePhoneBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
    const value = event.target.value
    dispatch(setPhoneFieldValue(value))
    dispatch(checkDiscountByNumber({ phone: value, restId: restaurantSource.id }))
  }

  const setDefaultPhoneValue = (event: MouseEvent<HTMLTextAreaElement> & { target: HTMLTextAreaElement }) => {
    const value = event.target.value
    if (value === '') {
      dispatch(setPhoneFieldValue(DEFAULT_PHONE_VALUE))
    }
  }

  return (
    <ErrorBoundary>
      <BookingInput
        field={phoneField}
        placeholder={l10n('guest_phone_placeholder')}
        isErrorsVisible={isErrorsVisible}
        maxLength={23}
        onClick={setDefaultPhoneValue}
        onFocus={handlePhoneFocus}
        onChange={(e) => {
          // @ts-ignore
          handlePhoneTypingCallback(e.target.value)
        }}
        onBlur={handlePhoneBlur}
      />
    </ErrorBoundary>
  )
}

export default BookingPhone
