import { createAsyncThunk } from '@reduxjs/toolkit'

import { addTemporaryNotice } from '@widgets/Common/Notices'

import { API } from '@shared/api'

import {
  setMainData,
  setDescriptionData,
  setPublishedPost,
  setPreviousPosts,
  setBackendSource,
  setLoadingForm,
  closeForm,
} from '@pages/OwnerPersonalPage/model/OwnerPostsModel/slice'
import { TypeCommonPost, TypePostCategory } from '@pages/OwnerPersonalPage/model/OwnerPostsModel/types'
import { getFormattedPosts, getPostsByCategories } from '@pages/OwnerPersonalPage/model/OwnerPostsModel/helpers'

/**
 * @function loadRestWithPosts - загрузка данных о ресторане, вместе с матеръялами
 */
export const loadRestWithPosts = createAsyncThunk(
  'OwnerPosts/loadRestWithPosts',
  async (props, { dispatch, getState }) => {
    // @ts-ignore
    const state = getState().pages.owner_personal.posts
    const params = { rid: state.restaurant.id, ...state.paramsRequest }

    await API.r_read(params)
      .then((response) => {
        const { news, special, menu, can_published, description } = response

        dispatch(setBackendSource(response))

        // 1. Приводим объявления к виду который ожидает action
        const formattedPosts = getFormattedPosts([news, special, menu])

        // 2. Определяем какие посты опубликованы, а какие нет
        const { publicPosts, oldPosts } = getPostsByCategories(formattedPosts)

        // 3. Опубликованные объ. закидываем в published а остальные в previous
        if (publicPosts?.length) {
          dispatch(setPublishedPost(publicPosts[0]))
        }
        if (oldPosts?.length) {
          dispatch(setPreviousPosts(oldPosts))
        }

        // Выводим статус публикации для ресторана на главной
        typeof can_published === 'boolean' && dispatch(setMainData(can_published))

        // Выводим описание на Карточке ресторана
        typeof description === 'string' && dispatch(setDescriptionData(description))
      })
      .catch(() => dispatch(addTemporaryNotice({ markup: 'Ошибка загрузки постов' })))
  },
)

/**
 * @function newsPartialUpdate
 */
// TODO: Надо конкретно все привести в порядок
export const postUpdate = createAsyncThunk('OwnerPosts/postUpdate', async (action, { dispatch, getState }) => {
  try {
    // @ts-ignore
    const state = getState().pages.owner_personal

    // определяем какой тип поста в данный момент открыт
    const stateForm = state.posts.forms[state.posts.forms.selectedForm]
    const stateCategory = stateForm.categories[stateForm.selectedCategory]

    if (stateForm.loading) {
      console.error('Форма в состоянии загрузки')
      return
    }
    if (!state.posts.canPublished) {
      throw new Error('С момента последней публикации прошло менее минуты, попробуйте позже!')
    }

    // проверка того, что все поля заполнены верно
    // в меню
    if (stateForm.selectedCategory === 'menu') {
      const dishesIsValid = stateCategory.fields.dishes.every((dish) => {
        return Object.keys(dish).every((field) => {
          const fieldObject = dish[field]
          const valueIsObject = typeof fieldObject === 'object' && !Array.isArray(fieldObject) && fieldObject !== null

          if (valueIsObject && 'valid' in fieldObject) return fieldObject.valid
          else return true
        })
      })
      if (!dishesIsValid || !stateCategory.fields.date.valid) {
        throw new Error('Ошибка валидации')
      }
    }
    // в новостях и спецпредложениях
    else {
      const isValidFields = Object.keys(stateCategory.fields).every((field) => {
        const fieldObject = stateCategory.fields[field]
        const valueIsObject = typeof fieldObject === 'object' && !Array.isArray(fieldObject) && fieldObject !== null

        if (valueIsObject && 'valid' in fieldObject) return fieldObject.valid
        else return true
      })
      if (!isValidFields) {
        throw new Error('Ошибка валидации')
      }
    }

    dispatch(setLoadingForm(true))

    // РАБОТА С API
    if (stateForm.selectedCategory === 'news' || stateForm.selectedCategory === 'special') {
      const isNews = stateForm.selectedCategory === 'news'
      let previewId = null

      // 1. Отправляем фото, и затем получить его id
      await API.api_files_create({
        body: { file: stateCategory.fields.preview.value },
        error: { showUser: true },
      })
        .then((res) => {
          previewId = res.id
        })
        .catch(() => {
          previewId = null
        })

      if (!previewId) {
        dispatch(setLoadingForm(false))
        return
      }

      // 2. Отправляем запрос на изменение новости\спецпредложения
      const params = {
        rid: state.posts.restaurant.id,
        id: state.posts.backendSource[isNews ? 'news' : 'special'].id,
        body: {
          title: stateCategory.fields.title.value,
          remove_photo: false,
          publish: true,
          api_file_id: previewId,
        },
      }

      params.body[isNews ? 'description' : 'body'] = stateCategory.fields.description.value

      const requestMethod = isNews ? API.r_news_partial_update : API.r_special_partial_update

      requestMethod(params)
        .then(() => {
          const message = isNews ? 'Новость отправлена на модерацию!' : 'Спецпредложение отправлено  на модерацию!'
          // @ts-ignore
          dispatch(addTemporaryNotice({ markup: message }))
          //  Обновляем данные о постах
          dispatch(loadRestWithPosts())
          dispatch(closeForm())
        })
        .catch(() => {
          dispatch(setLoadingForm(false))
          const message = isNews
            ? 'Ошибка отправки новости, попробуйте позже'
            : 'Ошибка отправки спецпредложения, попробуйте позже'
          throw new Error(message)
        })
    } else if (stateForm.selectedCategory === 'menu') {
      // Чтобы удалить пост с меню нам нужно правильно удалить его из API
      // 1. Сначала узнаем текущие данные сущности menu в API (из API.r_read => backendSource)
      const { id: menuId, items: sourceDishes } = state.posts.backendSource.menu

      // 2. Удаляем все позиции меню которые есть на данный момент
      if (sourceDishes?.length) {
        const responseDeleteDishes = await Promise.all(
          sourceDishes.map((dish) =>
            API.r_menu_item_delete({ rid: state.posts.restaurant.id, menu_offer_id: menuId, id: dish.id }),
          ),
        )
        if (responseDeleteDishes.state === 'rejected') throw new Error('Не удалось удалить позицию меню')
      }

      // 3. Параллельно отправляем фотографии новых позиций
      const responseDownloadPreviews = await Promise.all(
        stateCategory.fields.dishes.map(
          (dish) =>
            new Promise((resolve, reject) => {
              API.api_files_create({
                body: { file: dish.preview.value },
              })
                .then((resFile) => {
                  // 4. После того как получим id фото, отправляем запросы на добавление позиций (POST)
                  API.r_menu_item_create({
                    rid: state.posts.restaurant.id,
                    menu_offer_id: menuId,
                    body: {
                      title: dish.title.value,
                      description: dish.description.value,
                      price: dish.price.value,
                      api_file_id: resFile.id,
                    },
                  })
                    .then((resItemCreate) => resolve(resItemCreate))
                    .catch((e) => reject(e))
                })
                .catch((e) => reject(e))
            }),
        ),
      )

      if (responseDownloadPreviews === 'rejected') {
        throw new Error('Не удалось добавить позицию меню')
      }

      // 5. Отправляем изменения для главного объекта меню
      API.r_menu_partial_update({
        rid: state.posts.restaurant.id,
        id: menuId,
        body: {
          publish: true,
        },
        error: { showUser: true },
      })
        .then((res) => {
          dispatch(addTemporaryNotice({ markup: 'Обновленное меню отправлено на модерацию!' }))
          //  Обновляем данные о постах
          dispatch(loadRestWithPosts())
          dispatch(closeForm())
        })
        .catch(() => {
          dispatch(setLoadingForm(false))
        })
    }
  } catch (e) {
    dispatch(addTemporaryNotice({ markup: e.message }))
    dispatch(setLoadingForm(false))
  }
})

export const postDelete = createAsyncThunk(
  'OwnerPosts/postDelete',
  async (action: { postId?: number; postType?: TypePostCategory }, { dispatch, getState }) => {
    // @ts-ignore
    const state = getState().pages.owner_personal
    let postId = null
    let postType = null

    if (!state.posts.canPublished) {
      throw new Error('С момента последней публикации прошло менее минуты, попробуйте позже!')
    }

    // Узнаем id поста
    // 1. Если id поста передали напрямую
    // 2. Если id не передавали, но какая либо форма открыта (create, edit, delete)
    if (action?.postId && action?.postType) {
      postId = action.postId
      postType = action.postType
    } else {
      // определяем какой тип поста в данный момент открыт
      const stateForm = state.posts.forms[state.posts.forms.selectedForm]
      if (stateForm.loading) {
        console.error('Форма в состоянии загрузки')
        return
      }

      postType = stateForm.selectedCategory
      postId = state.posts.backendSource[postType].id

      dispatch(setLoadingForm(true))
    }

    // РАБОТА С API
    if (postType === 'news' || postType === 'special') {
      const isNews = postType === 'news'
      // 1. Отправляем запрос на изменение новости\спецпредложения (по сути удаление)
      const params = {
        rid: state.posts.restaurant.id,
        id: postId,
        body: {
          title: '',
          status: 'draft',
          remove_photo: true,
          publish: false,
        },
      }
      params.body[isNews ? 'description' : 'body'] = ''

      const requestMethod = isNews ? API.r_news_partial_update : API.r_special_partial_update

      requestMethod(params)
        .then(() => {
          const message = isNews ? 'Новость удалена!' : 'Спецпредложение удалено!'

          dispatch(addTemporaryNotice({ markup: message }))
          dispatch(closeForm())
          //  Обновляем данные о постах
          dispatch(loadRestWithPosts())
        })
        .catch(() => {
          const message = isNews
            ? 'Ошибка удаления новости, попробуйте позже'
            : 'Ошибка удаления спецпредложения, попробуйте позже'
          if (!action?.postId && !action?.postType) dispatch(setLoadingForm(false))
          dispatch(addTemporaryNotice({ markup: message }))
        })
    } else if (postType === 'menu') {
      try {
        // Чтобы удалить пост с меню нам нужно правильно удалить его из API
        // 1. Сначала узнаем текущие данные сущности menu в API (из API.r_read => backendSource)
        const { id: menuId, items: sourceDishes } = state.posts.backendSource.menu

        // 2. Удаляем все позиции меню которые есть на данный момент
        if (sourceDishes?.length) {
          const responseDeleteDishes = await Promise.all(
            sourceDishes.map((dish) =>
              API.r_menu_item_delete({ rid: state.posts.restaurant.id, menu_offer_id: menuId, id: dish.id }),
            ),
          )

          if (responseDeleteDishes.state === 'rejected') throw new Error('Не удалось удалить позицию меню')
        }

        // 3. Отправляем пустые данные для главного объекта меню
        API.r_menu_partial_update({
          rid: state.posts.restaurant.id,
          id: menuId,
          body: {
            status: 'draft',
            publish: false,
          },
        })
          .then((res) => {
            dispatch(addTemporaryNotice({ markup: 'Спецпредложение удалено!' }))
            dispatch(closeForm())
            //  Обновляем данные о постах
            dispatch(loadRestWithPosts())
          })
          .catch((e) => {
            throw new Error('Не удалось удалить меню')
          })
          .finally(() => {
            if (!action?.postId && !action?.postType) dispatch(setLoadingForm(false))
          })
      } catch (e) {
        dispatch(addTemporaryNotice({ markup: e.message }))
      }
    }
  },
)
