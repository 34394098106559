import React, { FC, memo, ReactNode, useMemo } from 'react'
import { Button } from '../../Actions/Button'
import s from './PopupNotice.module.sass'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'
import { Text } from '@shared/ui/Typography/Text'
import { ErrorBoundary } from '../../../lib/components/ErrorBoundary'
import { TypeNotice } from '@widgets/Common/Notices/model/types'

interface Props {
  children: ReactNode
  type: TypeNotice
  id: number
  // eslint-disable-next-line
  handleClose: (id: number) => void
  status?: 'send' | 'error'
  className?: string
}

// TODO: Нужно использовать константы из STATUSES
const textForStatus = {
  send: 'Отправлено. Спасибо!',
  error: 'Что-то пошло не так. Форма не отправлена. Попробуйте позже еще раз.',
}

const PopupNotice: FC<Props> = ({ children, status, type, id, handleClose, className = '' }) => {
  const { isMobile } = useClientResponsive()

  const handleCloseInner = () => handleClose(id)

  const getText = useMemo(() => {
    if (children) return children
    else if (status) return textForStatus[status]
    else {
      console.error('Ошибка! Отсутствует text или неверно указан status')
      return 'Ошибка! Отсутствует text или неверно указан status'
    }
  }, [children, status])

  return (
    <ErrorBoundary>
      <div className={s.wrapper} data-type={type}>
        <div className={`${s.notice} ${className}`}>
          {/*@ts-ignore*/}
          <Text sizes={'M'} className={s.text} dangerouslySetInnerHTML={{ __html: getText }} />
          <Button sizes='S' mode='white' onClick={handleCloseInner} className={s.button}>
            Окей
          </Button>
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default memo(PopupNotice)
