import React, { FC } from 'react'
import s from './MapRestCarouselMobileNotFound.module.sass'
import { Text } from '@shared/ui/Typography/Text'
import { Title } from '@shared/ui/Typography/Title'

const MapRestCarouselMobileNotFound: FC = () => {
  return (
    <div id={'rest-card-map-mobile'} className={s.card}>
      <Title sizes={'h4'}>Ничего не найдено</Title>
      <Text sizes={'S'}>
        Попробуйте поискать
        <br />в другой области на карте
      </Text>
    </div>
  )
}

export default MapRestCarouselMobileNotFound
