import React, { FC, memo, useEffect } from 'react'
import { MapContext } from '@widgets/Restaurant/Map/MapContext'
import { useAppDispatch, useAppSelector } from '@app/model/store'
import { openMapAC, setRestSelectedAC } from '@widgets/Restaurant/Map/model/actions'
import MapContent from '@widgets/Restaurant/Map/MapContent'
import { useRouter } from 'next/router'
import { load } from '@2gis/mapgl'
import { resetMap } from '@widgets/Restaurant/Map/model/slice'
import { EGG } from '@shared/api/analytics'

const Map: FC = () => {
  const [mapInstance, setMapInstance] = React.useState({
    mapglAPI: null,
    mapCreated: null,
  })
  const storeMapActive = useAppSelector((store) => store.features.map.active)
  const dispatch = useAppDispatch()
  const router = useRouter()

  useEffect(() => {
    const hasPreopenedUrlPath = window.location.search.includes('?map')
    if (hasPreopenedUrlPath && !storeMapActive)
      dispatch(openMapAC({ selectedSlug: (router.query.restaurant as string) || null }))
    else if (!hasPreopenedUrlPath && storeMapActive) {
      dispatch(resetMap())
      setMapInstance({
        mapglAPI: null,
        mapCreated: null,
      })
    }
  }, [router])

  useEffect(() => {
    storeMapActive && EGG.pages.Map.appear_screen_page()
  }, [storeMapActive])

  return (
    storeMapActive && (
      <MapContext.Provider value={[mapInstance, setMapInstance]}>
        <MapContent />
      </MapContext.Provider>
    )
  )
}

export default memo(Map)
