import { Dispatch } from 'react'
import { AnyAction } from '@reduxjs/toolkit'
import Authorization from '@app/service/authorization'
import { setUserData, setUserLoading } from '@app/model/slice'
import { globalStore } from '@app/model/store'
import { apiPublicMock } from '@shared/api/apiPublicMock'

export const initAuthAC = (type) => async (dispatch: Dispatch<AnyAction>) => {
  if (type === 'dev') {
    dispatch(setUserData(apiPublicMock.user_me))
    dispatch(setUserLoading(false))
    return
  }

  if (Authorization.isInit) return

  Authorization.init()
  Authorization.addListener('loginStart', () => dispatch(setUserLoading(true)))
  Authorization.addListener('loginSuccess', (data) => {
    dispatch(setUserData(data))
    dispatch(setUserLoading(false))
  })
  Authorization.addListener('loginError', () => {
    dispatch(setUserLoading(false))
  })

  Authorization.addListener('logoutStart', () => dispatch(setUserLoading(true)))
  Authorization.addListener('logoutSuccess', () => {
    dispatch(setUserData(null))
    dispatch(setUserLoading(false))
  })
  Authorization.addListener('logoutError', () => {
    dispatch(setUserLoading(false))
  })

  Authorization.isAuthorized()
    .then(() => Authorization.login())
    .catch(() => null)
}

export const loginAC = () => {
  const userState = globalStore.getState().global.user

  if (userState.loading) return

  // Открываем окно авторизации
  Authorization.openAuth()

  // Если пользователь авторизовался на другой площадке то получаем данные и закрываем окно авторизации
  Authorization.login()
}

export const logoutAC = () => async (dispatch: Dispatch<AnyAction>) => {
  const userState = globalStore.getState().global.user

  if (userState.loading) return

  Authorization.logout()
}
