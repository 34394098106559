import s from './HeaderAdvert.module.sass'

import React, { FC, memo, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { AdvertTemplate } from '@shared/ui/Layout/AdvertTemplate'
import { useClientResponsive } from '@shared/lib/hooks/useClientResponsive'

import Script from 'next/script'
import { useAppSelector } from '@app/model/store'
import Link from 'next/link'

const SHOW_ANY_ADV = process.env.SHOW_ANY_ADV

// adv-header Billboard TopBanner
const HeaderAdvert: FC = () => {
  const router = useRouter()
  const store = useAppSelector((store) => store.global)
  const { designEnv, isMobile } = useClientResponsive()

  const [hasDefaultAdv, setHasDefaultAdv] = useState(false)

  // Лендинг ресторатора со своей шапкой
  const isOwnerPage =
    store.currentPage == 'owner_marketing' ||
    store.currentPage == 'owner_booking' ||
    router.pathname == '/[city_slug]/restaurants/restorator'

  const isServiceMasterPages = store.currentPage === 'not_found' || store.currentPage === 'service_err'

  const isShowHeaderAdvert = !isOwnerPage && !isServiceMasterPages

  // основная логика для ADFox баннеров
  // отображение плоскости для верхней рекламы билборда
  const isShowAdv = SHOW_ANY_ADV && isShowHeaderAdvert && designEnv !== 'default'

  const handleEmptyAdv = () => {
    setHasDefaultAdv(false)
  }

  const handleLoadedAdv = () => {
    setHasDefaultAdv(true)
  }

  return isShowAdv ? (
    <div className={s['adv-header']} data-has-adv={hasDefaultAdv}>
      <div className={s['adv-wrapper']}>
        <div className={`${s['adv-container']} ${isMobile ? s['adv-container--mobile'] : ''}`}>
          <AdvertTemplate
            type={'Billboard TopBanner'}
            className={`${s['adv-place']} ${isMobile ? s['adv-place--mobile'] : ''}`}
            onEmptyAdv={handleEmptyAdv}
            onLoadedAdv={handleLoadedAdv}
            singleRequest={true}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className={s.plug}></div>
  )
}

export default memo(HeaderAdvert)
